<!-- Icons for Laptop View -->
<div class="d-flex justify-content-between" *ngIf="this.deviceDetectionService.isDesktop" style="align-items: center;">
   <div *ngFor="let hdr of headerConfig" [ngClass]="{'displayNameAdd': hdr.add_header == false}">

      <!-- Widget Heading Laptop -->
      <div *ngIf="hdr.add_header">
         <span *ngIf="hdr.header_name_isNeed"  [id]="heading.replaceAll(' ', '')==''?'header':heading.replaceAll(' ', '')" style="font-weight: 600;font-family: poppins; line-height: 24px;font-size: 20px;display: flex;margin-left: 7px;">
            <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key != 'from_date'" style="margin-right: 5px;">
               {{ this.findWhichKey(hdr.sp_nm_visibility_key, hdr.firstValue) + ", " }}
            </span>
            
            <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key == 'from_date'" style="margin-right: 5px;">
               {{ this.findPrefixDateFormat(hdr) }}
            </span>
            <span *ngIf="hdr.nav_header"> {{ nav_header_name}}</span>
          
            {{ filterService.baseQuery?.source_nm && sourceHeaderName ? hdr.page_header_name +' '+( (filterService.showByHeader=='true') ? (filterService.showByKey=='N'?'NDC':'Location'):'') : hdr.page_header_name+' '+( (filterService.showByHeader=='true')? (filterService.showByKey=='N'?'NDC':'Location'):'') }}
            
            <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key != 'from_date'" style="margin-left: 5px;">
               <span *ngIf="cardId=='single'">{{ " "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span>
              
               <span *ngIf="cardId!='single'" style="color: #1363DF;white-space: normal;">  {{ " - "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span> 
               
               <span *ngIf="cardId!='single'">{{" - Q"+ moment(filterService.baseQuery?.['from_date']).quarter()+' '+moment(filterService.baseQuery?.['from_date']).year()}}</span>
               
               <span *ngIf="cardId!='single' && this.detailData.length>0"> 
                  {{ ' - ' }}
                  <span *ngFor="let a of detailData;let last = last;let idx = index;">
                     
                     <span>{{ (a.filterKey == 'date' ? moment(a[a.key]).format("MM/DD/YYYY") : a[a.key]) }}</span>

                     <span *ngIf="!last">{{ ' - ' }}</span>
                  </span>
               </span>
            </span>
            
            <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key == 'from_date'" style="margin-left: 5px;">
               {{ this.findSuffixDateFormat(hdr) }}
            </span>

            <span *ngIf="hdr.data_count == true" style="color: #8A98AB;margin: 0 0 0 5px;">
               {{'(' + rowDataLength.length + ')' || '(0)'}}
            </span>
         </span>

         <!-- Annotations -->
         <span *ngIf="hdr.value=='annotation'&& annotations">
            <div style="width: 152px; height: 32px;border-radius: 4px;background-color: #D0E0F9;">
               <div style="padding:5px">
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clip-path="url(#clip0_8824_9006)">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2644 15.1879L12.1655 15.4694C12.1062 15.638 11.9213 15.7277 11.7522 15.6699L10.3414 15.1879H12.2644ZM11.9843 0.350104C12.6739 0.519917 13.0952 1.21657 12.9254 1.90614C12.7609 2.57382 12.1025 2.99004 11.4349 2.86157L11.1903 3.37857H15.0655C15.2452 3.37857 15.3917 3.52514 15.3917 3.70482V14.2091C15.3917 14.3888 15.2452 14.5354 15.0655 14.5354H4.72972C4.55003 14.5354 4.40347 14.3888 4.40347 14.2091V3.70482C4.40347 3.52514 4.55003 3.37857 4.72972 3.37857H10.47L10.8473 2.58135C10.4859 2.27585 10.3075 1.78176 10.4283 1.29117C10.5981 0.601604 11.2947 0.180292 11.9843 0.350104ZM11.1903 3.37857H10.47L9.51009 5.40707C9.43337 5.56967 9.503 5.76367 9.66556 5.84039C9.82816 5.9171 10.0222 5.84748 10.0989 5.68492L11.1903 3.37857ZM6.81759 12.0876H12.9776C13.1578 12.0876 13.3038 11.9415 13.3038 11.7614C13.3038 11.5812 13.1578 11.4351 12.9776 11.4351H6.81759C6.63741 11.4351 6.49134 11.5812 6.49134 11.7614C6.49134 11.9415 6.63744 12.0876 6.81759 12.0876ZM6.81759 9.75492H12.9776C13.1578 9.75492 13.3038 9.60885 13.3038 9.42867C13.3038 9.24848 13.1578 9.10242 12.9776 9.10242H6.81759C6.63741 9.10242 6.49134 9.24848 6.49134 9.42867C6.49134 9.60885 6.63744 9.75492 6.81759 9.75492ZM6.81759 7.42226H12.9776C13.1578 7.42226 13.3038 7.2762 13.3038 7.09601C13.3038 6.91582 13.1578 6.76976 12.9776 6.76976H6.81759C6.63741 6.76976 6.49134 6.91582 6.49134 7.09601C6.49134 7.2762 6.63744 7.42226 6.81759 7.42226ZM3.75097 12.936V3.70482C3.75097 3.16501 4.18991 2.72607 4.72972 2.72607H8.21741L4.33497 1.43182C4.1645 1.37501 3.97912 1.4677 3.92228 1.63817L0.626124 11.526C0.569562 11.6957 0.660905 11.8801 0.830124 11.9379L3.75097 12.936Z" fill="#1363DF"/>
               </g>
               <defs>
               <clipPath id="clip0_8824_9006">
               <rect width="16" height="16" fill="white"/>
               </clipPath>
               </defs>
               </svg>
             <span style="font-family: Poppins;font-weight: 600;font-size: 12px;"> Annotations mode   x</span>  
            </div>
         </div>
         </span>

         <!-- Search Box Laptop -->
         <span *ngIf="hdr.value=='search_box' && this.deviceDetectionService.isDesktop">
            <input type="search" class=" form-control"
               style="width: 300px;border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;"
               id="filter-text-box" placeholder="Type to Search" autocomplete="off" (input)="onQuickFilterChanged($event)">
         </span>

         <!-- Legends Laptop -->
         <span *ngIf="hdr.value=='legends'" class="d-flex align-items-center du-legends-header mx-2">
            <div *ngFor="let legend of hdr.legends_data;" class="mx-2">
               <div class="d-flex align-items-center" style="white-space: nowrap;">
                  <span class="mx-2"><mat-icon style="height: 20px;width: 20px;overflow: visible;" [svgIcon]="legend.icon"></mat-icon></span>
                  <span class="du-legends-icons-chart">{{ legend.name }}</span>
              </div>
            </div>
         </span>

         <!-- Follow Up Laptop -->
         <span *ngIf="hdr.value=='follow_up' && this.deviceDetectionService.isDesktop" style="display: flex;align-items: center;cursor: pointer;">
            <mat-icon (click)="followUP()" style="width: 12px;margin-right: 5px;" [svgIcon]="plus"></mat-icon>
            <span (click)="followUP()" style="color: #1363DF">Create follow up</span>
         </span>

         <!-- Selection Toggle (Bar) Laptop -->
         <span class="widContainers" *ngIf="hdr.value == 'selection_toggle'">
            <div class="d-flex justify-content-between">
               <button *ngFor="let item of hdr['toggle_btn'];" (click)="changeDisplayType(item.value)"
                  [ngClass]="{'highlight': selectedType===item.value}" class="cycleHover">
                  {{item.title}}
               </button>
            </div>
         </span>

         <!-- Moving Avg Laptop -->
         <span class="widgets-container main_screen_bar" *ngIf="hdr.value == 'moving_average'">
            <div class="d-flex">
               <div class="iconPads" style="margin-top: -4px;padding-right: 2px;">
                  <mat-icon  [svgIcon]="settingsm" style="padding: 3px 6px 3px 3px;cursor: pointer;"  id="moving_average" (click)="selectedVal()" [closeOnOutsideClick]="false"
                     mwlConfirmationPopover  [customTemplate]="customTemplate"  placement="bottom">
                  </mat-icon>
               </div>

               <div class="iconPads">
                  <span style="color: #8A98AB;">
                     Moving Average Periods:
                  </span>

                  <span style="margin-left: 6px;">{{counter}}</span>
               </div>
            </div>
         </span>

         <!-- Time Cycle Laptop -->
         <span *ngIf="hdr.value == 'time_cycle'">
            <div class="d-flex justify-content-between" class="widget-container-timeCycle">
               <div *ngFor="let item of hdr.timeValues" (click)="changeTimeCycleEvent($event,item.value)"
                  [ngClass]="{'highlightTimeCycle': selectedTypeTimeCyle===item.value}" class="cycleHoverTimeCycle">
                  {{item.value}}
               </div>
            </div>
         </span>

         <!-- Network Header Status Label Laptop -->
         <span *ngIf="hdr.value == 'status_label'" class="d-flex w-max-content pipeline_sort">
            <div class="d-flex align-items-center pipeline_sort_icon ml-2" *ngFor="let item of selectedFilter.values">
               <mat-icon [style.color]="item.color">circle</mat-icon>
               <span class="option_value" style="flex: none !important;">{{item.name}}</span>
            </div>
         </span>

         <!-- ShowBy Laptop -->
         <span *ngIf="hdr.value == 'sort_by'" class="d-flex dropdown_val align-items-center"
            style="width: max-content;">

            <div class="d-flex bd-highlight justify-content-between ml-1 align-items-center sort_by_styles">
               <div class="bd-highlight">
                  <label for="sortBy" class="sort_by_text mt-2">Show By |</label>   
               </div>
               <div class="ms-auto bd-highlight">
                  <select class="sort_by_select text-center" id="sortBy" name="Select" (change)="sortByChange($event)">
                     <option *ngFor="let data of sortByArray" [value]="data['dropdownName']" class="dropdown_values">
                        {{data['dropdownName']}}</option>
                  </select>
               </div>
             </div>
             
            <!-- <div class="sort_by_styles ml-2" style="flex:inherit">
               <label for="sortBy" class="sort_by_text mt-2">Show By |</label>
               <select class="sort_by_select text-center" id="sortBy" name="Select" (change)="sortByChange($event)">
                  <option *ngFor="let data of sortByArray" [value]="data['dropdownName']" class="dropdown_values">
                     {{data['dropdownName']}}</option>
               </select>
            </div> -->
         </span>
         <div  *ngIf="hdr.value == 'show_by'" class="showByContainer align-items-center w-auto" style="margin: 0px auto;text-align: center;">
            <div class="sort_by_styles d-flex">
              <label for="sortBy" class="sort_by_texts mt-2" style="border-right: 2px solid  #E8EAEE;">Show By&nbsp;&nbsp;</label>
              <mat-select class="sort_by_select text-center" style="width: 60% !important;" [matTooltip]="tooltipForShowBy(hdr.show_by_value,showByvalue)" [(ngModel)]="showByvalue"
                (ngModelChange)="showByChange($event)">
                <mat-option *ngFor="let showBy of hdr.show_by_value" [value]="showBy.value" 
                  class="dropdown_values">{{showBy.title}}</mat-option>
              </mat-select>
            </div>
          </div>

         <!-- Page Size Laptop -->
         <span *ngIf="hdr.value == 'page_size'">
            <span>Page Size</span> &nbsp;
            <input type="number" noENotationInput [min]="minValue" [max]="maxValue" [(ngModel)]="paginationPageSize"
               style="width: 50px;border-radius: 0.2rem;border: 2px solid #E8EAEE;margin-right: 10px;text-align: center;">
            <button class="btn btn-primary btn-sm rounded-circle" (click)="onPageSizeChanged()"
               style="width: 30px;height: 30px;text-align: center;margin: 0 !important;padding: 0 !important;"
               type="button" [disabled]="!inputPagenationFromDir" >Go</button>
         </span>

         <!-- PHI Toggle Button Laptop -->
         <span *ngIf="hdr.hidden_toggle && this.deviceDetectionService.isDesktop">
            <span style="font-weight: 600; font-size: 14px;font-family: poppins;line-height: 18px;padding: 0 15px;">
               {{ hdr.hidden_toggle_name || "PHI Column" }}
            </span>

            <mat-slide-toggle color="primary" [checked]="this.filterService.phiChecked"
               (change)="isPHIFunc($event)"></mat-slide-toggle>
         </span>

         <!-- Alerts Laptop -->
         <span *ngIf="hdr.value == 'alerts'">
            <app-alert-icons-commom class="ml-2 mr-2" [alertIcons]="alertIcons"></app-alert-icons-commom>
         </span>

         <!-- Thumb Slider Laptop -->
         <span *ngIf="hdr.slider" style="white-space: nowrap;">

            <span style="font-weight: 500;color: #8A98AB;font-size: 14px;font-family: poppins;line-height: 18px">
               {{ hdr.slider_name || 'Activity since days' }} :
            </span>

            <span style="color: #101D42;font-weight: 700; padding: 0 7px;">
               {{ this.sliderValue }}
            </span>

            <mat-slider #gridsize style="width: 150px;" step="1" tickInterval="2"
               [min]="hdr.slider_custom_range ? hdr.sliderLenth[0].min : 1"
               [max]="hdr.slider_custom_range ? hdr.sliderLenth[0].max : 7" [(ngModel)]="this.sliderValue"
               (change)="updateSetting($event)">
            </mat-slider>

         </span>

         <!-- Icon List Laptop -->
         <span *ngIf="hdr.icon_selected">

            <!-- <span *ngIf="this.deviceDetectionService.isTablet">
               <mat-icon type="button" [matMenuTriggerFor]="iPadMode" class="matIcons"
                  svgIcon="setting_responsive"></mat-icon>
               <mat-icon type="button" class="matIcons" svgIcon="vr_line_ipad"></mat-icon>
            </span> -->
            <span *ngFor="let icon of hdr.iconList">
               <span *ngIf="icon.activated">
                  <mat-icon matTooltip="Export List" class="matIcons" [svgIcon]="'dotmenu_circle_disabled'"
                     *ngIf="annotations&&icon.name=='dotmenu_circle'"></mat-icon>
                  <mat-icon matTooltip="Export List" class="matIcons" [svgIcon]="icon.name"
                     *ngIf="!annotations&&icon.name=='dotmenu_circle'" [matMenuTriggerFor]="Exportmenu"></mat-icon>

                  <mat-menu #Exportmenu="matMenu">
                     <span *ngFor="let ic of icon?.event">
                        <a *ngIf="ic.is_true==true" mat-menu-item style="font-family: 'Poppins'!important;"
                           (click)="ic.value == 'pdf' || ic.value == 'pdftable' ? openPDFcustom(ic.value) : ic.value == 'excel' ? excelExport() : testIcon()">
                           <mat-icon
                              [svgIcon]="ic.value == 'pdf' || ic.value == 'pdftable'? 'pdf' : ic.value == 'excel' ? 'xls' : ''"></mat-icon>{{ic.dispPlayName}}</a>
                     </span>
                  </mat-menu>

                  <mat-icon matTooltip="Help" class="matIcons" [svgIcon]="annotations?'info_circle_disabled':icon.name" *ngIf="icon.name =='info_circle'"
                     [matMenuTriggerFor]="annotations?null:info" (click)="annotations?'':getInfoDetail()"></mat-icon>
                  <mat-icon [matTooltip]=" isFullscreen ? ' Exit Full Screen': 'Show Full Screen'" class="matIcons"
                     [svgIcon]=" isFullscreen ? 'collapse_arrow':icon.name " *ngIf="icon.name =='expand_circle'"
                     (click)="isFullscreen ? closeFullscreen() : openFullscreen()"></mat-icon>
                  <mat-icon matTooltip="Change Status" class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='change_status'" (click)="changeStatus()"></mat-icon>
                  <mat-icon matTooltip="Show Underlying Data" class="matIcons" [svgIcon]="annotations?'tabular_circle_disabled':icon.name"
                     *ngIf="icon.name =='tabular_circle'" (click)="annotations?'':openDialog()"></mat-icon>
                  <mat-icon matTooltip="User Union" class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='union_circle'"></mat-icon>
                     <mat-icon class="compareIconsBtn" svgIcon="compareBtn" *ngIf="compareChart && icon.name =='compare'"   (click)="CompareBtn()"></mat-icon>
                  <mat-icon [matTooltip]="  compareChart ?' ':'Compare'"
                     [class]="compareChart ?'compareIcons': 'matIcons'"
                     [svgIcon]=" compareChart ?'compareCross': icon.name" *ngIf="icon.name =='compare'"
                     (click)="Compare()"></mat-icon>
                  <mat-icon matTooltip="Setting"  class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='setting'" [matMenuTriggerFor]="setting" placement="bottom"></mat-icon>
                     <mat-icon matTooltip="Settings"  class="matIcons" id="setting" [svgIcon]="'shuffle_disabled'"  *ngIf="icon.name =='shuffle'&& annotations" ></mat-icon>

                     <mat-icon matTooltip="Settings" class="matIcons" id="setting" [svgIcon]="icon.name"  *ngIf="icon.name =='shuffle'&&!annotations"  mwlConfirmationPopover   (click)="reset()"  [closeOnOutsideClick]="false" [customTemplate]="SettingTemplate"  placement="bottom" ></mat-icon>
                     <mat-icon matTooltip="Transaction Details" class="matIcons" [svgIcon]="'layout_disabled'"  *ngIf="icon.name =='layout'&& annotations"  ></mat-icon>
                     <mat-icon matTooltip="Transaction Details" class="matIcons" [svgIcon]="icon.name"  *ngIf="icon.name =='layout'&& !annotations" (click)="controlChartPopup()" ></mat-icon>
                     <mat-icon matTooltip="Notes" class="matIcons" [svgIcon]="icon.name"  *ngIf="icon.name =='notes_circle'"  ></mat-icon>
                     <mat-icon matTooltip="Annotations" class="matIcons" [svgIcon]="annotations?'annotations_cross':'annotations'"  *ngIf="icon.name =='notes'"  (click)="annotationmethod()" ></mat-icon>
                     
                  <!-- <mat-icon matTooltip="Settings" id="setting" class="matIcons" [svgIcon]="icon.name" *ngIf="icon.name =='setting'"
                     mwlConfirmationPopover [customTemplate]="SettingTemplate" placement="bottom"></mat-icon> -->
                  <mat-icon matTooltip="Expand All" class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='gridload_circle' && !expandChart" (click)="expandCollapse()"></mat-icon>
                  <mat-icon matTooltip="Collapse All" class="matIcons" svgIcon="collapse_new"
                     *ngIf="icon.name =='gridload_circle' && expandChart" (click)="expandCollapse()"></mat-icon>
                  <mat-icon [svgIcon]="expandCollapseIcon" class="matIcons" *ngIf="icon.name =='expand'"
                     (click)="pivotexpandCollapse()" [matTooltip]="expandOrCollapse"></mat-icon>
                  <!-- [svgIcon]="expandCollapseIcon" -->
               </span>
            </span>
         </span>
      </div>

   </div>
</div>

<!-- Icons for Mobile -->
<div *ngIf="this.deviceDetectionService.isMobile">
   <div class="row" [ngClass]="{'fullScreenScroll' : this.isFullscreen}">
      <div class="d-flex justify-content-between align-items-center">
         <div *ngFor="let hdr of headerConfig">
            <div *ngIf="hdr.header_name_isNeed"  [id]="heading.replaceAll(' ', '')==''?'header':heading.replaceAll(' ', '')" style="font-weight: 600;font-family: poppins; line-height: 24px;font-size: 20px;display: flex;margin-left: 7px;">
               <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key != 'from_date'" style="margin-right: 5px;">
                  {{ this.findWhichKey(hdr.sp_nm_visibility_key, hdr.firstValue) + ", " }}
               </span>
               
               <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key == 'from_date'" style="margin-right: 5px;">
                  {{ this.findPrefixDateFormat(hdr) }}
               </span>
               
               {{ filterService.baseQuery?.source_nm && sourceHeaderName ? hdr.page_header_name +' '+( (filterService.showByHeader=='true') ? (filterService.showByKey=='N'?'NDC':'Location'):'') : hdr.page_header_name+' '+( (filterService.showByHeader=='true')? (filterService.showByKey=='N'?'NDC':'Location'):'') }}
               
               <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key != 'from_date'" style="margin-left: 5px;">
                  <span *ngIf="cardId=='single'">{{ " "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span>
                 
                  <span *ngIf="cardId!='single'" style="color: #1363DF;white-space: normal;">  {{ " - "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span> 
                  
                  <span *ngIf="cardId!='single'">{{" - Q"+ moment(filterService.baseQuery?.['from_date']).quarter()+' '+moment(filterService.baseQuery?.['from_date']).year()}}</span>
                  
                  <span *ngIf="cardId!='single' && this.detailData.length>0"> 
                     {{ ' - ' }}
                     <span *ngFor="let a of detailData;let last = last;let idx = index;">
                        
                        <span>{{ (a.filterKey == 'date' ? moment(a[a.key]).format("MM/DD/YYYY") : a[a.key]) }}</span>
   
                        <span *ngIf="!last">{{ ' - ' }}</span>
                     </span>
                  </span>
               </span>
               
               <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key == 'from_date'" style="margin-left: 5px;">
                  {{ this.findSuffixDateFormat(hdr) }}
               </span>
   
               <span *ngIf="hdr.data_count == true" style="color: #8A98AB;margin: 0 0 0 5px;">
                  {{'(' + rowDataLength.length + ')' || '(0)'}}
               </span>
            </div>

            <!-- ShowBy Mobile -->
            <div *ngIf="hdr.value == 'sort_by' && !isPortraitMode" class="d-flex dropdown_val align-items-center"
               style="width: max-content;">
               <div class="sort_by_styles ml-2" style="flex:inherit">
                  <label for="sortBy" class="sort_by_text mt-2">Show By |</label>
                  <select class="sort_by_select text-center" id="sortBy" name="Select" (change)="sortByChange($event)">
                     <option *ngFor="let data of sortByArray" [value]="data['dropdownName']" class="dropdown_values">
                        {{data['dropdownName']}}</option>
                  </select>
               </div>
            </div>

            <!-- Follow Up Mobile -->
            <div *ngIf="hdr.value=='follow_up'" style="display: flex;align-items: center;cursor: pointer;">
               <mat-icon (click)="followUP()" style="width: 12px;margin-right: 5px;" [svgIcon]="plus"></mat-icon>
               <span *ngIf="!isPortraitMode" (click)="followUP()" style="color: #1363DF">Create follow up</span>
            </div>

            <div  *ngIf="hdr.value == 'show_by'" class="showByContainer align-items-center w-auto" style="margin: 0px auto;text-align: center;">
               <div class="sort_by_styles d-flex">
                 <label for="sortBy" class="sort_by_texts mt-2" style="border-right: 2px solid  #E8EAEE;">Show By</label>
                 <mat-select class="sort_by_select text-center" style="width: 60% !important;" [(ngModel)]="hdr.show_by_value[0].value"
                   (ngModelChange)="showByChange($event)">
                   <mat-option *ngFor="let showBy of hdr.show_by_value" [value]="showBy.value"
                     class="dropdown_values">{{showBy.title}}</mat-option>
                 </mat-select>
               </div>
             </div>
            <!-- PHI Toggle Button Mobile -->
            <div *ngIf="hdr.hidden_toggle && !isPortraitMode">
               <span style="font-weight: 600; font-size: 14px;font-family: poppins;line-height: 18px;padding: 0 15px;">
                  {{ hdr.hidden_toggle_name || "PHI Column" }}
               </span>

               <mat-slide-toggle color="primary" [checked]="this.filterService.phiChecked"
                  (change)="isPHIFunc($event)"></mat-slide-toggle>
            </div>

            <!-- Time Cycle  -->
            <span *ngIf="hdr.value == 'time_cycle' && !isPortraitMode">
               <div class="d-flex justify-content-between" class="widget-container-timeCycle">
                  <div *ngFor="let item of hdr.timeValues" (click)="changeTimeCycleEvent($event,item.value)"
                     [ngClass]="{'highlightTimeCycle': selectedTypeTimeCyle===item.value}" class="cycleHoverTimeCycle">
                     {{item.value}}
                  </div>
               </div>
            </span>

            <!-- Search Box Mobile -->
            <div *ngIf="hdr.value=='search_box' && !isPortraitMode">
               <input type="text" 
                  style="border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;margin-top: 5px;"
                  id="filter-text-box" autocomplete="off" placeholder="Type to Search" (input)="onQuickFilterChanged($event)">
            </div>

            <!-- Page Size Mobile -->
            <div *ngIf="hdr.value == 'page_size' && !isPortraitMode">
               <span>Page Size</span> &nbsp;
               <input type="number" noENotationInput [min]="minValue" [max]="maxValue" [(ngModel)]="paginationPageSize"
                  style="width: 50px;border-radius: 0.2rem;border: 2px solid #E8EAEE;margin-right: 10px;text-align: center;">
               <button class="btn btn-primary btn-sm rounded-circle" (click)="onPageSizeChanged()"
                  style="width: 30px;height: 30px;text-align: center;margin: 0 !important;padding: 0 !important;"
                  type="button">Go</button>
            </div>

            <!-- Mat Icon for Icons List Popup Mobile -->
            <div *ngIf="hdr.icon_selected">
               <mat-icon type="button" (click)="openDialogIcon()" class="matIcons" svgIcon="dotmenu_circle"></mat-icon>
            </div>

         </div>
      </div>
   </div>
   <div class="row mt-3">
      <div class="col-12 d-flex align-items-center" [ngStyle]="{'justify-content': this.isPortraitMode ? 'center' : 'space-evenly'}">
         <div *ngFor="let hdr of headerConfig">

            <!-- Legends Laptop -->
            <div *ngIf="hdr.value=='legends'" class="d-flex align-items-center du-legends-header mx-2">
               <div *ngFor="let legend of hdr.legends_data;" class="mx-2">
                  <div class="d-flex align-items-center" style="white-space: nowrap;">
                     <span class="mx-2"><mat-icon style="height: 16px;width: 16px;overflow: visible;" [svgIcon]="legend.icon"></mat-icon></span>
                     <span class="du-legends-icons-chart" style="font-size: 10px;">{{ legend.name }}</span>
               </div>
               </div>
            </div>

            <!-- Alerts Mobile -->
            <div *ngIf="hdr.value == 'alerts'">
               <app-alert-icons-commom class="ml-2 mr-2" [alertIcons]="alertIcons"></app-alert-icons-commom>
            </div>

            <!-- Network Header Label Mobile -->
            <div *ngIf="hdr.value == 'status_label' && selectedFilter.values.length < 3"
               class="d-flex w-max-content pipeline_sort">
               <div class="d-flex align-items-center pipeline_sort_icon"
                  *ngFor="let item of selectedFilter.values">
                  <mat-icon [style.color]="item.color">circle</mat-icon>
                  <span class="option_value" style="flex: none !important;">{{item.name}}</span>
               </div>
            </div>

            <!-- Network Header Label Mobile -->
            <div *ngIf="hdr.value == 'status_label' && selectedFilter.values.length > 3"
               class="d-flex flex-column w-max-content pipeline_sort">
               <div class="d-flex align-items-center pipeline_sort_icon"
                  *ngFor="let item of selectedFilter.values">
                  <mat-icon [style.color]="item.color">circle</mat-icon>
                  <span class="option_value" style="flex: none !important;">{{item.name}}</span>
               </div>
            </div>

            <!-- Search Box Mobile -->
            <!-- <div *ngIf="hdr.value=='search_box' && !isPortraitMode">
               <input type="text" 
                  style="border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;"
                  id="filter-text-box" autocomplete="off" placeholder="Type to Search" (input)="onQuickFilterChanged($event)">
            </div> -->

            <!-- Moving Avg Box Mobile -->
            <div class="widgets-container" *ngIf="hdr.value == 'moving_average'">
               <div class="d-flex">
                  <div class="iconPads" style="margin-top: -4px;padding-right: 2px;">
                     <mat-icon [svgIcon]="settingsm" style="padding: 3px 6px 3px 3px;cursor: pointer;" id="moving_average" (click)="selectedVal()"[closeOnOutsideClick]="false"
                        mwlConfirmationPopover [customTemplate]="customTemplate" placement="bottom">
                     </mat-icon>
                  </div>

                  <div class="iconPads">
                     <span style="color: #8A98AB;">
                        Moving Average Periods:
                     </span>

                     <span style="margin-left: 6px;">{{counter}}</span>
                  </div>
               </div>
            </div>

            <!-- Selection Toggle (Bar) Mobile -->
            <div class="widContainers" *ngIf="hdr.value == 'selection_toggle'">
               <div class="d-flex justify-content-between">
                  <button *ngFor="let item of hdr['toggle_btn'];" (click)="changeDisplayType(item.value)"
                     [ngClass]="{'highlight': selectedType===item.value}" class="cycleHover">
                     {{item.title}}
                  </button>
               </div>
            </div>

            <!-- Time Cycle  -->
            <span *ngIf="hdr.value == 'time_cycle' && isPortraitMode">
               <div class="d-flex justify-content-between" class="widget-container-timeCycle">
                  <div *ngFor="let item of hdr.timeValues" (click)="changeTimeCycleEvent($event,item.value)"
                     [ngClass]="{'highlightTimeCycle': selectedTypeTimeCyle===item.value}" class="cycleHoverTimeCycle">
                     {{item.value}}
                  </div>
               </div>
            </span>

         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
         <div *ngFor="let hdr of headerConfig">
            <!-- Page Size Mobile -->
            <div class="mt-2" *ngIf="hdr.value == 'page_size' && isPortraitMode">
               <span>Page Size</span> &nbsp;
               <input type="number" noENotationInput [min]="minValue" [max]="maxValue" [(ngModel)]="paginationPageSize"
                  style="width: 50px;border-radius: 0.2rem;border: 2px solid #E8EAEE;margin-right: 10px;text-align: center;">
               <button class="btn btn-primary btn-sm rounded-circle" (click)="onPageSizeChanged()"
                  style="width: 30px;height: 30px;text-align: center;margin: 0 !important;padding: 0 !important;"
                  type="button">Go</button>
            </div>

            <!-- Thumb Slider Mobile -->
            <div *ngIf="hdr.slider && iconsWithoutPopup" style="white-space: nowrap;">

               <span style="font-weight: 500;color: #8A98AB;font-size: 14px;font-family: poppins;line-height: 18px">
                  {{ hdr.slider_name || 'Activity since days' }} :
               </span>

               <span style="color: #101D42;font-weight: 700; padding: 0 7px;">
                  {{ this.sliderValue }}
               </span>

               <mat-slider #gridsize style="width: 150px;" step="1" tickInterval="2"
                  [min]="hdr.slider_custom_range ? hdr.sliderLenth[0].min : 1"
                  [max]="hdr.slider_custom_range ? hdr.sliderLenth[0].max : 7"
                  [(ngModel)]="this.sliderValue" (change)="updateSetting($event)">
               </mat-slider>

            </div>
         </div>

      </div>
   </div>
   <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
         <div *ngFor="let hdr of headerConfig">

            <!-- ShowBy Mobile -->
            <div *ngIf="hdr.value == 'sort_by' && isPortraitMode" class="d-flex dropdown_val align-items-center"
               style="width: max-content;">
               <div class="sort_by_styles ml-2" style="flex:inherit;margin-top: 5px">
                  <label for="sortBy" class="sort_by_text mt-2">Show By |</label>
                  <select class="sort_by_select text-center" id="sortBy" name="Select" (change)="sortByChange($event)">
                     <option *ngFor="let data of sortByArray" [value]="data['dropdownName']" class="dropdown_values">
                        {{data['dropdownName']}}</option>
                  </select>
               </div>
            </div>

            <!-- PHI Toggle Button iPad -->
            <div class="mt-2" *ngIf="hdr.hidden_toggle && isPortraitMode">
               <span style="font-weight: 600; font-size: 14px;font-family: poppins;line-height: 18px;padding: 0 15px;">
                  {{ hdr.hidden_toggle_name || "PHI Column" }}
               </span>

               <mat-slide-toggle color="primary" [checked]="this.filterService.phiChecked"
                  (change)="isPHIFunc($event)"></mat-slide-toggle>
            </div>
         </div>
      </div>
   </div>
   <div style="text-align: center;" *ngIf="isPortraitMode">
      <div class="d-flex justify-content-center align-items-center">
         <div  *ngFor="let hdr of headerConfig">

            <!-- Search Box Mobile -->
            <div *ngIf="hdr.value=='search_box'">
               <input type="text" 
                  style="border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;margin-top: 5px;"
                  id="filter-text-box" autocomplete="off" placeholder="Type to Search" (input)="onQuickFilterChanged($event)">
            </div>
         </div>
      </div>
   </div>
</div>


<!-- Icons for iPad -->
<div *ngIf="this.deviceDetectionService.isTablet">
   <div class="row" [ngClass]="{'fullScreenScroll' : this.isFullscreen}">
      <div class="col-12 d-flex justify-content-between align-items-center">
         <div  *ngFor="let hdr of headerConfig">

            <!-- Widget Heading iPad -->
            <span *ngIf="hdr.header_name_isNeed"  [id]="heading.replaceAll(' ', '')==''?'header':heading.replaceAll(' ', '')" style="font-weight: 600;font-family: poppins; line-height: 24px;font-size: 20px;display: flex;margin-left: 7px;">
               <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key != 'from_date'" style="margin-right: 5px;">
                  {{ this.findWhichKey(hdr.sp_nm_visibility_key, hdr.firstValue) + ", " }}
               </span>
               
               <span *ngIf="hdr.sp_nm_visibility && filterService.baseQuery?.[hdr.sp_nm_visibility_key] && hdr.sp_nm_visibility_key == 'from_date'" style="margin-right: 5px;">
                  {{ this.findPrefixDateFormat(hdr) }}
               </span>
               
               {{ filterService.baseQuery?.source_nm && sourceHeaderName ? hdr.page_header_name +' '+( (filterService.showByHeader=='true') ? (filterService.showByKey=='N'?'NDC':'Location'):'') : hdr.page_header_name+' '+( (filterService.showByHeader=='true')? (filterService.showByKey=='N'?'NDC':'Location'):'') }}
               
               <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key != 'from_date'" style="margin-left: 5px;">
                  <span *ngIf="cardId=='single'">{{ " "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span>
                 
                  <span *ngIf="cardId!='single'" style="color: #1363DF;white-space: normal;">  {{ " - "+ this.findWhichKey(hdr.spb_nm_visibility_key, hdr.secondValue) }}</span> 
                  
                  <span *ngIf="cardId!='single'">{{" - Q"+ moment(filterService.baseQuery?.['from_date']).quarter()+' '+moment(filterService.baseQuery?.['from_date']).year()}}</span>
                  
                  <span *ngIf="cardId!='single' && this.detailData.length>0"> 
                     {{ ' - ' }}
                     <span *ngFor="let a of detailData;let last = last;let idx = index;">
                        
                        <span>{{ (a.filterKey == 'date' ? moment(a[a.key]).format("MM/DD/YYYY") : a[a.key]) }}</span>
   
                        <span *ngIf="!last">{{ ' - ' }}</span>
                     </span>
                  </span>
               </span>
               
               <span *ngIf="hdr.spb_nm_visibility && filterService.baseQuery?.[hdr.spb_nm_visibility_key] && hdr.spb_nm_visibility_key == 'from_date'" style="margin-left: 5px;">
                  {{ this.findSuffixDateFormat(hdr) }}
               </span>
   
               <span *ngIf="hdr.data_count == true" style="color: #8A98AB;margin: 0 0 0 5px;">
                  {{'(' + rowDataLength.length + ')' || '(0)'}}
               </span>
            </span>

            <!-- Alerts Mobile/iPad -->
            <div *ngIf="hdr.value == 'alerts' && !isPortraitMode">
               <app-alert-icons-commom class="ml-2 mr-2" [alertIcons]="alertIcons"></app-alert-icons-commom>
            </div>

            <!-- Legends Laptop -->
            <span *ngIf="hdr.value=='legends' && !isPortraitMode" class="d-flex align-items-center du-legends-header mx-2">
               <div *ngFor="let legend of hdr.legends_data;" class="mx-2">
                  <div class="d-flex align-items-center" style="white-space: nowrap;">
                     <span class="mx-2"><mat-icon style="height: 20px;width: 20px;overflow: visible;" [svgIcon]="legend.icon"></mat-icon></span>
                     <span class="du-legends-icons-chart">{{ legend.name }}</span>
               </div>
               </div>
            </span>
            
            <!-- Follow Up Laptop -->
            <div *ngIf="hdr.value=='follow_up'" style="display: flex;align-items: center;cursor: pointer;">
               <mat-icon (click)="followUP()" style="width: 12px;margin-right: 5px;" [svgIcon]="plus"></mat-icon>
               <span (click)="followUP()" style="color: #1363DF">Create follow up</span>
            </div>

            <!-- ShowBy iPad -->
            <div *ngIf="hdr.value == 'sort_by'" class="d-flex dropdown_val align-items-center"
               style="width: max-content;">
               <div class="sort_by_styles ml-2" style="flex:inherit">
                  <label for="sortBy" class="sort_by_text mt-2">Show By |</label>
                  <select class="sort_by_select text-center" id="sortBy" name="Select" (change)="sortByChange($event)">
                     <option *ngFor="let data of sortByArray" [value]="data['dropdownName']" class="dropdown_values">
                        {{data['dropdownName']}}</option>
                  </select>
               </div>
            </div>
            <div  *ngIf="hdr.value == 'show_by'" class="showByContainer align-items-center w-auto" style="margin: 0px auto;text-align: center;">
               <div class="sort_by_styles d-flex">
                 <label for="sortBy" class="sort_by_texts mt-2" style="border-right: 2px solid  #E8EAEE;">Show By</label>
                 <mat-select class="sort_by_select text-center" style="width: 60% !important;" [(ngModel)]="hdr.show_by_value[0].value"
                   (ngModelChange)="showByChange($event)">
                   <mat-option *ngFor="let showBy of hdr.show_by_value" [value]="showBy.value"
                     class="dropdown_values">{{showBy.title}}</mat-option>
                 </mat-select>
               </div>
             </div>
            <!-- PHI Toggle Button iPad -->
            <div *ngIf="hdr.hidden_toggle && !isPortraitMode">
               <span style="font-weight: 600; font-size: 14px;font-family: poppins;line-height: 18px;padding: 0 15px;">
                  {{ hdr.hidden_toggle_name || "PHI Column" }}
               </span>

               <mat-slide-toggle color="primary" [checked]="this.filterService.phiChecked"
                  (change)="isPHIFunc($event)"></mat-slide-toggle>
            </div>

            <!-- Search Box iPad -->
            <div *ngIf="hdr.value=='search_box' && !isPortraitMode">
               <input type="text" 
                  style="border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;"
                  id="filter-text-box" autocomplete="off" placeholder="Type to Search" (input)="onQuickFilterChanged($event)">
            </div>


            <!-- Moving Avg Box iPad -->
            <div class="widgets-container" *ngIf="hdr.value == 'moving_average'">
               <div class="d-flex">
                  <div class="iconPads" style="margin-top: -4px;padding-right: 2px;">
                     <mat-icon [svgIcon]="settingsm" style="padding: 3px 6px 3px 3px;cursor: pointer;"id="moving_average" (click)="selectedVal()"[closeOnOutsideClick]="false"
                        mwlConfirmationPopover [customTemplate]="customTemplate" placement="bottom">
                     </mat-icon>
                  </div>

                  <div class="iconPads">
                     <span style="color: #8A98AB;">
                        Moving Average Periods:
                     </span>

                     <span style="margin-left: 6px;">{{counter}}</span>
                  </div>
               </div>
            </div>

            <!-- Selection Toggle (Bar) iPad -->
            <div class="widContainers" *ngIf="hdr.value == 'selection_toggle'">
               <div class="d-flex justify-content-between">
                  <button *ngFor="let item of hdr['toggle_btn'];" (click)="changeDisplayType(item.value)"
                     [ngClass]="{'highlight': selectedType===item.value}" class="cycleHover">
                     {{item.title}}
                  </button>
               </div>
            </div>

            <!-- Time Cycle Laptop -->
            <span *ngIf="hdr.value == 'time_cycle'">
               <div class="d-flex justify-content-between" class="widget-container-timeCycle">
                  <div *ngFor="let item of hdr.timeValues" (click)="changeTimeCycleEvent($event,item.value)"
                     [ngClass]="{'highlightTimeCycle': selectedTypeTimeCyle===item.value}" class="cycleHoverTimeCycle">
                     {{item.value}}
                  </div>
               </div>
            </span>



            <!-- Page Size iPad -->
            <div *ngIf="hdr.value == 'page_size' && !isPortraitMode">
               <span>Page Size</span> &nbsp;
               <input type="number" noENotationInput [min]="minValue" [max]="maxValue" [(ngModel)]="paginationPageSize"
                  style="width: 50px;border-radius: 0.2rem;border: 2px solid #E8EAEE;margin-right: 10px;text-align: center;">
               <button class="btn btn-primary btn-sm rounded-circle" (click)="onPageSizeChanged()"
                  style="width: 30px;height: 30px;text-align: center;margin: 0 !important;padding: 0 !important;"
                  type="button">Go</button>
            </div>

            <!-- Mat Icon for Icons List Popup iPad Portrait Mode -->
            <div *ngIf="hdr.icon_selected && isPortraitMode">
               <mat-icon type="button" (click)="openDialogIcon()" class="matIcons" svgIcon="dotmenu_circle"></mat-icon>
            </div>

            <!-- Mat Icon for Icons List Popup iPad Landscape Mode-->
            <div *ngIf="hdr.icon_selected && !isPortraitMode">
               <span *ngFor="let icon of hdr.iconList">
                  <span *ngIf="icon.activated">
                     <mat-icon matTooltip="Export List" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name=='dotmenu_circle'" [matMenuTriggerFor]="Exportmenu"></mat-icon>
                        
                     <mat-menu #Exportmenu="matMenu">
                        <span *ngFor="let ic of icon?.event">
                           <a *ngIf="ic.is_true==true" mat-menu-item style="font-family: 'Poppins'!important;"
                              (click)="ic.value == 'pdf' || ic.value == 'pdftable' ? openPDFcustom(ic.value) : ic.value == 'excel' ? excelExport() : testIcon()">
                              <mat-icon
                                 [svgIcon]="ic.value == 'pdf' || ic.value == 'pdftable' ? 'pdf' : ic.value == 'excel' ? 'xls' : ''"></mat-icon>{{ic.dispPlayName}}</a>
                        </span>
                     </mat-menu>

                     <mat-icon matTooltip="Help" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='info_circle'" [matMenuTriggerFor]="info"
                        (click)="getInfoDetail()"></mat-icon>
                     <mat-icon [matTooltip]=" isFullscreen ? ' Exit Full Screen': 'Show Full Screen'" class="matIcons"
                        [svgIcon]=" isFullscreen ? 'collapse_arrow':icon.name " *ngIf="icon.name =='expand_circle'"
                        (click)="isFullscreen ? closeFullscreen() : openFullscreen()"></mat-icon>
                     <mat-icon matTooltip="Change Status" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='change_status'" (click)="changeStatus()"></mat-icon>
                     <mat-icon matTooltip="Show Underlying Data" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='tabular_circle'" (click)="openDialog()"></mat-icon>
                     <mat-icon matTooltip="User Union" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='union_circle'"></mat-icon>
                     <mat-icon [matTooltip]="  compareChart ?' ':'Compare'"
                        [class]="compareChart ?'compareIcons': 'matIcons'"
                        [svgIcon]=" compareChart ?'compareCross': icon.name" *ngIf="icon.name =='compare'"
                        (click)="Compare()"></mat-icon>
                     <mat-icon matTooltip="Setting" id="setting" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='setting'" [matMenuTriggerFor]="setting" placement="bottom"></mat-icon>

                     <!-- <mat-icon matTooltip="Settings" id="setting" class="matIcons" [svgIcon]="icon.name" *ngIf="icon.name =='setting'"
                     mwlConfirmationPopover [customTemplate]="SettingTemplate" placement="bottom"></mat-icon> -->
                     <mat-icon matTooltip="Expand All" class="matIcons" [svgIcon]="icon.name"
                        *ngIf="icon.name =='gridload_circle' && !expandChart" (click)="expandCollapse()"></mat-icon>
                     <mat-icon matTooltip="Collapse All" class="matIcons" svgIcon="collapse_new"
                        *ngIf="icon.name =='gridload_circle' && expandChart" (click)="expandCollapse()"></mat-icon>
                     <mat-icon [svgIcon]="expandCollapseIcon" class="matIcons" *ngIf="icon.name =='expand'"
                        (click)="pivotexpandCollapse()" [matTooltip]="expandOrCollapse"></mat-icon>
                     <!-- [svgIcon]="expandCollapseIcon" -->
                  </span>
               </span>
            </div>
         </div>
      </div>
   </div>
   <div class="row mt-2 mb-3">
      <div class="col-12 d-flex justify-content-center align-items-center">
         <div class="pl-2" *ngFor="let hdr of headerConfig">

            <!-- Network Header Label iPad -->
            <div *ngIf="hdr.value == 'status_label' && selectedFilter.values.length < 3"
               class="d-flex w-max-content pipeline_sort">
               <div class="d-flex align-items-center pipeline_sort_icon ml-2"
                  *ngFor="let item of selectedFilter.values">
                  <mat-icon [style.color]="item.color">circle</mat-icon>
                  <span class="option_value" style="flex: none !important;">{{item.name}}</span>
               </div>
            </div>

            <!-- Network Header Label iPad -->
            <div *ngIf="hdr.value == 'status_label' && selectedFilter.values.length > 3"
               class="d-flex flex-column w-max-content pipeline_sort">
               <div class="d-flex align-items-center pipeline_sort_icon ml-2"
                  *ngFor="let item of selectedFilter.values">
                  <mat-icon [style.color]="item.color">circle</mat-icon>
                  <span class="option_value" style="flex: none !important;">{{item.name}}</span>
               </div>
            </div>

            <!-- Legends Laptop -->
            <span *ngIf="hdr.value=='legends' && isPortraitMode" class="d-flex align-items-center du-legends-header mx-2">
               <div *ngFor="let legend of hdr.legends_data;" class="mx-2">
                  <div class="d-flex align-items-center" style="white-space: nowrap;">
                     <span class="mx-2"><mat-icon style="height: 20px;width: 20px;overflow: visible;" [svgIcon]="legend.icon"></mat-icon></span>
                     <span class="du-legends-icons-chart">{{ legend.name }}</span>
               </div>
               </div>
            </span>

            <!-- Search Box iPad -->
            <div *ngIf="hdr.value=='search_box' && isPortraitMode">
               <input type="text" autocomplete="off" 
                  style="border: 1px solid #ddd;border-radius: 5px;font-family: 'Poppins';font-size: 12px;"
                  id="filter-text-box" placeholder="Type to Search" (input)="onQuickFilterChanged($event)">
            </div>

            <!-- PHI Toggle Button iPad -->
            <div *ngIf="hdr.hidden_toggle && isPortraitMode">
               <span style="font-weight: 600; font-size: 14px;font-family: poppins;line-height: 18px;padding: 0 15px;">
                  {{ hdr.hidden_toggle_name || "PHI Column" }}
               </span>

               <mat-slide-toggle color="primary" [checked]="this.filterService.phiChecked"
                  (change)="isPHIFunc($event)"></mat-slide-toggle>
            </div>

            <!-- Page Size iPad -->
            <div *ngIf="hdr.value == 'page_size' && isPortraitMode">
               <span>Page Size</span> &nbsp;
               <input type="number" noENotationInput [min]="minValue" [max]="maxValue" [(ngModel)]="paginationPageSize"
                  style="width: 50px;border-radius: 0.2rem;border: 2px solid #E8EAEE;margin-right: 10px;text-align: center;">
               <button class="btn btn-primary btn-sm rounded-circle" (click)="onPageSizeChanged()"
                  style="width: 30px;height: 30px;text-align: center;margin: 0 !important;padding: 0 !important;"
                  type="button">Go</button>
            </div>

            <!-- Alerts Mobile/iPad -->
            <div *ngIf="hdr.value == 'alerts' && isPortraitMode">
               <app-alert-icons-commom class="ml-2 mr-2" [alertIcons]="alertIcons"></app-alert-icons-commom>
            </div>

            <!-- Thumb Slider iPad -->
            <div *ngIf="hdr.slider && !isPortraitMode" style="white-space: nowrap;">

               <span style="font-weight: 500;color: #8A98AB;font-size: 14px;font-family: poppins;line-height: 18px">
                  {{ hdr.slider_name || 'Activity since days' }} :
               </span>

               <span style="color: #101D42;font-weight: 700; padding: 0 7px;">
                  {{ this.sliderValue }}
               </span>

               <mat-slider #gridsize style="width: 150px;" step="1" tickInterval="2"
                  [min]="hdr.slider_custom_range ? hdr.sliderLenth[0].min : 1"
                  [max]="hdr.slider_custom_range ? hdr.sliderLenth[0].max : 7"
                  [(ngModel)]="this.sliderValue" (change)="updateSetting($event)">
               </mat-slider>

            </div>

         </div>
      </div>
   </div>
</div>


<mat-menu #setting="matMenu">
   <div mat-subheader
      style="background-color: #e8e8e8;margin: 3px;border-radius: 8px 8px 0px 0px;padding: 10px;margin: 2px;"> Table
      view</div>
   <div style="margin: 10px; cursor: pointer;" (click)="this.pivotGrid.showByValue('source')"> By SP </div>
   <div style="margin: 10px; cursor: pointer;" (click)="this.pivotGrid.showByValue('NDC')"> By NDCs </div>
</mat-menu>


<!-- <mat-menu #menu="matMenu">
   <a mat-menu-item *ngIf="chartName!='pivotGrid'" style="font-family: 'Poppins'!important;" (click)="openPDFcustom()">
      <mat-icon svgIcon="pdf"></mat-icon>Export PDF</a>
   <a mat-menu-item style="font-family: 'Poppins'!important;" (click)="excelExport()"><mat-icon
         svgIcon="xls"></mat-icon>Export XLS</a>
</mat-menu> -->


<ng-template #dialogTemplate let-data="data">

   <div class="deletePopup" style="font-family: Poppins;">
      <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;">
          <div class="p-2">
              <span style="font-size: 15px;font-weight: 600;">{{item?.config['report_heading'] || 'Options'}}</span>
          </div>
          <div class="p-2" style="margin: 0 0 -5px 0px !important;">
              <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
          </div>
      </div>

      <div mat-dialog-content class="matDialogg">
         <div class="row">
            <div class="col-12" *ngFor="let icon of iconList1">
               <div class="d-flex" style="align-items:center;">
                  <div class="d-flex flex-column" *ngIf="icon.name =='Slider'">
                     <div class="mr-2">
                        <span
                           style="font-weight: 500;color: #8A98AB;font-size: 14px;font-family: poppins;line-height: 18px">Activity
                           since(days):
                        </span>
                     </div>
                     <div class="d-flex justify-content-between align-items-center">
                        <span style="color: #101D42;font-weight: 700; padding: 0 7px;">{{
                           gridsize.value?gridsize.value:this.sliderValue}}</span>

                           <mat-slider #gridsize style="width: 210px;" step="1" tickInterval="2"
                              [min]="icon.slider_custom_range ? icon.sliderLenth[0].min : 1"
                              [max]="icon.slider_custom_range ? icon.sliderLenth[0].max : 7"
                              [(ngModel)]="this.sliderValue" (change)="updateSetting($event)">
                           </mat-slider>

                        <!-- <mat-slider class="mr-3" #gridsize (change)="updateSetting($event)" style="width: 210px;" step="1"
                           tickInterval="2" min="1" max="7" [(ngModel)]="this.sliderValue"></mat-slider> -->
                     </div>
                  </div>
                  <mat-icon class="matIcons" [svgIcon]="icon.name" *ngIf="icon.name =='info_circle' && icon.activated"
                     [matMenuTriggerFor]="info" (click)="getInfoDetail()"></mat-icon>
   
                  <mat-icon class="matIcons" [svgIcon]=" isFullscreen ? 'collapse_arrow':icon.name "
                     *ngIf="icon.name =='expand_circle' && icon.activated"
                     (click)="isFullscreen ? closeFullscreen() : openFullscreen()"></mat-icon>
   
                  <mat-icon class="matIcons" [svgIcon]="icon.name" *ngIf="icon.name =='tabular_circle' && icon.activated"
                     (click)="openDialog()"></mat-icon>
   
                  <mat-icon class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='union_circle' && icon.activated"></mat-icon>
   
                  <mat-icon class="matIcons" [svgIcon]="icon.name" *ngIf="icon.name =='change_status' && icon.activated"
                     (click)="changeStatus()"></mat-icon>
   
                  <mat-icon [class]="compareChart ?'compareIcons': 'matIcons'"
                     [svgIcon]=" compareChart ?'compareCross': icon.name" *ngIf="icon.name =='compare' && icon.activated"
                     (click)="Compare()"></mat-icon>
   
                  <mat-icon matTooltip="Setting" id="setting" class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='setting'" [matMenuTriggerFor]="setting" placement="bottom"></mat-icon>
   
                  <mat-icon class="matIcons" [svgIcon]="icon.name"
                     *ngIf="icon.name =='gridload_circle' && !expandChart && icon.activated"
                     (click)="expandCollapse()"></mat-icon>
   
                  <mat-icon class="matIcons" svgIcon="collapse_new"
                     *ngIf="icon.name =='gridload_circle' && expandChart && icon.activated"
                     (click)="expandCollapse()"></mat-icon>
   
                  <mat-icon class="matIcons" svgIcon="pdf" (click)="openPDFcustom('pdf')" *ngIf="icon.name=='PDF'"></mat-icon>
   
                  <mat-icon class="matIcons" svgIcon="xls" (click)="excelExport()" *ngIf="icon.name=='Excel'"></mat-icon>
   
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name=='Excel'">Export as CSV</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name=='PDF'">Export as PDF</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='info_circle' && icon.activated">Information
                  </div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='change_status' && icon.activated">Change
                     Status</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='expand_circle' && icon.activated">
                     {{isFullscreen ?
                     'Exit Full Screen' : 'Full Screen'}}</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='tabular_circle' && icon.activated">Show
                     Underlying
                     Data</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='union_circle' && icon.activated">User View
                  </div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='compare' && icon.activated">Compare</div>
                  <div class="ml-3 icon_description_resp" *ngIf="icon.name =='setting' && icon.activated">Settings</div>
                  <div class="ml-3 icon_description_resp"
                     *ngIf="icon.name =='gridload_circle' && expandChart && icon.activated">
                     Collapse All</div>
                  <div class="ml-3 icon_description_resp"
                     *ngIf="icon.name =='gridload_circle' && !expandChart && icon.activated">
                     Expand All</div>
               </div>
               <hr style="border: 1px solid rgba(0, 0, 0, 0.05);" />
            </div>
         </div>
      </div>
  </div>
</ng-template>


<mat-menu #info="matMenu">
   <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
      <div class="card-header d-flex justify-content-between align-items-center" style="border-bottom: none;background: #ddd;">
         <span style="font-weight: 600;">{{infoData?.info_header || ''}}</span>

         <span (click)="closeMenu()" mat-dialog-close> 
            <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z" fill="#1363DF" />
            </svg>
         </span>
      </div>

      <div class="card-body">
         <div [innerHtml]="(infoData?.info_text) || '' "></div>
      </div>
   </div>
</mat-menu>

<!-- <mat-menu #menu="matMenu">
   <button mat-menu-item style="font-family: 'Poppins'!important;" (click)="openPDFcustom()"> <mat-icon
         svgIcon="pdf"></mat-icon>Export PDF</button>
   <button mat-menu-item style="font-family: 'Poppins'!important;" (click)="excelExport()"><mat-icon
         svgIcon="xls"></mat-icon>Export XLS</button>
</mat-menu> -->

<ng-template #prompt style="height: 200px; width: auto;">

   <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;">
      <div class="px-3 py-2">
          <h2 style="font-weight: 500;font-family: 'Poppins';margin: 0 !important;">{{ (filterService.showByHeader=='true') ? (filterService.showByKey=='N'? (item.config['report_heading']+' NDC'):(item.config['report_heading']+' Location')) : ( this.SubHeading?this.SubHeading:item.config['report_heading'] ) }}</h2>
      </div>
      <div class="px-3 py-2" style="margin: 0 0 -5px 0px !important;">
          <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
      </div>
   </div>

   <pivot-grid [dateFormat]="dateFormat" [data]="gridData" [item]="item" [columnDef]="columnDefs" pagination="false"
      [pivot]="pivot"
   ></pivot-grid>
</ng-template>
<ng-template #controlPopup style="height: 200px; width: auto;"> 

   <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;">
      <div class="px-3 py-2" style="display: flex;">
          <h2 style="font-weight: 600;font-family: 'Poppins';margin: 0 !important;">{{'Transaction Details'}}</h2>
          <span style="margin: 4px 14px;">|</span> 
          <h4 style="font-weight: 600;font-family: Poppins;padding: 5px;margin: 0 !important;">{{filterService.dragDate.length>0 ? moment(this.filterService.dragDate[0]).format('MMM DD, yyyy') +' - '+moment(this.filterService.dragDate[1]).format('MMM DD, yyyy'): moment(filterService.baseQuery.from_date).format('MMM DD, yyyy') +' - '+ moment(filterService.baseQuery.to_date).format('MMM DD, yyyy')}}</h4>
      </div>
      <div class="px-3 py-2" style="margin: 0 0 -5px 0px !important;">
          <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1); float: right;" >close</mat-icon>
      </div>
   </div>
   <div  mat-dialog-content *ngIf="!clicked"  style="height: 200px;width: 100%;background: white;text-align: center;padding-top: 75px;font-family: Poppins !important;font-weight: 600;font-size: 19px;color: gray;"  >Loading.... </div>
   <div  mat-dialog-content *ngIf="clicked&&gridData.length==0"  style="height: 200px;width: 100%;background: white;text-align: center;padding-top: 75px;font-family: Poppins !important;font-weight: 600;font-size: 19px;color: gray;"  >There is no data for this time period </div>
      <pivot-grid *ngIf="clicked&&gridData.length>0" [dateFormat]="dateFormat" [data]="gridData" [item]="item" [columnDef]="columnDefs" [pagination]="true"
      [pivot]="pivot"
   ></pivot-grid>
</ng-template>
<ng-template #pdfCustom style="height: 100px; width: auto;">
   <div class="header" style="height: 40px;">
      <div class="row">
         <div class="col-9 text-start pl-2">
            <h2 class="pl-4 mt-1" style="font-family: 'Poppins'!important;">{{"PDF Position"}}</h2>
         </div>
         <div class="col-3 text-end pt-1">
            <mat-icon class="pr-5 mt-1" [mat-dialog-close]="true">close</mat-icon>
         </div>
      </div>
   </div>
   <div style="width:100%; text-align: center; padding: 10px;">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="PdfPosition">
         <mat-radio-button value="l">Landscape </mat-radio-button>
         <mat-radio-button class="ml-5" value="p">Portrait </mat-radio-button>
      </mat-radio-group>
   </div>
   <div style="text-align: center; padding: 10px;">
      <button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="onSaveChartClicked();">PDF
         Export</button>
   </div>
</ng-template>


<ng-template #SettingTemplate  >
   
   <div class="popoverCustom"   *ngIf="settingpopup" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="header all-scroll " style="height: 40px;margin: -12px;border-radius: 5px;">
         <div class="row">
            <div class="col-6 text-start pl-2" style="margin: 8px 10px;">
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M13.6057 7.37666L9.77561 7.37666V8.62341H13.6057L12.1175 10.1116L13.0029 10.9971L16 8.00004L13.0029 5.00297L12.1175 5.88842L13.6057 7.37666Z"
                     fill="#8A98AB" />
                  <path
                     d="M8.62521 6.22626L8.62334 2.39428L10.1116 3.88252L10.997 2.99707L7.99996 0L5.0029 2.99707L5.88835 3.88252L7.37659 2.39428L7.37659 6.22439L8.62521 6.22626Z"
                     fill="#8A98AB" />
                  <path
                     d="M7.37666 9.77561L7.37666 13.6057L5.88842 12.1175L5.00297 13.0029L8.00004 16L10.9971 13.0029L10.1116 12.1175L8.62341 13.6057L8.62528 9.77374L7.37666 9.77561Z"
                     fill="#8A98AB" />
                  <path
                     d="M3.88252 5.88835L2.99707 5.0029L0 7.99996L2.99707 10.997L3.88252 10.1116L2.39428 8.62334L6.22626 8.62521V7.37472L2.39428 7.37659L3.88252 5.88835Z"
                     fill="#8A98AB" />
               </svg>
            </div>
            <div class="col-3 text-end pt-1 justify-content-between contect" style="display: flex; margin: 0 24px;">
               <h3>Settings</h3>
               <!-- <span class="pl-2 " style="margin-top: 4px;">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M0.191807 6.4955L0.584883 6.8913C0.708591 7.01492 0.873178 7.08301 1.04908 7.08301C1.22489 7.08301 1.38967 7.01492 1.51338 6.8913L5.99732 2.40717L10.4864 6.89628C10.6099 7.01989 10.7747 7.08789 10.9505 7.08789C11.1263 7.08789 11.2912 7.01989 11.4148 6.89628L11.808 6.50291C12.064 6.2471 12.064 5.83042 11.808 5.57461L6.46317 0.210567C6.33966 0.087054 6.17507 2.84539e-05 5.99771 2.84694e-05L5.99566 2.84696e-05C5.81975 2.8485e-05 5.65517 0.0871516 5.53165 0.210567L0.191807 5.56007C0.0680982 5.68359 0.000194914 5.85315 -1.94455e-07 6.02896C-1.79077e-07 6.20486 0.0680983 6.37208 0.191807 6.4955Z"
                        fill="#8A98AB" />
                  </svg></span> -->

            </div>
         </div>
      </div>
      <!-- <form [formGroup]="userForm" (ngSubmit)="onSubmit(this.userForm.value)"> -->
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headS">Std deviation</span> <mat-slide-toggle class="togControl" [(ngModel)]="Stddeviation" 
               style="float: right;"></mat-slide-toggle></div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headSub">{{'Lookback period (' + this.getPeriodtext()+')'}}</span> <input type="number"  min="0" [(ngModel)]="look_back_period"  #lookbackperiod (keydown)="restictnumberneg($event, lookbackperiod)"
               style="float: right; width: 70px; height: 30px;" class="form-control" [disabled]="!Stddeviation">
         </div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headSub">No Std deviations </span> <input type="number"  min="0" [(ngModel)]="no_of_std_deviations"  #noofstddeviations (keydown)="restictnumberneg($event, noofstddeviations)"
               style="float: right; width: 70px; height: 30px;" class="form-control" [disabled]="!Stddeviation" ></div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headS">Thresholds values</span> <mat-slide-toggle class="togControl" [(ngModel)]="Thresholdsvalues"
               style="float: right;"></mat-slide-toggle></div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headSub">Upper thresholds</span> <input type="number"  [(ngModel)]="upper_threshold"  [min]="lower_threshold>0?lower_threshold:0" #upperthreshold (keydown)="restictnumberneg($event, upperthreshold)"
               style="float: right; width: 70px; height: 30px;" class="form-control" [disabled]="!Thresholdsvalues">
         </div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headSub">Lower thresholds</span> <input type="number" min="0"  [max]="upper_threshold" [(ngModel)]="lower_threshold"  #lowerthreshold (keydown)="restictnumberneg($event, lowerthreshold)"
               style="float: right; width: 70px; height: 30px;" class="form-control" [disabled]="!Thresholdsvalues"></div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headS">Mean (Avg)</span> <mat-slide-toggle class="togControl" [(ngModel)]="MeanAvg"
               style="float: right;"></mat-slide-toggle></div>
      </div>
      <!-- <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headS">Buffer</span><mat-slide-toggle [(ngModel)]="Buffer"
            style="float: right;"></mat-slide-toggle> </div>
      </div>
      <div class="mt-3" style="text-align: left !important;">
         <div> <span class="headSub">Buffer range, %</span> 
            <input type="number"  min="0" max="100"  #inputText (keydown)="checkLength2($event, inputText)"  [(ngModel)]="buffer_range"   
               style="float: right; width: 70px; height: 30px;"  class="form-control"  [disabled]="!Buffer"></div>
      </div> -->
      <div class="mt-3 mb-2" style="text-align: left !important;">
         <div> 
            <span class="headSub">
               <!-- <mat-checkbox class="example-margin" [(ngModel)]="checkedPreview" (change)="preview()">Preview</mat-checkbox> -->
               <button type="button" class="btn btn-preview"
                 (click)="preview()">Preview</button>
               </span>
                 <span class="headSub" style="float: right;"><button type="button"  class="btn  btn-reset"
                  (click)="reset()">Reset</button></span>
            <!-- <div style="float: right; " (click)="reset()"><span style="margin-right: 4px;color: red;">Reset</span>
               <svg viewBox="260.084 137.146 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="268.084" cy="145.146" r="8" style="fill: rgb(255, 0, 0);" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.842170943040401e-14, 0)"/>
                 <path d="M 270.064 142.11 L 268.084 144.09 L 266.104 142.11 C 265.885 141.891 265.53 141.891 265.311 142.11 L 265.047 142.374 C 264.829 142.592 264.829 142.947 265.047 143.166 L 267.028 145.146 L 265.047 147.126 C 264.829 147.344 264.829 147.699 265.047 147.918 L 265.311 148.182 C 265.53 148.4 265.885 148.4 266.104 148.182 L 268.084 146.202 L 270.064 148.182 C 270.282 148.4 270.637 148.4 270.856 148.182 L 271.12 147.918 C 271.338 147.699 271.338 147.344 271.12 147.126 L 269.14 145.146 L 271.12 143.166 C 271.338 142.947 271.338 142.592 271.12 142.374 L 270.856 142.11 C 270.637 141.891 270.282 141.891 270.064 142.11 Z" fill="white" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.842170943040401e-14, 0)"/>
                 <path d="M 270.064 142.11 L 268.084 144.09 L 266.104 142.11 C 265.885 141.891 265.53 141.891 265.311 142.11 L 265.047 142.374 C 264.829 142.592 264.829 142.947 265.047 143.166 L 267.028 145.146 L 265.047 147.126 C 264.829 147.344 264.829 147.699 265.047 147.918 L 265.311 148.182 C 265.53 148.4 265.885 148.4 266.104 148.182 L 268.084 146.202 L 270.064 148.182 C 270.282 148.4 270.637 148.4 270.856 148.182 L 271.12 147.918 C 271.338 147.699 271.338 147.344 271.12 147.126 L 269.14 145.146 L 271.12 143.166 C 271.338 142.947 271.338 142.592 271.12 142.374 L 270.856 142.11 C 270.637 141.891 270.282 141.891 270.064 142.11 Z" fill="#8A98AB" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.842170943040401e-14, 0)"/>
                 <path d="M 270.064 142.11 L 268.084 144.09 L 266.104 142.11 C 265.885 141.891 265.53 141.891 265.311 142.11 L 265.047 142.374 C 264.829 142.592 264.829 142.947 265.047 143.166 L 267.028 145.146 L 265.047 147.126 C 264.829 147.344 264.829 147.699 265.047 147.918 L 265.311 148.182 C 265.53 148.4 265.885 148.4 266.104 148.182 L 268.084 146.202 L 270.064 148.182 C 270.282 148.4 270.637 148.4 270.856 148.182 L 271.12 147.918 C 271.338 147.699 271.338 147.344 271.12 147.126 L 269.14 145.146 L 271.12 143.166 C 271.338 142.947 271.338 142.592 271.12 142.374 L 270.856 142.11 C 270.637 141.891 270.282 141.891 270.064 142.11 Z" style="mix-blend-mode: overlay; fill-rule: evenodd; paint-order: fill; fill: rgb(252, 252, 252);" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.842170943040401e-14, 0)"/>
               </svg>
            </div> -->
         </div>
      </div>
      <hr>
      <div class=" text-right">
         <!-- <button type="button" class="btn btn-cancel" (click)="preview()">Preview</button> -->
         <button type="button" class="btn btn-cancel" style="line-height: 15px;" (click)="cancel()">Cancel</button>
         <button type="button" class="btn btn-primary" style="line-height: 15px;" (click)="Apply()">Apply</button>
         <!-- <button
         type="submit"
         [ngClass]="{'opacity-50': !userForm.valid }"
       
         [disabled]="!userForm.valid"
       >
         Submit
       </button> -->
      </div>
      <!-- </form> -->
   </div>
</ng-template>
<!-- <ng-template #SettingTemplate let-options="options">
   <div class="popoverCustom">
      <div class="header" style="height: 40px;margin: -12px;border-radius: 5px;">
         <div class="row">
            <div class="col-9 text-start pl-2">
            <h2 class="pl-4 mt-1" style="font-family: 'Poppins'!important;font-size: 15px;">{{"Masking Settings"}}</h2> 
         </div>
         <div class="col-3 text-end pt-1">
            <mat-icon class="pr-5 mt-1" [mat-dialog-close]="true" (click)="cancel()" >close</mat-icon>
         </div>
         </div>
      </div>
       <div class="mt-4" style="text-align: left !important;">
          <span class="head">Select the only SP that iwould be shown in a legend,
            the other one’s names would be masked (hidden) from you.</span>
       </div>
       <div style="margin: 13px auto !important ; width: 90%;" >
         <select   placeholder="Show all"  class="form-select" [(ngModel)]="SettingValue"   (ngModelChange)="setSettingValue($event)" >
             <option [defaultSelected]="true" >Show all</option>
              <option [value]="'ONCO360'">ONCO360</option>
              <option [value]="'BIOLOGICS'">BIOLOGICS</option>
              <option [value]="'RXCROSSROADS'">RXCROSSROADS</option>
              <option [value]="'AMBER'">AMBER</option>
              <option [value]="'MCKESSON HUB'">MCKESSON HUB</option>
          </select>
          
       </div>
       <div class=" text-right">
           <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
           <button type="button" class="btn btn-primary" (click)="cancel()">Apply</button>
       </div>
   </div>        
</ng-template> -->



<mat-menu #iPadMode="matMenu" class="menu-center">
   <div (click)="$event.stopPropagation()" *ngIf="this.deviceDetectionService.isTablet">
      <div>
         Chat Settings
         <br>
      </div>
      <div *ngFor="let hdr of headerConfig">
         <div *ngIf="hdr.add_header">
            <div *ngIf="hdr.slider">
               <span style="position: relative;">

                  <!-- <mat-form-field> -->
                  <mat-label class="matClassForRel">Activity Since</mat-label>
                  <mat-select>
                     <mat-option *ngFor="let ds of sliderArray" [value]="ds['value']">
                        {{ds['name']}}
                     </mat-option>
                  </mat-select>
                  <!-- </mat-form-field> -->

               </span>
            </div>
         </div>
      </div>
   </div>

</mat-menu>

<ng-template #customTemplate let-options="options">

   <div class="popoverCustom">

      <div class="mb-2">

         <span class="heading">Set moving average period</span>

      </div>

      <div class="input-group mb-3">

         <span class="input-group-text" (click)="decrement()"><mat-icon [svgIcon]="minus"
               class="addicon minusicon"></mat-icon></span>

         <input type="text" (keypress)="numberOnly($event)"  [(ngModel)]="counter"   maxlength="1" class="form-control counter" >

         <span class="input-group-text" (click)="increment()"><mat-icon [svgIcon]="plus" class="addicon"></mat-icon>

         </span>

      </div>

      <div class="p-2 text-right">

         <button type="button" class="btn btn-cancel" (click)="movingavgs()">Cancel</button>

         <button type="button" class="btn btn-primary" (click)="countSave(customTemplate)">Apply</button>

      </div>

   </div>

</ng-template>
<ng-template #ExportToTablePDF>
   <app-pdf-export-popup 
     [columnDefs]="exportcolumnDefs"
     [rowData]="data" 
     [columnDefs2]="[]" 
     >
   </app-pdf-export-popup>
 </ng-template>