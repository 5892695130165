import * as _ from 'lodash';
import { GridsterItem } from "angular-gridster2"
import { Widget1kpiCardComponent } from './card1kpi/card1kpi.component'
import { Widget2kpiCardComponent } from './card2kpi/card2kpi.component'
import { WidgetDonutChartCardComponent } from './donut-chart-card/donut-chart-card.component'
import { WidgetLinkCardComponent } from './link-card/link-card.component'
import { WidgetTextNotesComponent } from './text-notes/text-notes.component'
import { TimeToTherapy } from './time-to-therapy/time-to-therapy'
import { DetailGridEditingComponent } from '../shared/grid/detail-grid-editing/detail-grid-editing.component'
import { AreaLineChartComponent } from './area-line-chart/area-line-chart.component'
import { WidgetBarChartCardComponent } from './bar-chart-card/bar-chart-card.component'
import { BasicHeatmapCardComponent } from './basic-heatmap-card/basic-heatmap-card.component'
import { WidgetBubbleChartComponent } from './bubble-chart-card/bubble-chart-card.component'
import { BulletChartCardComponent } from './bullet-chart-card/bullet-chart-card.component'
import { WidgetComboBarChartComponent } from './comboBar-chart-card/comboBar-chart-card.component'
import { ControlChartCardComponent } from './control-chart-card/control-chart-card.component'
import { CustomKpiComponent } from './custom-kpi/custom-kpi.component'
import { DataGridEditingComponent } from './data-grid-editing/data-grid-editing.component'
import { WidgetDataGridComponent } from './data-grid/data-grid.component'
import { WidgetDetailCardComponent } from './detail-card/detail-card.component'
import { FilterBadgesComponent } from './filter-badges/filter-badges.component'
import { FilterComponent } from './filter/filter.component'
import { GridPageComponent } from './grid-page/grid-page.component'
import { GroupedHbarComponent } from './grouped-hbar/grouped-hbar.component'
import { HBarChartComponent } from './h-bar-chart/h-bar-chart.component'
import { HelpCenterComponent } from './help-center/help-center.component'
import { HelpInformationComponent } from './help-information/help-information.component'
import { HierarchicalGridComponent } from './hierarchical-grid/hierarchical-grid.component'
import { WidgetHtmlNotesComponent } from './html-notes/html-notes.component'
import { InfoIconComponent } from './info-icon/info-icon.component'
import { KpiAlertsComponent } from './kpi-alerts/kpi-alerts.component'
import { WidgetLineChartComponent } from './line-chart-card/line-chart-card.component'
import { MultiLinechartComponent } from './multi-linechart/multi-linechart.component'
import { PatientCardComponent } from './patient-card/patient-card.component'
import { PatientGridListComponent } from './patient-grid-list/patient-grid-list.component'
import { PatientJourneyCardComponent } from './patient-journey-card/patient-journey-card.component'
import { PatientPipelineComponent } from './patient-pipeline/patient-pipeline.component'
import { PaymentRecordDetailsComponent } from './payment-record-details/payment-record-details.component'
import { UnderScoreDetailsComponent } from './payment-record-details/under-score-details.component'
import { PendingPatientTherapyCardComponent } from './pending-patient-therapy-card/pending-patient-therapy-card.component'
import { WidgetPivotGridComponent } from './pivot-Grid/pivot-grid.component'
import { WidgetPrescriberAccordinComponent } from './prescriber-accordin/prescriber-accordin.component'
import { PrescriberCardComponent } from './prescriber-card/prescriber-card.component'
import { PrescriberOfficesListComponent } from './prescriber-offices-list/prescriber-offices-list.component'
import { ShowByCardComponent } from './show-by-card/show-by-card.component'
import { SlopeChartComponent } from './slope-chart/slope-chart.component'
import { SmallBarChartComponent } from './small-bar-chart/small-bar-chart.component'
import { WidgetSparklineBarChartComponent } from './sparklineBar-chart/sparklineBar-chart-card.component'
import { StackedAraeBarComponent } from './stacked-arae-bar/stacked-arae-bar.component'
import { StatusCodesComponent } from './status-codes/status-codes.component'
import { WidgetSummaryGridComponent } from './summary-grid/summary-grid.component'
import { WidgetTimeCycleComponent } from './time-cycle/time-cycle.component'
import { TreeMenuComponent } from './tree-menu/tree-menu.component'
import { WordCloudComponent } from './word-cloud/word-cloud.component'
import { VennChartCardComponent } from './venn-chart-card/venn-chart-card.component'
import { PieChartCardComponent } from './pie-chart-card/pie-chart-card.component'
import { WidgetHeatmapChartComponent } from './heatmap-chart/heatmap-chart.component'
import { KpiHeatMapCardComponent } from './kpi-heat-map-card/kpi-heat-map-card.component'
import { TerritoryMapComponent } from './territory-map/territory-map.component'
import { DispenseMapComponent } from './dispense-map/dispense-map.component'
import { ClusterBarchartCardComponent } from './cluster-barchart-card/cluster-barchart-card.component'
import { IRHeatMapCardComponent } from './ir-heat-map-card/ir-heat-map-card.component'
import { ValidationInputComponent } from './data-grid-editing/validation-input.component'
import { BasicGridListComponent } from './basic-grid-list/basic-grid-list.component'
import { FilterButton } from './filter-button/filter-button'
import { AlertButton } from './alert-button/alert-button'
import { StatusOverviewWidget } from './status-overview/status-overview'
import { NestedWidgetComponent } from './nested-widget/nested-widget.component'
import { AlertSubscriptionComponent } from './alerts/alert-subscription/alert-subscription.component'
import { OperationMonitorComponent } from './operation-monitor/operation-monitor.component'
import { WidgetPaymentSummaryComponent } from './payment-summary/payment-summary.component';


export interface WidgetHeaderIconConfig {
    name: string
    value: string
    activated: boolean
}

export interface WidgetHeaderConfig {
    add_header: boolean
    name: string
    value: string
    iconList?: WidgetHeaderIconConfig[]
    icon_selected?: boolean
}

export interface WidgetConfig extends GridsterItem {
    key: number
    checked?: boolean
    config: {
        [key: string]: any
        report_heading: string
        list_of_api: any
        widget_header_config: WidgetHeaderConfig[],
    },
    nested_widget_config?: any;
}

export const RefreshDashboard = { key: 111111 }
export const SelectedTab = { key: 333333 }
export const Copyright = {
    x: 0,
    y: 100,
    cols: 50,
    rows: 1,
    key: 100202,
    config: { widgetKey: 100202, notes: '' }
}

const Placeholder = { key: 100303 }
const Unknown2 = { key: 100304 }

export const widgetGroups = {
    common: [WidgetTimeCycleComponent, InfoIconComponent, FilterButton, AlertButton], // ShowByCardComponent, 
    kpiCard: [Widget1kpiCardComponent, Widget2kpiCardComponent, WidgetDonutChartCardComponent, CustomKpiComponent],
    informationCard: [PrescriberCardComponent],
    gesture: [RefreshDashboard, FilterComponent, SelectedTab],
    desktopHide: [],
    mobileHide: [
        Widget1kpiCardComponent,
        Widget2kpiCardComponent,
        WidgetTimeCycleComponent,
        CustomKpiComponent,
        // ShowByCardComponent,
        PrescriberCardComponent,
        WidgetTextNotesComponent,
        WidgetDonutChartCardComponent,
        InfoIconComponent,
        FilterBadgesComponent,
        FilterButton,
        AlertButton,
        Placeholder,
    ],
    pageHeader: [
        FilterBadgesComponent,
        ShowByCardComponent,
        WidgetTimeCycleComponent,
        InfoIconComponent,
        AlertButton,
        WidgetTextNotesComponent,
        FilterButton,
    ],
    nonHighlight: [
        WidgetTextNotesComponent,
        WidgetTimeCycleComponent,
        WidgetHtmlNotesComponent,
        ShowByCardComponent,
        WidgetPrescriberAccordinComponent,
        PatientCardComponent,
        InfoIconComponent,
        FilterBadgesComponent,
        AlertButton,
        FilterButton,
        RefreshDashboard,
        Unknown2,
    ],
    visibilityHidden: [
        Placeholder
    ]
}

// @ts-ignore
export const keysByType: Record<keyof typeof widgetGroups, number[]> = {}
_.each(widgetGroups, (group, name) => {
  // @ts-ignore
  keysByType[name] = _.map(group, widgetComponent => widgetComponent.key)
})

export default [
    TimeToTherapy,
    Widget1kpiCardComponent,
    Widget2kpiCardComponent,
    WidgetDonutChartCardComponent,
    WidgetLinkCardComponent,
    WidgetTextNotesComponent,
    WidgetHtmlNotesComponent,
    WidgetHeatmapChartComponent,
    WidgetDataGridComponent,
    WidgetPivotGridComponent,
    WidgetLineChartComponent,
    WidgetBarChartCardComponent,
    WidgetTimeCycleComponent,
    GridPageComponent,
    FilterComponent,
    FilterBadgesComponent,
    PatientCardComponent,
    PatientJourneyCardComponent,
    PatientGridListComponent,
    WidgetBubbleChartComponent,
    PatientPipelineComponent,
    WidgetSparklineBarChartComponent,
    InfoIconComponent,
    PrescriberCardComponent,
    PendingPatientTherapyCardComponent,
    PrescriberOfficesListComponent,
    WidgetComboBarChartComponent,
    ShowByCardComponent,
    WidgetPrescriberAccordinComponent,
    WordCloudComponent,
    GroupedHbarComponent,
    AreaLineChartComponent,
    SmallBarChartComponent,
    StatusCodesComponent,
    HelpCenterComponent,
    BulletChartCardComponent,
    HierarchicalGridComponent,
    SlopeChartComponent,
    HBarChartComponent,
    BasicHeatmapCardComponent,
    KpiHeatMapCardComponent,
    MultiLinechartComponent,
    ControlChartCardComponent,
    HelpInformationComponent,
    TreeMenuComponent,
    PaymentRecordDetailsComponent,
    StackedAraeBarComponent,
    UnderScoreDetailsComponent,
    VennChartCardComponent,
    CustomKpiComponent,
    TerritoryMapComponent,
    WidgetDetailCardComponent,
    WidgetSummaryGridComponent,
    PieChartCardComponent,
    WidgetSummaryGridComponent,
    WidgetDetailCardComponent,
    KpiAlertsComponent,
    DispenseMapComponent,
    DataGridEditingComponent,
    ClusterBarchartCardComponent,
    IRHeatMapCardComponent,
    ValidationInputComponent,
    BasicGridListComponent,
    AlertButton,
    FilterButton,
    StatusOverviewWidget,
    NestedWidgetComponent,
    AlertSubscriptionComponent,
    OperationMonitorComponent,    
    WidgetPaymentSummaryComponent
]