import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ICellRendererParams } from "ag-grid-community";
import { HelperService } from "src/app/services/helper.service";

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    selector: 'app-string-conditional-format',
    template: `
        <span style="padding: 0 5px;border-radius: 3px;" [style.background-color]="params['background-color']" [style.color]="params.color">{{ params.value }}</span>
    `,
})

export class StringConditionalComponent {
    value: any;
    params: any;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
}