import { Component, Input, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { Subscription, debounceTime } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";
import { NavigationService } from "src/app/services/navigation.service";
import { NewFilterService } from "src/app/services/new-filter.service";
import { RouterHandlerService } from "src/app/services/router-handler.service";

@Component({
  selector: "app-prescriber-offices-list",
  templateUrl: "./prescriber-offices-list.component.html",
  styleUrls: ["./prescriber-offices-list.component.scss"],
})

export class PrescriberOfficesListComponent implements OnInit, OnDestroy {
  @Input("heading") heading = "Account list";
  @Input("item") item: any;
  @Input("allData") allData: any = [];
  @Input("statusArray") statusArray: any = [];

  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  routeParams: any;

  // Icons
  status_tick = "status_tick";
  status_na = "status_na";
  status_pending = "status";
  status_circle = "status_circle";
  status_ns = "status_ns";


  groupData: any = [];
  backUpGroupedData: any = [];
  isDataLoaded = false;
  toggleSelection = 'state';
  showToggle: boolean = false;
  panelOpenState = false;
  reqSubcription: Subscription[] = [];

  groupConfigKey: any = [];
  groupLocConfigKey: any = [];
  configToShowStateValues = [];
  configToShowLocationValues = [];
  configToShowStatusJourney = {};
  groupConfigObj = {};

  moduleName: any;
  cardName: any;
  decryptedParams: any;

  constructor(
    public dataSource: DataService,
    private route: ActivatedRoute,
    private newFilterService: NewFilterService,
    private dataService: DataService,
    private filterServiceOld: FilterService,
    private routerHandlerService: RouterHandlerService,
    private navigationService: NavigationService
  ) {

    // Click from Coropleth Map
    this.reqSubcription.push(
      this.dataService.getState.pipe(debounceTime(300)).subscribe((resp: any) => {

        if (resp) {
          let fnd = this.allData.find((o: any) => o.state_cd == resp)
          if (fnd) {
            this.groupData = this.backUpGroupedData.filter((f: any) => f.name == fnd[this.groupConfigKey.toString()]);
          }
          this.showToggle = true;
        }

      })
    );

    // Click from Coropleth Map Back Button
    this.reqSubcription.push(
      this.dataService.backToDefault.pipe(debounceTime(300)).subscribe((resp: any) => {
        // debugger;
        if (resp) {
          this.groupData = [...this.backUpGroupedData];
          this.showToggle = false;
          this.toggleSelection = 'state';
        }
      })
    );

    // Click from Coropleth Map Location Level
    this.reqSubcription.push(
      this.dataService.showLocationLevelData.pipe(debounceTime(300)).subscribe((resp: any) => {
        // debugger;
        if (resp) {

          let fnd: any = this.allData.find((o: any) => o.state_cd == resp.state);
          if (fnd) {
            const groupData = this.allData.filter((f: any) => f[this.groupConfigKey.toString()] == fnd[this.groupConfigKey.toString()] && f.zip_code == resp.postcode);
            this.mergeObjectsInUnique(groupData);
          }
          this.showToggle = true;
          this.toggleSelection = 'location';
        }
      })
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });
  }

  // Account Level
  routerGroupHandler(item: any) {
    this.setNavigationData(item.navObj);
    this.routerHandlerService.storingPayloadAndFilterForGrid(item.navObj, item.allData[0], this.cardName);
    let spl = item.name.split(',');
    let query: any = Object.assign({}, this.decryptedParams, {'tab_id': 0}, {'acc_nm': spl.pop()});
    let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'pwt'}/${spl.pop()}`, queryMaped);
  }

  // State Level 
  routerHandler(item: any, value: any) {
    this.setNavigationData(value.obj);
    this.routerHandlerService.storingPayloadAndFilterForGrid(value.obj, item.allData[0], this.cardName);
    let query: any = Object.assign({}, this.decryptedParams, {'tab_id': 0});
    let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'pwt'}/${value.name}`, queryMaped);
  }

  // Prescriber Card Level
  routerHandlerPrescriberCard(item: any, value: any) {
    this.setNavigationData(value.obj);
    let fndData = item.allData.find((o: any) => o[value.obj.value_name] == value.value.toString())

    if (fndData) {
      // Prescriber Name Header
      if (value.obj.pageName?.toString().replace(/\s+/g, '').toLowerCase() == 'prescribercard') {
        // Prescriber Name
        let prName = 'prescriber_nm'
        if (fndData[prName]) {
          if (fndData[prName].includes(',')) {
            sessionStorage.setItem("navbar_prName", fndData[prName].replaceAll(',', ''));
          } else {
            sessionStorage.setItem("navbar_prName", fndData[prName]);
          }
        }
      }

      this.routerHandlerService.storingPayloadAndFilterForGrid(value.obj, fndData, this.cardName);
      let query: any = Object.assign({}, this.decryptedParams, { cardId: fndData['prescriber_id']}, {'tab_id': 0});
      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${value.obj.pageName}`, queryMaped);
    }
  }

  // Patient Card
  routerHandlerPatientCard(item: any, status: any) {

    // there is no filter patient card
    this.newFilterService.setPatientStatusBaseQuery(this.filterServiceOld.baseQuery);

    // Set Navigation
    this.setNavigationData(status.navObj);
    let fndData = item.allData.find((o: any) => o[status.navObj.value_name] == status.id);

    if (fndData) {
      this.routerHandlerService.storingPayloadAndFilterForGrid(status.navObj, fndData, this.cardName);

      let query: any = Object.assign({}, this.decryptedParams, { cardId: status.id }, {'tab_id': 0});
      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${status.navObj.pageName}`, queryMaped);
    }
  }

  // Set Navigations
  setNavigationData(config: any) {
    let nav = {
      dbJSON: config?.dbJSON,
      s3JSON: config?.s3JSON,
      pageName: config?.pageName,
      isDashboardLevel: config?.isDashboardLevel
    }
    this.routerHandlerService.navDetailInfo(nav);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let cfg = this.item.config;
    console.log("cfg", cfg);

    // Group Configuration
    let grp = cfg['main_configuration']?.['controls'];

    this.groupConfigKey = [grp[0].value_name];
    this.groupConfigObj = grp[0];

    // Child Group Configuration
    this.groupLocConfigKey = [cfg['locationGroupConfig']]

    this.configToShowStateValues = cfg['nav_view_configuration']?.['controls'];
    this.configToShowLocationValues = cfg['nav_location_configuration']?.['controls'];
    this.configToShowStatusJourney = cfg['nav_status_journey_configuration']?.['controls']?.[0] || {};

    if (changes['allData']?.currentValue != changes['allData']?.previousValue) {
      this.allData = changes['allData'].currentValue || [];
      this.mergeObjectsInUnique(this.allData);
    }
  }

  reduceTheNumber(data: any, name: any) {
    return data.reduce((acc: any, val: any) => acc += Number(val[name]), 0);
  }

  reduceTheString(data: any, name: any) {
    // Extract unique names from the array of objects
    const uniqueNamesSet = new Set(data.map((obj: any) => obj[name]));
    // Convert Set to Array
    return Array.from(uniqueNamesSet);
  }

  groupObjectsBy(array: any[], keys: string[]): { [key: string]: any[] } {
    return array.reduce((result, currentValue) => {
      const groupKey = keys.map(key => currentValue[key]).join('-');
      (result[groupKey] = result[groupKey] || []).push(currentValue);
      return result;
    }, {});
  }

  // Data Mapping
  mergeObjectsInUnique(data: any[]) {
    this.groupData = [];

    const groupedStateObjects = this.groupObjectsBy(data, this.groupConfigKey);
    const groupedStateArray = Object.entries(groupedStateObjects).map(([key, value]) => ({ key: key, values: value }));

    if (groupedStateArray?.length) {

      // State Level Configuration
      for (let index = 0; index < groupedStateArray.length; index++) {
        const element: any = groupedStateArray[index];
        let modifyObj: any = {};

        modifyObj['name'] = element.key;
        modifyObj['allData'] = element.values;
        modifyObj['state_values'] = [];
        modifyObj['location_values'] = [];
        modifyObj['navObj'] = this.groupConfigObj;

        // State Display Value
        this.configToShowStateValues.map((m: any) => {
          let presName = this.reduceTheString(element.values, m.value_name)
          if (m.type.toLowerCase() == 'number') {
            modifyObj['state_values'].push({
              name: m.disp_name,
              value: this.reduceTheNumber(element.values, m.value_name),
              obj: m
            })
          } else {
            modifyObj['state_values'].push({
              name: m.disp_name,
              value: presName.length,
              obj: m
            })
          }
        });

        // Location Display Values;
        const groupedLocObjects = this.groupObjectsBy(element.values, this.groupLocConfigKey);
        const groupedLocArray = Object.entries(groupedLocObjects).map(([key, value]) => ({ key: key, values: value }));

        // Location and Status Configuration
        for (let index = 0; index < groupedLocArray.length; index++) {
          const locElement: any = groupedLocArray[index];
          let modifyLocObj: any = {};

          modifyLocObj['name'] = locElement.key;
          modifyLocObj['value'] = [];
          modifyLocObj['statusJourney'] = [];

          this.configToShowLocationValues.map((m: any) => {
            if (m.type.toLowerCase() == 'number') {
              modifyLocObj['value'].push({
                name: m.disp_name,
                value: this.reduceTheNumber(locElement.values, m.value_name),
                obj: m
              })
            } else {
              modifyLocObj['value'].push({
                name: m.disp_name,
                value: this.reduceTheString(locElement.values, m.value_name),
                obj: m
              })
            }
          })

          const groupedByPatient = _.groupBy(this.statusArray, 'du_patient_id');

          locElement.values.forEach((o: any) => {
            let statusJourneys: any = {
              id: o.patient_id,
              name: o.referral_source,
              color: o.referral_source == 'SP' ? '#FFCD4A' : '#844AFF',
              status: groupedByPatient[o.patient_id],
              navObj: this.configToShowStatusJourney,
            }
            modifyLocObj['statusJourney'].push(statusJourneys);
          });


          modifyObj['location_values'].push(modifyLocObj);
        }

        this.groupData.push(modifyObj);
      };
    }

    if (this.backUpGroupedData.length == 0) this.backUpGroupedData = [...this.groupData];

    console.log('this.groupData', this.groupData);

  }

  // Kill Component
  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }
}
