
<div class="widget-container" #paymentGridDiv >
  <app-hierarchical-grid-chart
    [heading]="heading"
    [headerConfig]="this.item.config?.widget_header_config"
    [config]="config"
    [item]="item"
    [pageKey]="pageKey"
    [loader]="loader"
    [selectedGroup]="selectedGroup"
    [groupDisplayType]="groupDisplayType"
    [dividerVisibility]="dividerVisibility"
    [contextMenuCondition]="contextMenuCondition"
    [rowData]="rowData"
    [isDetailGrid]="isDetailGrid"
    [isPivotGrid]="isPivotGrid"
    [columnDefs]="columnDefs"
    [inputConfig]="inputConfig"
    [childColumnDefs]="childColumnDefs">
  </app-hierarchical-grid-chart>
</div>