import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { Legend } from '@dataunveil/ducharts';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-stacked-combo-bar-chart',
  templateUrl: './stacked-combo-bar-chart.component.html',
  styleUrls: ['./stacked-combo-bar-chart.component.scss']
})
export class stackedComboBarChartComponent implements OnInit, OnDestroy {
  // @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  // @ViewChild('WordCloudChart') WordCloudChart!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  reqSubcription: Subscription[] = []
  isActive = false;
  iconList: any[] = []
  timeCycleData: any = []
  dataTurn: any
  selectedType = 'D'
  mytooltipData: any={}
  isTooltip: boolean = false
  noData : boolean = false
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  WordCloudChartData: any;
  initiateChart: Boolean = false
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event:any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen(false);
    }
  }
  areaLineData: any = {};
  divId: any = 'areaLineChartDiv';
  props: any = {};
  constructor(private currency: CurrencyPipe, private filterService: FilterService) {
    // this.filterService.changeTimeCycle.subscribe((res: any) => {
    //   this.changeTimeCycle(res)
    // })
  }

  height: any = 0
  showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    let Data:any = {};
    myData[1].forEach((item:any) => {
    Data[item[0]] = item[1];
});
    let data:any = [
      {
       name:"Failures",
       color:"#FF7715",
       key:"F",
       value:Data['F']
   },
      {
       name:"Errors",
       color:"#FFD951",
       key:"E",
       value:Data['E']
   },
      {
       name:"AVG Resolution time-Failures (IN DAYS)",
       color:"#0BBF1D",
       key:"AVG RT-F",
       value:Data['AVG RT-F']
   },
      {
       name:"AVG Resolution time-Errors (IN DAYS)",
       color:"#C90F0F",
       key:"AVG RT-E",
       value:Data['AVG RT-E']
   },

 ]
 data=  data.sort((a: any,b: any) =>  a['key'] == myData[2]['selected'] ? -1 : b['key'] == myData[2]['selected'] ? 1 : 0);
    this.mytooltipData['date']= myData[0]
//   myData['data']=data
    this.mytooltipData['data']=data
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (this.isFullscreen == true) {
      if (this.height < 200) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn < 350) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 350) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true

    } 
    else {
      if (this.height < 200) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn <350) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn >350) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  hideTooltip(myType: any): void {
    this.isTooltip = false
    // d3.select("#d3AreaLineTooltip")
    // .style('visibility', 'hidden');
  }
  isLoading = false;
  async wait(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() {
    this.isLoading = true;

  }
 
  timecycleChange(selectedType: any) {

    this.start()
    this.props = {
      lineDataType: "group",
      legendLabel: { "F": "Failures", "E": "Errors", "AVG RT-F": "AVG RT-Failures", "AVG RT-E": "AVG RT-Errors" },
      colors: ["#C90F0F","#0BBF1D","#8C23E6", "#E223E6", "#FFCD4A", "#645DD7", "gold", "black", "purple", "orange"],
      barcolor:['#FFD953','#FF842B',"#8C23E6", "#E223E6", "#FFCD4A", "#645DD7"],
      chartHeight: 500,
      lineGroupVar: "error_type",//not needed if lineDataType === "single"
      barGroupVar: "error_type",
      lineVar: "no_of_days",
      barVar: "defect_count",
      dateVar: "period",
      showBrush: true,
      d3AxisFormatting: false,
      period: "D",
      yAxisLabelLeft:"Defects",
      yAxisLabelRight:"# of Days",
      yAxisFormat: "-2~s",
      partialPeriod: false,
      barFill: { 'E': '#FFD953', 'F': '#FF842B' }, //'W': "deeppink","FP" : "skyblue",
      barLabels: { 'E': 'Errors', 'F': 'Failures' }//'W': "Warnings","FP" : "Failures But Processed"

    }
    this.selectedType = selectedType
    this.props.period = this.selectedType
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 80;
    }else{
      this.props.chartHeight = 500
    }
    // API Payloads
    let obj:any = {"api_key": "100171", "time_cycle": this.selectedType, "report_typ": this.filterService.report_type}

    this.reqSubcription.push(this.filterService.selfTimeCycleExecuteQuery(obj).subscribe((res: any) => {
      this.data = res == null ? [] : res
      let modifiedData = this.data.map((e:any) => {
        return {
            ...e,
            error_type: e.error_type === 'E' ? 'AVG RT-E' : 'AVG RT-F'
        };
    });
      this.areaLineData["lineData"] = modifiedData
      this.areaLineData["barData"] = this.data 
     setTimeout(() => {
      
       this.plotChart()
     }, 200);
    }, (error: any) => { 
      this.noData= true
      this.areaLineData = { lineData: [], barData: [] };
      this.data = []
      console.error( "areaLineChart Chart", error)
    }))
  }
  closeFullscreen(fullscreen: any): void {
    this.isFullscreen = fullscreen;
    // if (document.fullscreenElement) {
    //   document.exitFullscreen();
    // }
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 80;
    } else {
      this.props.chartHeight = 500
    }
    this.isActive = fullscreen;
    setTimeout(() => {
      this.plotChart()
    }, 120);
  }

    // @HostListener("window:resize", ["$event"])
    // onResize(event: Event) {
    //   setTimeout(() => {
    //     if(!this.isFullscreen)
    //     this.plotChart()
    //   }, 100);
    // }

  ngOnInit(): void {
    this.start()
    this.selectedType = this.item.config?.default_timeCycle ? this.item.config.default_timeCycle : this.selectedType
    this.timeCycleData = this.item.config?.time_cycle ? this.item.config.time_cycle : this.timeCycleData
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();

  }
  // data change Event
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getAreaLineComboData()
    }
  }
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const width = mySection.clientWidth;
    const mySvg =  d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    // soon to be redundant
    mySvg.append('text').attr('id', 'noDataMessage' + myClass);

    const defs = mySvg.append('defs');
    // see bar for explanation - clip rect, drop shadow
    defs.append('clipPath').attr('id', 'alcBrushClip' + myClass)
      .append('rect').attr('id', 'alcBrushClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    // bar, line, legend, brush group (bar and line)
    mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg.append('g').attr('class', 'brushBarGroup' + myClass);
    mySvg.append('g').attr('class', 'brushLineGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass)
    // axes
    mySvg.append('text').attr('id', 'yAxisLeftLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisRightLabel' + myClass);
    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    // partial period line
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('g').attr('id', 'yAxisRight' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('class', 'barGroup' + myClass);
    mySvg.append('g').attr('class', 'lineGroup' + myClass);
    // handle lines
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    // legend stuff
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    // mouseover stuff
    mySvg.append('line').attr('class', 'barMouseoverLine' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('rect').attr('class', 'barMouseoverRect' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('text').attr('class', 'barMouseoverText' + myClass + ' barMouseoverItem' + myClass);
    this.initiateChart = true
  }

  plotChart(): void {
    const myChart: any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = myChart.props.chartHeight;
    let margins: any = {left: 90, right: 90, top: 100, bottom: 20, brush: 82, sides: 0, mid: 40, axis: 40};
    if (myChart.props.showBrush === false) {
      // different if brush
      margins.brush = 0;
      margins.mid = 0;
    }
    mySvg.attr('height', height);
    // tick format as always (see bar chart)
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    const timePeriod = this.props.period;
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);

    const chartData: any = myChart.areaLineData;
    // convert and sort date as always
    chartData.lineData.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.lineData.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    chartData.barData.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.barData.sort((a: any, b: any) => d3.ascending(a.date, b.date));

    // gather the sources from lineData
    let sourceSet: any = new Set();
    chartData.lineData.forEach((d: any) => sourceSet.add(d[myChart.props.lineGroupVar]));
    sourceSet = Array.from(sourceSet);
    //if lineDataType is single, there is only one line so the variable is provided
    if(myChart.props.lineDataType === "single"){
      sourceSet = [myChart.props.lineVar];
    }
    // assign the colors
    const lineColors: any = {};
    sourceSet.forEach((d: any, i: any) => lineColors[d] = myChart.props.colors[i])
    // lineColors[myChart.props.yAxisLabelRight] = '#1468E1';
    // sourceSet.forEach((d: any, i: any) => lineColors[d] = myChart.props.colors[i])

    let lineDataset: any = [], tooltipData: any = {}, dateSet: any = new Set();

    let yMax: any = 0;
    if(myChart.props.lineDataType === "group"){
      // group behaves very like area line
      // empty array for each source
      sourceSet.forEach(() => {
        lineDataset.push([]);
      })
      // group by date
      let lineGroup: any = d3.group(chartData.lineData, (d: any) => d.date);
      lineGroup = Array.from(lineGroup);
      lineGroup.forEach((d: any) => {
        // for each date
        // add to set
        dateSet.add(d[0]);
        // set tooltip data
        tooltipData[d[0]] = [d[0], []]
        sourceSet.forEach((s: any, i: any) => {
          // for each source
          // calculate total
          const myTotal = d3.sum(d[1].filter((f: any) => f[myChart.props.lineGroupVar] === s), (t: any) => +t[myChart.props.lineVar]);
          // add a value to lineDataset
          lineDataset[i].push({
            date: d[0],
            value: myTotal === undefined ? 0 : myTotal,
            source: s
          })
        })
      })
      lineDataset.forEach((d: any) => {
        // now loop through line dataset and keep resetting max for all
        const myMax: any = d3.max(d, (m: any) => m.value);
        yMax = Math.max(yMax, myMax);
        // sort
        d = d.sort((a: any, b: any) => d3.ascending(a.date, b.date));
        // map out whether there is movement up/down/none (for tooltip)
        d.map((m: any, i: any) => m.movement = i === 0 ? null : m.value > d[i-1].value ? "up"
          : (m.value === d[i - 1].value ? "none" : "down"));
        d.forEach((x: any) => {
          // add source, value, movement, colour to tooltip data
          tooltipData[x.date][1].push([x.source,x.value,x.movement,lineColors[x.source]]);
        })
      })
    } else {
      // slightly different for single line mode (not much)
      let myDataset: any = [];
      // group by date
      let lineGroup: any = d3.group(chartData.lineData, (d: any) => d.date);
      lineGroup = Array.from(lineGroup);
      lineGroup.forEach((d: any, i: any) => {
        // add to date set
        dateSet.add(d[0]);
        // get total by line var
        const myTotal = d3.sum(d[1], (s: any) => +s[myChart.props.lineVar]);
        d.value = myTotal;
        // calculate movement
        const myMovement = i === 0 ? null : d.value > myDataset[i-1].value ? "up"
          : (d.value === myDataset[i - 1].value ? "none" : "down");
        // add to dataset
        myDataset.push({
          date: d[0],
          value: myTotal,
          source: myChart.props.lineVar,
          movement: myMovement
        })

        // add to tooltip data
        tooltipData[d[0]] =[d[0],[myChart.props.lineVar,myTotal,myMovement,lineColors[myChart.props.lineVar]]];
      })
      // sort dates and add to lineData
      myDataset = myDataset.sort((a: any, b: any) => d3.ascending(a.date, b.date));
      lineDataset.push(myDataset);
      const lineMax: any = d3.max(myDataset, (m: any) => m.value);
      // keep resetting max till all data done
      yMax = Math.max(yMax, lineMax);
    }
    //fix when no data (soon to be redundant)
    if(lineDataset.length > 0 && lineDataset[0].length === 0){lineDataset = []}
    // now bar data
    let barDataset: any = [];
    // group by date
    let barGroup: any = d3.group(chartData.barData, (d: any) => d.date);
    barGroup = Array.from(barGroup);
    barGroup.forEach((d: any) => {
      // for each date, calculate total and build dataset
      const myTotal = d3.sum(d[1], (s: any) => +s[myChart.props.barVar]);
      barDataset.push({
        date: d[0],
        value: myTotal,
        source: myChart.props.barVar
      })
    })



// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//new logic for the stackbar
let barData: any = [];

    let barVars: any = new Set();
    let stackVars: any = new Set()
    myChart.areaLineData.barData.forEach((d: any) => {
      barVars.add(d['period']);
      stackVars.add(d[myChart.props.barGroupVar]);
    })

    Array.from(stackVars).forEach((d: any, i: any) => lineColors[d] = myChart.props.barcolor[i])
    let stackData: any = [];
    Array.from(barVars).forEach((d: any) => {
      const myData: any = {name: new Date(d)}
      Array.from(stackVars).forEach((v: any) => {
        const myValue = d3.sum(myChart.areaLineData.barData, (s: any) =>
          s['period'] === d && s[myChart.props.barGroupVar] === v
            ? s[myChart.props.barVar] : 0);
        myData[v] = myValue;
      })
      stackData.push(myData);
    })
    stackData = d3.stack()
      .keys(Array.from(stackVars))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)
      (stackData);

      const sbarMax:any = d3.max(stackData, (d: any) => d3.max(d, (m: any) => m[1]));
    // const yScale: any = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    // const yScaleRight: any = d3.scaleLinear().domain([0, barMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const yScale: any  = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const yScaleRight :any= d3.scaleLinear().domain([0, sbarMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const extraYs: any = {};
    
    stackData.forEach((d: any, i: any) => {
      const myKey = d.key;
      const isLast = (i === (stackData.length - 1));
      d.map((m: any) => m.value = (m[1] - m[0]))
      d.map((m: any) => m.height = yScaleRight(m.value));
      d.forEach((s: any, index: any) => {
        let myExtraY = 0;
        if (s.height < 2 && s.value > 0) {
          myExtraY = (2 - s.height);
          s.height = 2;
          extraYs[index] = (extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY)
        }
        let myFill = myChart.props.barFill[myKey];
        if(myFill === undefined){
          myFill = myChart.props.stackColors[i];
          myChart.props.barFill[myKey] = myFill;
          myChart.props.barLabels[myKey] = myKey;
        }
        barData.push({
          date: s.data.name,
          start: s[0],
          value: s.value,
          height: s.height,
          fill: myFill,
          extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
          totalValue: s[1],
          borderRadius: isLast ? 2 : 0,
          groupIndex: index,
          stackIndex: i,
          errorType: myKey
        })
        tooltipData[s.data.name][1].push([ myKey,s.value,'none', myFill]);
      //   barData = barDataset.sort((a: any, b: any) => d3.ascending(a.date, b.date));
      //   barData.map((m: any, i: any) => m.movement = i === 0 ? null : m.value > barDataset[i-1].value ? "up"
      // : (m.value === barDataset[i - 1].value ? "none" : "down"));
      })
    })
  

  const emptyLastBars = barData.filter((f: any) => f.borderRadius > 0 && f.value === 0);
  emptyLastBars.forEach((d: any) => {
    d.borderRadius = 0;
    let filledBars = barData.filter((f: any) => f.groupIndex === d.groupIndex && f.value > 0);
    filledBars = filledBars.sort((a: any, b: any) => d3.descending(a.stackIndex, b.stackIndex));
    if (filledBars.length > 0) {
      filledBars[0].borderRadius = 2;
    }
  })










/////////////////////////////////////////////////////////////////////////


    // sort and add movement
    barDataset = barDataset.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    barDataset.map((m: any, i: any) => m.movement = i === 0 ? null : m.value > barDataset[i-1].value ? "up"
      : (m.value === barDataset[i - 1].value ? "none" : "down"));
    // get max
    const barMax: any = d3.max(barDataset, (m: any) => m.value);
    // create dateSet into sorted array of dates
    dateSet = Array.from(dateSet).map((m: any) => m = new Date(m));
    dateSet = dateSet.sort((a: any, b: any) => d3.ascending(a, b));
    // tickValues depending on axisFormatting - see bar chart for full explanation, same for all
    const tickValues = myChart.props.d3AxisFormatting === true ? null : dateSet;
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    // using a linear scale so setting bar with  a min
    let barWidth = Math.min(margins.axis - 5,(width - margins.left - margins.right)/((barDataset.length-1) * 2))
    // x scales (all and scalle as normal)
    // brush a bit different so the bars fit
    const xExtent: any = d3.extent(dateSet,(d: any) => d);
    const xScaleBrush: any = d3.scaleTime().range([0, width - margins.left - margins.right- barWidth]).domain(xExtent);
    const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right ]).domain(xExtent);
    const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    // y scales - since we have right, need 4
    // const yScale: any = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    // const yScaleRight: any = d3.scaleLinear().domain([0, barMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const yScaleBrush: any = d3.scaleLinear().domain([0, yMax]).range([margins.brush - margins.mid - 5,0]);
    const yScaleBrushRight: any = d3.scaleLinear().domain([0, sbarMax]).range([margins.brush - margins.mid - 5, 0]);
    // calculating brushStartX - same in all but slightly different variables
    // see bar chart for full explanation
    let startingVisibleRows: any = parseInt(String((width - margins.left - margins.right) / 25), 10);
    const maxDataLength: any = barDataset.length;
    startingVisibleRows = startingVisibleRows > maxDataLength ? maxDataLength : startingVisibleRows;
    const brushStartX = barDataset.length === 0 ? 0 : (1-(startingVisibleRows/maxDataLength)) * (width - margins.left - margins.right);
    // define lines
    const line: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d.date))
      .y((d: any) =>  yScale(d.value));

    const lineBrush: any =  d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScaleBrush(d.date))
      .y((d: any) =>  yScaleBrush(d.value))
    // define brush
    const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right, margins.brush - margins.mid]])
      .on('start brush end', brushed);
    // mouseover line
    mySvg.select('.barMouseoverLine' + myClass)
      .attr("visibility", "hidden")
      .attr("x2", width - margins.right)
      // .attr('stroke', '#1468E1')
      .attr('stroke-dasharray', '2,2');
    // additional mouseover rect and text on right for this chart only
    mySvg.select('.barMouseoverRect' + myClass)
      .attr("visibility", "hidden")
      .attr("x", width - margins.right + 2)
      .attr('width', 40)
      .attr('height', 12)
      .attr('rx', 4)
      .attr('ry', 4)
      // .attr('fill', '#1468E1');

    mySvg.select('.barMouseoverText' + myClass)
      .attr("visibility", "hidden")
      .attr("x", width - margins.right + 2 + 20)
      .attr('text-anchor', "middle")
      .style('font-family', 'Poppins')
      .style('font-weight', 500)
      .style('font-size', 10)
      .attr('dy', 4)
      .attr('fill', 'white');
    // soon to be redundant
    mySvg.select('#noDataMessage' + myClass)
      .attr("x", margins.left + (width - margins.left - margins.right)/2)
      .attr("y", -8 + margins.top + (height - margins.top + margins.mid - margins.brush - margins.bottom)/2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(lineDataset.length === 0 && barDataset.length === 0 ? "There is no data for this time period" : "");
    // partial period
    mySvg.select('.partialPeriodLine' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("x1", width - margins.right)
      .attr("x2", width - margins.right)
      .attr("y1", 75)
      .attr("y2", height - margins.bottom - margins.brush )
      .attr('stroke', '#1363DF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray','2,2');

    mySvg.select('.partialPeriodLineLabel' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("transform", "translate(" + (width - margins.right) + "," +
        (40) + ")")
      .attr("text-anchor", "middle")
      .attr("dy", 0)
      .attr('font-size', '8px')
      .attr('font-family', 'Poppins')
      .attr('font-weight', '400')
      .attr('fill', '#1363DF')
      .text('Partial period')
      .call(wrap, 45);
    // axis labels
    mySvg.select('#yAxisLeftLabel' + myClass)
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (margins.left - 40) + ',' + (margins.top-42) + ')')
      .attr('text-anchor', 'end')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .attr('dy', '0')
      .text(myChart.props.yAxisLabelLeft)
      .call(wrap,margins.left - 5);

    mySvg.select('#yAxisRightLabel' + myClass)
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (width - (margins.right - 40)) + ',' + (margins.top - 42) + ')')
      .attr('text-anchor', 'start')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .attr('dy', '0')
      .text(myChart.props.yAxisLabelRight)
      .call(wrap,40);
    // clip rect
    mySvg.select('#alcBrushClipRect' + myClass)
      .style('width', width -  margins.right - margins.left + barWidth + 3)
      .style('height', height - margins.brush - 15 - margins.mid)
      .attr('transform', 'translate('+ (margins.left - (barWidth/2)) + ',' + (margins.top -10) + ')');
    // same as others (see bar chart) - different settings
    // actions depending on whether we want a brush or not
    if(myChart.props.showBrush === true){
      mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + (margins.left) + ',' + (height - margins.brush - 10 + margins.mid) + ')')
        .call(brush)
        .call(brush.move, [brushStartX, width - margins.left - margins.right]);

      mySvg.selectAll('.handleLines' + myClass)
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
        .attr('y1', height - margins.brush + margins.mid - 10 + ((margins.brush - margins.mid ) - 12) / 2)
        .attr('y2', height - margins.brush  + margins.mid - 10 + 12 + ((margins.brush - margins.mid ) - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + (margins.left) + ',0)');

      mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');
    } else {

      mySvg.select('#brushGroup' + myClass).html("")

      mySvg.selectAll('.handleLines' + myClass)
        .style('stroke-width', '0')

      mySvg.select('#brushLinePath' + myClass)
        .attr('d', "")
    }
    // y axes
    mySvg.select('#yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScaleRight).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + (margins.left - margins.axis) + ',' + margins.top + ')');

    mySvg.selectAll('#yAxis' + myClass + ' line')
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('x1', '0')
      .attr('x2', width - margins.right - margins.left)
      .style('stroke', '#F0F3F6')
      // .style('stroke-width', 1);

    mySvg.select('#yAxisRight' + myClass)
      // @ts-ignore
      .call(d3.axisRight(yScale).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + (width - margins.right + margins.axis) + ',' + margins.top + ')');

    mySvg.selectAll('#yAxisRight' + myClass + ' line')
      .style('display', 'none');

    mySvg.selectAll('#yAxisRight' + myClass + ' text')
      .text((d: any) =>  d3.format(myChart.props.yAxisFormat)(d));
      // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));

    mySvg.selectAll('#yAxis' + myClass + ' text')
      .text((d: any) =>  d3.format(myChart.props.yAxisFormat)(d));
      // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));

    // brush chart
    const brushBarChartGroup = mySvg
      .select('.brushBarGroup' + myClass)
      .selectAll('.brushBGroup' + myClass)
     .data(myChart.props.showBrush === true ? barData : [])
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'brushBGroup' + myClass);
        enter.append('path').attr('class', 'barRect');
        return enter;
      });

    // bar is a path not a rect so that the end can be rounded
    brushBarChartGroup.select( '.barRect')
      .attr('d', (d: any) => 'M0,' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) + 'L0,'+ d.borderRadius +' Q0,0 '+ d.borderRadius +',0 L' + (barWidth-d.borderRadius)
        + ',0 Q' + barWidth + ',0 ' + barWidth + ','+d.borderRadius+' L' +  barWidth + ',' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) +  'Z')
        .attr('fill', (d: any) => d.fill)
      .attr('transform', (d: any) => 'translate(' + (margins.left + xScaleBrush(d.date))
        + ',' + (height - margins.brush - margins.bottom + 15 + margins.mid + yScaleBrushRight(d.totalValue)) + ')')
    // brush line
    const brushChartGroup = mySvg
      .select('.brushLineGroup' + myClass)
      .selectAll('.brushCGroup' + myClass)
      .data(myChart.props.showBrush === true ? lineDataset : [])
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'brushCGroup' + myClass);
        enter.append('path').attr('class', 'brushLine');
        return enter;
      });

    brushChartGroup.select('.brushLine')
      .attr('d', lineBrush)
      .attr('fill', 'none')
      .attr('stroke-width', '2')
      .attr('stroke', (d: any) => lineColors[d[0].source])
      .attr('transform', 'translate(' + (margins.left + (barWidth/2)) + ',' + (height - margins.brush - 5 + margins.mid) + ')');

    // legend functionality with buttons
    // same on combo/line/area line
    // see line chart for comments/explanations
    let currentLegendSet: any = Array.from(stackVars)// [myChart.props.yAxisLabelRight];
    currentLegendSet = currentLegendSet.concat(sourceSet);
    let currentLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
   let legendIndexLeft = 0;
    let legendIndexRight = currentLegendItems.length;
    const legendWidth = d3.sum(currentLegendItems, (s: any) => measureWidth(s.toUpperCase(), 12)  + 40)
    mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
    const legendExtraWidth = 120;
    if (legendWidth >= (width - legendExtraWidth)){
      currentLegendItems = [];
      let currentWidth = 0;
      currentLegendSet.forEach((d: any, i: any) => {
        currentWidth += (measureWidth(d.toUpperCase(), 12) + 40);
        if (currentWidth < (width - legendExtraWidth)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }
    mySvg.selectAll('.legendArrowLeftItem' + myClass)
      .attr('visibility', currentLegendItems.length === currentLegendSet.length || legendIndexLeft === 0? "hidden" : "visible");
    mySvg.selectAll('.legendArrowRightItem' + myClass)
      .attr('visibility', currentLegendItems.length === currentLegendSet.length ? "hidden" : "visible");

    // more legend - see line
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 21)
      .attr('y', 35 + 5.5)
      .text('<');
    // more legend - see line
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft += 1;
        legendIndexRight += 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (currentLegendSet.length - 1)){
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 21)
      .attr('y', 35 + 5.5)
      .text('>');

    drawLegend(lineDataset.length === 0 ? [] : currentLegendItems)
    drawChart();

    function drawChart(){
      // set selection visibility
      mySvg.selectAll(".selection")
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible');
      // set data for Angular team
      myChart.currentVisibleData = [barDataset, lineDataset, xScale.domain()];
      // reset x axis styles
      mySvg.selectAll('#xAxis' + myClass + ' line')
        .attr('display', 'none');

      mySvg.selectAll('.axis' + myClass + ' path')
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
        .style('stroke', '#E8EAEE');

      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', 500)
        .style('font-size', 10)
        .attr('fill', '#737D88');

      mySvg.selectAll('#xAxis' + myClass + ' text')
        .style("text-anchor", "middle")
        .attr("dx", -5)
        .attr("dy", 5)
        .attr("transform", "");
      // labels - rotation - same for bar/line/combo/arealine - see bar for comments/explanation
      let ticksArray = tickValues === null ? xScale.ticks() : tickValues.filter((f: any) => f >= xScale.domain()[0] && f <= xScale.domain()[1]);
      ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
      const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
        ? width - margins.chartRight - margins.chartLeft : xScale(ticksArray[i+1]) - xScale(m) )
      const maxTextWidth = getMaxTextWidth();
      if(maxTextWidth > (xBandwidth - 5)){
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;
        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style("text-anchor", (myChart.props.d3AxisFormatting === true ? "middle" : "end" ))
          //@ts-ignore
          .attr("dy",  (myChart.props.d3AxisFormatting === true ? 5 : 1 ))
          .style("font-size", myFontSize)
          //@ts-ignore
          .attr("transform", "rotate(" + (myChart.props.d3AxisFormatting === true ? 0 : -45 ) + ")");
      }
      function getMaxTextWidth(){
        let maxTextWidth =  0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function(){
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width)
        })
        return maxTextWidth
      }
      // bar group
      const barGroup = mySvg.select('.barGroup' + myClass)
      .attr('clip-path', 'url(#alcBrushClip' + myClass + ')')
      .selectAll('.barRectGroup' + myClass)
      .data(barData)
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'barRectGroup' + myClass);
        enter.append('path').attr('class', 'barRect');
        return enter;
      });

    // const barWidth = xScale.bandwidth() / 4;

    // barGroup.select('.barRect')
    //   .attr('d', (d: any) => 'M0,' + d.height + 'L0,' + d.borderRadius
    //     + ' Q0,0 ' + d.borderRadius + ',0 L' + (barWidth - d.borderRadius)
    //     + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + d.borderRadius
    //     + ' L' + barWidth + ',' + d.height + 'Z')
    //   .attr('fill', (d: any) => d.fill)
    //   .attr('stroke-width', 0)
    //   .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.date) + (xScale.bandwidth() * 0.375))
    //     + ',' + (margins.top + (yScaleRight(d.totalValue) + d.extraY)) + ')')
      
      // const barGroup = mySvg
      //   .select('.barGroup' + myClass)
      //   .attr('clip-path', 'url(#alcBrushClip' + myClass + ')')
      //   .selectAll('.barRectGroup' + myClass)
      //   .data(barData)
      //   .join(function(group: any): any {
      //     const enter = group.append('g').attr('class', 'barRectGroup' + myClass);
      //     enter.append('path').attr('class', 'barRect');
      //     return enter;
      //   });
      barGroup.on('mousemove', function (event: any, d: any) {
        // make all bars opaque
        mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 0.6);
        // @ts-ignore - except this one
        d3.select(this).attr("opacity", 1);
        // make all lines opaque
        mySvg.selectAll('.lineGroup' + myClass).attr("opacity", 0.4);
        // get tooltip data and show tooltip
        const tooltipContents = tooltipData[String(d.date)];
        tooltipContents[2]={'selected' : d.errorType}
        const barTooltip = [d.date, [myChart.props.yAxisLabelRight, d.totalValue, d.movement, '#1468E1']]
        myChart.showTooltip('bar', tooltipContents, event.offsetX, event.offsetY, width, height);
        // bar is RIGHT axis, so make that prominent
        mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
        mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '3');
        // show mouseover line, rect and text
        // mySvg.select('.barMouseoverLine' + myClass)
        //   .attr("visibility", "visible")
        //   .attr("x1", margins.left + xScale(d.date))
        //   .attr("y1", margins.top + yScaleRight(d.totalValue))
        //   .attr("y2", margins.top + yScaleRight(d.totalValue));

        // mySvg.select('.barMouseoverRect' + myClass)
        //   .attr("visibility", "visible")
        //   .attr("y", margins.top + yScaleRight(d.totalValue) - 6);

        // mySvg.select('.barMouseoverText' + myClass)
        //   .attr("visibility", "visible")
        //   .attr("y", margins.top + yScaleRight(d.totalValue))
        //   .text(d3.format("-2~")(d.totalValue))
      })
        .on('mouseout', () => {
          // back to original settings
          // mySvg.selectAll('.barMouseoverItem' + myClass).attr("visibility", "hidden");
          mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
          mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '1');
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 1);
          mySvg.selectAll('.lineGroup' + myClass).attr("opacity", 1);
          myChart.hideTooltip('bar');
        });

      // bars are paths not rects
      barGroup.select( '.barRect')
        .attr('d', (d: any) => {
          let barHeight = yScaleRight(0) - yScaleRight(d.value);
          let curveHeight = barWidth/2;
          barHeight = barHeight < 3 && barHeight !== 0 ? 3 : barHeight;
          curveHeight = curveHeight > barHeight ? barHeight : curveHeight;
          return 'M0,' + barHeight + 'L0,' + d.borderRadius + ' Q0,0 ' + (d.borderRadius) + ',0 L' + (barWidth - d.borderRadius)
          + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + d.borderRadius + ' L' +  barWidth + ',' + barHeight +  'Z'
        })
        .attr('fill', (d: any) => d.fill)
        .attr('transform', (d: any) => 
          'translate(' + (margins.left + xScale(d.date) - (barWidth / 2)) 
          + ',' + (margins.top + yScaleRight(d.totalValue) - ((yScaleRight(0) - yScaleRight(d.value)) < 3 && (yScaleRight(0) - yScaleRight(d.value)) !== 0 ? 3 : 0 )) + ')');

      // now for the lines
      const lineGroup = mySvg
        .select('.lineGroup' + myClass)
        .attr('clip-path', 'url(#alcBrushClip' + myClass + ')')
        .selectAll('.linePathGroup' + myClass)
        .data(lineDataset)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'linePathGroup' + myClass);
          enter.append('path').attr('class', 'linePath');
          enter.append('g').attr('class', 'lineDotGroup');
          return enter;
        });

      lineGroup
        .attr('id', (d: any, i: any) => "line" + i)
        .on("mousemove", function (event: any, d: any) {
          // same mousemove policy on all lines
          // get index of closest date (see area line for full explanation)
          const positionDate = xScale.invert(event.offsetX - margins.left);
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(dateSet[closest - 1], positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(dateSet[closest], positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            closest = dateSet.length - 1;
          }
          // @ts-ignore
          const myId = this.id;
          //show line dots, background dots, paths
          mySvg.selectAll('.lineDot').attr("r", 3);
          mySvg.selectAll('.backgroundDot').attr("r", 3);
          mySvg.selectAll('.linePath').attr("stroke-width", 3);
          // make bars opaque
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 0.2);
          // lines are LEFT axis so make this prominent
          mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '3');
          // make this path thicker
          mySvg.select("path#" + myId).attr("stroke-width", 6);
          // and these dots bigger
          mySvg.selectAll("circle#dot" + closest).attr("r", 4.5)
          // show tooltip
          const mySource = sourceSet[myId.split("line")[1]];
          const tooltipContents = tooltipData[String(dateSet[closest])];
          if(myChart.props.lineDataType === "group"){
            tooltipContents[1] = tooltipContents[1].sort((a: any,b: any) =>  a[0] == mySource ? -1 : b[0] == mySource ? 1 : 0);
          }
          let currentData = tooltipContents[1].find((a: any) =>  a[0] == mySource )
         
          // show mouseover line, rect and text
          mySvg.select('.barMouseoverLine' + myClass)
            .attr("visibility", "visible")
            .attr('stroke',currentData[3])// '#1468E1')
            .attr("x1", margins.left + xScale(tooltipContents[0]))
            .attr("y1", margins.top + yScale(currentData[1]))
            .attr("y2", margins.top + yScale(currentData[1]));
  
          mySvg.select('.barMouseoverRect' + myClass)
            .attr("visibility", "visible")
            .attr('fill', currentData[3])//'#1468E1')
            .attr("y", margins.top + yScale(currentData[1]) - 6);
  
          mySvg.select('.barMouseoverText' + myClass)
            .attr("visibility", "visible")
            .attr("y", margins.top + yScale(currentData[1]))
            .text(d3.format("-2~")(currentData[1]))
            tooltipContents[2]={'selected' :currentData[0]}
          myChart.showTooltip('line', tooltipContents,event.offsetX, event.offsetY, width, height);
        })
        .on('mouseout', () => {
          // back to original settings
          mySvg.selectAll('.barMouseoverItem' + myClass).attr("visibility", "hidden");
          mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
          mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '1');
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 1);
          mySvg.selectAll('.lineDot').attr("r", 3);
          mySvg.selectAll('.backgroundDot').attr("r", 3);
          mySvg.selectAll('.linePath').attr("stroke-width", 3);
          mySvg.select('#mouseoverLine' + myClass).attr('visibility', 'hidden');
          myChart.hideTooltip('line');
        });
      // line path
      lineGroup.select('.linePath')
        .attr('id', (d: any, i: any) => "line" + i)
        .attr('d', line)
        .attr('stroke', (d: any) => lineColors[d[0].source])
        .attr('stroke-width', 3)
        .attr('fill','none')
        .attr('transform', 'translate(' + (margins.left) + ',' + margins.top + ')');

      lineGroup.select('.lineDotGroup')
        .attr('transform', 'translate(' + (margins.left) + ',' + margins.top + ')');

      // dot group
      const lineDotGroup = lineGroup.select('.lineDotGroup')
        .selectAll('.lineDotsGroup' + myClass)
        .data((d: any) => d)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'lineDotsGroup' + myClass);
          enter.append('circle').attr('class', 'backgroundDot');
          enter.append('circle').attr('class', 'lineDot');
          return enter;
        });

      // background dot so that line not visible when opacity set.
      lineDotGroup.select('.backgroundDot')
        .attr('id', (d: any, i: any) => "dot" + i)
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScale(d.value))
        .attr('stroke-width', 0)
        .attr('r',3)
        .attr('fill','white')

      lineDotGroup.select('.lineDot')
        .attr('id', (d: any, i: any) => "dot" + i)
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScale(d.value))
        .attr('stroke', (d: any) => lineColors[d.source])
        .attr('stroke-width', 2)
        .attr('fill-opacity', 0.3)
        .attr('r',3)
        .attr('fill',(d: any) => lineColors[d.source]);
    }

    function drawLegend(currentLegendItems: any): void {
      // draw legend, similar in all cases, see area line for full explanation
      const legendGroup = mySvg
        .select('#legendGroup' + myClass)
        .selectAll('.legendGroup' + myClass)
        .data(currentLegendItems)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d.replace(/ /g, '') + myClass)
        .attr('cy', 35)
        .attr('fill', (d: any) => lineColors[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + d.replace(/ /g, '') + myClass)
        .attr('y', 35 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) => myChart.props.legendLabel[d.toUpperCase().replace(/_/g, ' ')]);

      let legendX = 30;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject).attr('x', legendX);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        legendX += (40 + measureWidth(myChart.props.legendLabel[d.toUpperCase().replace(/_/g, ' ')], 12));
      });

      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }

    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        myChart.hideTooltip('line');
        // same in all sets
        // reset xDomain
        const xDomain: any = selection.map(xScaleAll.invert);
        const dataRemaining: any = dateSet.filter((f: any) => f >= xDomain[0] && f <= xDomain[1]).length;
        if(dataRemaining < 2 && dataRemaining !== dateSet.length) {
          //if only one data point, reset to previous (also catch in there in case only one data point!)
          const previousStart = xScaleAll(xScale.domain()[0]);
          const previousEnd = xScaleAll(xScale.domain()[1]);

          mySvg.select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, [previousStart, previousEnd]);
        } else {
          // otherwise
          // reset x scale
          xScale.domain(xDomain);
          // call x axis again
          mySvg.select('#xAxis' + myClass)
            .call(d3.axisBottom(xScale).tickSizeOuter(0)
              .tickFormat(xTickFormat)
              .ticks(tickCount)
              .tickValues(tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1])))
            .attr('transform', 'translate(' + (margins.left ) + ',' + (height - margins.bottom - margins.brush) + ')');
          // draw chart
          drawChart();
          // show/hide partial period depending on whether last data point shown
          if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          } else {
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility",  "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility","hidden")
          }
          // position handle lines and reset handle styles - see area line/bar for full explanation
          const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
          const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');

          mySvg.select('.handleLeftLine1' + myClass)
            .attr('x1', handleEastX + 3)
            .attr('x2', handleEastX + 3);

          mySvg.select('.handleLeftLine2' + myClass)
            .attr('x1', handleEastX + 7)
            .attr('x2', handleEastX + 7);

          mySvg.select('.handleRightLine1' + myClass)
            .attr('x1', handleWestX + 3)
            .attr('x2', handleWestX + 3);

          mySvg.select('.handleRightLine2' + myClass)
            .attr('x1', handleWestX + 7)
            .attr('x2', handleWestX + 7);

          mySvg.select('#brushGroup' + myClass)
            .selectAll('.handle')
            .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
            .attr('fill', 'white')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('y', ((margins.brush - margins.mid) - 24) / 2)
            .attr('height', 24)
            .style('filter', 'url(#drop-shadow)');
        }
      }
    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function(): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 8;
        const dy = 22;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            if(line.length > 0){
              tspan.text(line.join(' '));
              line = [word];
              if (word.trim().length > 0){
                lineNumber += 1;
                tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

              }
            } else {
              line = [word]
            }
          }
        }
      });
    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px poppins';
      return context.measureText(myText).width;
    }
    this.wait(100).then(() => this.isLoading = false);
  }
// property & data for the chart
  getAreaLineComboData() {
    let modifiedData = this.data.map((e:any) => {
      return {
          ...e,
          error_type: e.error_type === 'E' ? 'AVG RT-E' : 'AVG RT-F'
      };
  });
    this.areaLineData["lineData"] = modifiedData
    this.areaLineData["barData"] = this.data
    //  ", "TEST ONE": "gold", "test TWO": "red", "test three" : "purple", "test four": "black"
    
    this.props = {
      lineDataType: "group",
      legendLabel: { "F": "Failures", "E": "Errors", "AVG RT-F": "AVG RT-Failures", "AVG RT-E": "AVG RT-Errors" },
      colors: ["#C90F0F","#0BBF1D","#8C23E6", "#E223E6", "#FFCD4A", "#645DD7", "gold", "black", "purple", "orange"],
      barcolor:['#FFD953','#FF842B',"#8C23E6", "#E223E6", "#FFCD4A", "#645DD7"],
      chartHeight: 500,
      lineGroupVar: "error_type",//not needed if lineDataType === "single"
      barGroupVar: "error_type",
      lineVar: "no_of_days",
      barVar: "defect_count",
      dateVar: "period",
      showBrush: true,
      d3AxisFormatting: false,
      period: "D",
      yAxisLabelLeft:"Defects",
      yAxisLabelRight:"# of Days",
      yAxisFormat: "-2~s",
      partialPeriod: false,
      barFill: { 'E': '#FFD953', 'F': '#FF842B' }, //'W': "deeppink","FP" : "skyblue",
      barLabels: { 'E': 'Errors', 'F': 'Failures' }//'W': "Warnings","FP" : "Failures But Processed"

    }
    // no data is below
    // this.areaLineData = {lineData: [], barData: []};
    if (this.areaLineData.lineData.length > 0) {
      if (Object.keys(this.areaLineData.lineData[0]).indexOf("partial_period_flg") > -1) {
        if (this.areaLineData.lineData.find((f: any) => f["partial_period_flg"] === "Y") !== undefined) {
          this.props.partialPeriod = true;
        }
      }
    }
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 80;
    }else{
      this.props.chartHeight = 500
    }
    if ( this.areaLineData.lineData.length > 0 && this.areaLineData.barData.length > 0) {
      this.noData= false
      setTimeout(() => {
        this.plotChart();
      }, 100);
    } else {
      this.noData= true
      this.areaLineData = { lineData: [], barData: [] };
      setTimeout(() => {
        this.plotChart();
      }, 100);
    }
    // });

  }
  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }

}
