import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-stacked-arae-bar',
  templateUrl: './stacked-arae-bar.component.html',
  styleUrls: ['./stacked-arae-bar.component.scss']
})
export class StackedAraeBarComponent implements OnInit {
  static key = 100705;

  @Input("item") item: any;
  @Input("pageKey") pageKey: any;
  heading: any;
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("StackedAreaBartwoChartDiv", { static: true }) StackedAreaBartwoChartDiv!: ElementRef;
  @ViewChild("StackedAreaBarmultiChartDiv", { static: true }) StackedAreaBarmultiChartDiv!: ElementRef;
  config: any;
  data: any;
  isDataLoaded = false;
  reqSubcription: Subscription[] = [];
  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute
  ) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        // this.loadApiData();
      })
    );
  }
  ngOnInit(): void {
    this.item;
    this.loadlineConfig();
    const observerone = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observerone.observe(this.StackedAreaBartwoChartDiv.nativeElement);
    const observertwo = new ResizeObserver((et: any) => {
      this.onSizeChange.emit(et[0].contentRect.height);
    });
    observertwo.observe(this.StackedAreaBarmultiChartDiv.nativeElement);
   
  }

  loadlineConfig() {
    this.heading = this.item.config["report_heading"];
    // this.config = {
    //   colours: {
    //     Biologics: "#8C23E6",
    //     Onco360: "#E223E6",
    //     Amber: "#FFCD4A",
    //     RxCrossroads: "#645DD7",
    //     TEST1: "gold",
    //     TEST2: "black",
    //     TEST3: "purple",
    //     TEST4: "orange",
    //   },
    //   period: "weekly",
    //   xAxisLabel: this.item.config["x_axis"]["api_resp_column_display_nm"],
    //   yAxisLabel: this.item.config["y_axis"]["api_resp_column_display_nm"],
    //   xAxisVar: this.item.config["x_axis"]["api_resp_column_nm"],
    //   yAxisVar: this.item.config["y_axis"]["api_resp_column_nm"],
    //   value_column: this.item.config["value_column"]["api_resp_column_nm"],
    //   secondary_value_column:
    //     this.item.config["secondary_value_column"]?.["api_resp_column_nm"] ||
    //     null,
    //   displayType: "value",
    // };
  }

  loadApiData(sourceName?: string) {
    let api = this.item.config["list_of_api"] || {};
    if(api && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {

      // API Payload
      let obj:any = {"api_key" : api.api_key, "source_nm" : sourceName}
      
      this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res: any) => {
            this.isDataLoaded = true;
            this.data = (res == null || res == undefined ) ? [] : res;
          },
          (error: any) => {
            this.data = [];
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
