import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { CompactType, GridsterItemComponent, GridsterPush } from 'angular-gridster2'
import * as _ from 'lodash'
import moment from 'moment'

import { FilterService } from 'src/app/services/filter.service'
import { WidgetService } from '../../../services/widget.service'
import { DeviceDetectionService } from 'src/app/services/detectipad.service'
import { FilterBadgesComponent } from '../filter-badges/filter-badges.component'
import { ShowByCardComponent } from '../show-by-card/show-by-card.component'
import { AlertButton } from '../alert-button/alert-button'
import { FilterButton } from '../filter-button/filter-button'
import { WidgetConfig, keysByType, Copyright as CopyrightWidgetConfig } from '../widget.model'
import config, { GridsterConfig } from './config'
import { InfoIconComponent } from '../info-icon/info-icon.component'
import { WidgetTextNotesComponent } from '../text-notes/text-notes.component'
import { FilterComponent } from '../filter/filter.component'
import { ActivatedRoute } from '@angular/router'
import { NavigationService } from 'src/app/services/navigation.service'

@Component({
  selector: 'app-grid-page',
  templateUrl: './grid-page.component.html',
  styleUrls: ['./grid-page.component.scss'],
})
export class GridPageComponent implements OnInit {
  @Input('designMode') designMode = false
  @Input('pageInfo') pageInfo: any = {}
  @ViewChildren(GridsterItemComponent) gridsterItems!: QueryList<GridsterItemComponent>;

  options: GridsterConfig = config
  widgets: WidgetConfig[] = []
  kpiCardWidgets: WidgetConfig[] = []
  informationCardWidgets: WidgetConfig[] = []
  commonWidgets: WidgetConfig[] = []
  textNotesWidget: WidgetConfig | undefined
  filterWidgetConfig: WidgetConfig | undefined
  showLoader = false;
  extract_hide_flg:any;

  isMobile = false;
  isTablet = false;
  isDesktop = false;

  protected readonly keysByType = keysByType

  constructor (
    public filterService: FilterService,
    public widgetService: WidgetService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    public deviceDetectionService: DeviceDetectionService,
    private cdr: ChangeDetectorRef
  ) {
    this.widgetService.showLessAndMore.subscribe(flag => this.showLessMore = flag);
    this.route.queryParams.subscribe((params: any) => {
      let decryptedParams = this.navigationService.decryptData(params);
      this.extract_hide_flg = decryptedParams["hd_flg"];
    });
  }

  moment = moment
  showLessMore = false

  ngOnInit () {
    if (!this.pageInfo.page_config_details) return

    // debugger

    this.filterService.baseQuery = {} // empty the baseQuery from the filter
    let widgets: WidgetConfig[] = JSON.parse(this.pageInfo.page_config_details);

    if(this.extract_hide_flg=='N') {
      const fndIndex = widgets.findIndex((o:any)=> o.config?.['hideWidgets'] && o.config?.['hideWidgets']?.name == 'Yes');
      if(fndIndex!=-1) {
        widgets.splice(fndIndex, 1);
      }
    }

    // sort widgets vertically
    widgets = widgets.sort((w1, w2) => w1.y - w2.y)
    const platformSpecific = _.filter(widgets, w => (
      keysByType[window.innerWidth >= 1024 ? 'desktopHide' : 'mobileHide'].includes(w.key)
    ))

    const filterBadges = _.find(widgets, { key: FilterBadgesComponent.key })
    if (filterBadges) filterBadges.layerIndex = 2

    const headerWidgets = widgets.filter(w => w.checked)
    let sortedHeaderWidgets: WidgetConfig[] = []
    if (headerWidgets.length) {
      sortedHeaderWidgets = [...headerWidgets] // Create a copy to avoid direct manipulation
      sortedHeaderWidgets.sort((w1, w2) => (w1.key === FilterBadgesComponent.key ? -1 : w2.key === FilterBadgesComponent.key ? 1 : 0))
      sortedHeaderWidgets.sort((w1, w2) => (w1.key === FilterButton.key ? 1 : w2.key === FilterButton.key ? -1 : 0))
    }
    this.textNotesWidget = _.find(sortedHeaderWidgets, { key: WidgetTextNotesComponent.key })

    this.filterWidgetConfig = widgets.find(w => [FilterComponent.key, FilterButton.key].includes(w.key))
    this.filterService.showByInGrid = !!widgets.find(w => w.key === ShowByCardComponent.key)
    // TODO there should be a better way to set widget for the page header
    setTimeout(() => {
      this.widgetService.pageHeaderWidgets = _.filter(sortedHeaderWidgets, w => keysByType.pageHeader.includes(w.key))
      this.widgetService.filterIcon = !!this.filterWidgetConfig && this.filterWidgetConfig.key === FilterButton.key
      this.widgetService.alertIcon = !!_.find(widgets, { key: AlertButton.key })
    }, 100)

    const commonWidgets = _.filter(widgets, w => keysByType.common.includes(w.key))
    this.kpiCardWidgets = _.filter(widgets, w => keysByType.kpiCard.includes(w.key))
    this.informationCardWidgets = _.filter(widgets, w => keysByType.informationCard.includes(w.key))

    const gestureWidgets = _.filter(widgets, w => keysByType.gesture.includes(w.key))
    const copyrightWidget = _.find(widgets, w => (
      w.key === CopyrightWidgetConfig.key && w.config['notes'].includes('Copyright')
    ))
    if (copyrightWidget) _.remove(widgets, copyrightWidget)
    this.widgets = _.difference(widgets, gestureWidgets, headerWidgets, platformSpecific)
    
    // Empty Space for bottom widget
    let emptyBottom:any = {"x": 0,"y": 100,"cols": 50,"rows": 1,"key": 100202,"config": {"widgetKey": 100202,"notes": ``}}
    this.widgets.push(emptyBottom);

    this.commonWidgets = _.difference(commonWidgets, platformSpecific)

    // position change
    this.commonWidgets.forEach((w, i) => {
      if (w.key === InfoIconComponent.key) {
        this.commonWidgets.splice(i, 1)
        this.commonWidgets.push(w)
      }
    })
    // position change
    this.commonWidgets.forEach((w, i) => {
      if (w.key === FilterButton.key) {
        this.commonWidgets.splice(i, 1)
        this.commonWidgets.push(w)
      }
    })

    // this.ipadBreakpoint()
    this.changedOptions()
    this.updateScreenSize();
  }

  changedOptions () {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged()
    }
  }

  ipadBreakpoint() {
    // debugger
    const gridster:any = document.getElementById('gridster');
    const width = window.innerWidth - gridster.clientWidth;
    const ops = gridster.clientWidth + width; 
    
    if(ops<1024) {
      this.options.mobileBreakpoint = gridster.clientWidth
    } else {
      this.options.mobileBreakpoint = 1024
    }

  }

  // Function to detect screen size
  updateScreenSize() {
    const width = window.innerWidth;
    this.isMobile = width >= 251 && width <= 767;
    this.isTablet = width >= 768 && width <= 1024;
    this.isDesktop = width > 1024;
  }

  pushItem (item: GridsterItemComponent) {
    this.options.draggable.enabled = true
    this.options.compactType = CompactType.CompactUp
    this.changedOptions()

    const push = new GridsterPush(item)
    const node = _.find(item.el.childNodes, { nodeType: Node.ELEMENT_NODE }) as HTMLElement
    const rows = Math.ceil(node.offsetHeight / item.gridster.curRowHeight)
    item.$item.rows = rows
    if (push.pushItems(push.fromNorth)) {
      push.checkPushBack()
      push.setPushedItems()
      item.setSize()
      item.checkItemChanges(item.$item, item.item)
    } else {
      item!.$item.rows = rows
      push.restoreItems()
    }
    push.destroy()
    this.options.draggable.enabled = false
    this.changedOptions()
  }

  resize (widgetConfig: WidgetConfig) {
    const item = this.gridsterItems?.filter(item => {
      return WidgetService.getId(item.item as WidgetConfig) === WidgetService.getId(widgetConfig)
    })
    if(item?.length) {item.forEach((m:any)=> this.pushItem(m))} 
  }

  getCenterBetween () {
    return this.deviceDetectionService.isDesktop ? 'space-between' : 'center'
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.ipadBreakpoint();
    this.updateScreenSize();

  }

  autoHeightFunc() {
    // debugger
    if(this.filterService.singlePage) {
      if(this.widgetService.pageHeaderWidgets.length) {
        return 'calc(100vh - 119px)';
      } else {
        return 'calc(100vh - 70px)';
      }
    } else {
      if(this.widgetService.pageHeaderWidgets.length) {
        return 'calc(100vh - 179px)';
      } else {
        return 'calc(100vh - 169px)';
      }
    }
  }
}