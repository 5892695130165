<loading *ngIf="loader" type="kpi"></loading>
<div class="container widget-container">
  <div class="d-flex justify-content-center align-items-center">
    <div class="donut-container" *ngIf="chartData">
      <app-donut-chart [data]="chartData"
          [donutWidth]="donutWidth"
          [dataColumn]="'data'"
          [colorColumn]="'color'"></app-donut-chart>
    </div>
    <div style="margin-block: auto; position: relative;text-align: left;white-space: nowrap;"
        class="align-items-center mx-3" style="white-space: nowrap;">
      <div style="display: inline-flex;font-family: 'Poppins';">
        <span class="Percentage">{{ headPercentage || '0%' }}</span>
        <span class="ratio">{{ headRatio || '(0/0)' }}</span>
      </div>
      <div class="headHead">
        <span class="heading"
            *ngIf="this.item.config"> {{ this.item.config['kpi_heading'] || 'KPI Heading' }}</span><br>
      </div>
      <div class="headHead">
        <span class="subheading">({{ subHeading }})</span>
      </div>
    </div>
    
    <div style="white-space: nowrap;text-align: left;font-family: 'Poppins';" *ngIf="!this.table_view">
      <div *ngFor="let i of data">
        <span [style.background-color]="i.color" class="dot"></span>
        <span style="font-family: 'Poppins';font-size: 12px;" class="pl-1">{{ i.name }}</span>:
        <span style="font-weight: bold;font-family: 'Poppins';font-size: 12px;">{{ i.value }}</span>
      </div>
    </div>

    <div *ngIf="this.table_view" style="white-space: nowrap; display: grid;grid-template-columns: auto auto;grid-template-rows: auto auto;grid-gap: 0 10px;align-content: center;grid-auto-flow: row;">
      <div class="d-flex justify-content-between align-items-center" *ngFor="let i of data">
        <span class="d-flex align-items-center mr-1">
          <span [style.background-color]="i.color" class="dot"></span>
          <span style="font-family: 'Poppins';font-size: 12px;" class="pl-1">{{ i.name }}</span>
        </span>
        <span style="font-weight: bold;font-family: 'Poppins';font-size: 12px;">{{ i.value }}</span>
      </div>
    </div>
  </div>
</div>



<!-- Top Right Opacity Icon -->
<div class="large-icon-containter" *ngIf="item.config" id="setting">
  <img class="info_img"  matTooltip="Help" (click)="getInfoDetail()"  [matMenuTriggerFor]="info"   type="button"  src="./../../../../assets/svgIcons/info_circle.svg"/>
  <!-- <span (mouseover)="isOpen = !isOpen" (mouseout)="isOpen = !isOpen"  type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="stroke_text">i</span> -->
  
  <mat-menu  #info="matMenu">
    <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
      <div class="card-header d-flex justify-content-between align-items-center" style="border-bottom: none;background: #ddd;">
        <span style="font-weight: 600;">{{infoData?.info_header}}</span>

        <span (click)="closeMenu()" mat-dialog-close> 
            <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z" fill="#1363DF" />
            </svg>
        </span>
      </div>

      <div class="card-body">
        <div [innerHtml]="(infoData?.info_text) || '' "></div>
      </div>
    </div> 
  </mat-menu>
</div>