import { Component, ElementRef, EventEmitter, HostListener, Injectable, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as d3 from "d3";
import * as htmlToImage from 'html-to-image';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { DialogService } from 'src/app/services/dialog.service';
import { ExcelExportService } from 'src/app/services/excelExport.service';
import moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { GanttChartComponent } from '../gantt-chart/gantt-chart.component';
// import { CurrencyPipe } from '@angular/common';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { BubbleChartComponent } from '../bubble-chart/bubble-chart.component';
import { SparklinesGridComponent } from '../sparklines-Grid/sparklines-grid.component';
import { CsvExportService } from 'src/app/services/csvexport.service';
import { ChoroplethMapChartComponent } from '../choropleth-map-chart/choropleth-map-chart.component';
import { NetworkChartComponent } from '../network-chart/network-chart.component';
import { ComboBarChartComponent } from '../combo-bar-card/combo-bar-chart.component';
import { WordCloudChartComponent } from '../word-cloud-chart/word-cloud-chart.component';
import { GroupedHbarChartComponent } from '../grouped-hbar-chart/grouped-hbar-chart.component';
import { SmallBarChartComponent } from '../small-bar-chart/small-bar-chart.component';
import { AreaLineComboChartComponent } from '../area-line-combo-chart/area-line-combo-chart.component';
import { BulletChartComponent } from '../bullet-chart/bullet-chart.component';
import { HierarchicalGridChartComponent } from '../hierarchical-grid-chart/hierarchical-grid-chart.component';
import { SlopeChartSComponent } from '../slope-chart/slope-chart.component';
import { HbarChartComponent } from '../hbar-chart/hbar-chart.component';
import { CurrencyPipe } from '@angular/common';
import { BasicHeatmapComponent } from '../basic-heatmap/basic-heatmap-chart.component';
import { KpiBreakdownHeatmapComponent } from '../kpi-breakdown-heatmap/kpi-breakdown-heatmap-chart.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupStatusComponent } from '../../popup-status/popup-status.component';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { ControlChartComponent } from '../control-chart/control-chart.component';
import { WidgetPivotGridComponent } from 'src/app/components/widget/pivot-Grid/pivot-grid.component';
import { StatusCodesComponent } from 'src/app/components/widget/status-codes/status-codes.component';
import { StackedAreabarComponent } from '../stacked-areabar/stacked-areabar.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculationService } from 'src/app/services/calculation.service';
import { TerritoryMapComponent } from 'src/app/components/widget/territory-map/territory-map.component';
import { ApiService } from 'src/app/services/api.service';
import { DispenseMapComponent } from 'src/app/components/widget/dispense-map/dispense-map.component';
import { ExportPPTService } from 'src/app/services/export-ppt.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { horizontalHarChartComponent } from '../horizontal-bar-chart/horizontal-bar-chart.component';
// import htmlToPdfmake from 'html-to-pdfmake';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { EnhancementService } from 'src/app/services/enhancements-service';
import { stackedComboBarChartComponent } from '../stacked-combo-bar-chart/stacked-combo-bar-chart.component';
import config from '../../../widget/grid-page/config';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'icon-chart',
  templateUrl: './icon-chart.component.html',
  styleUrls: ['./icon-chart.component.scss']
})

export class IconChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  @ViewChild("controlPopup", { static: true }) controlPopup!: ElementRef;
  @ViewChild("pdfCustom", { static: true }) pdfCustom!: ElementRef;
  @ViewChild("ExportToTablePDF", { static: true }) ExportToTablePDF!: ElementRef;
  @Input('iconList') iconList: any
  @Input('pageKey') pageKey: any
  @Input('data') data: any
  NetworkChartData: any = []
  @Input('rowDataLength') rowDataLength: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('exportcolumnDefs') exportcolumnDefs: any
  @Input('selectedTypeTimeCyle') selectedTypeTimeCyle: any;
  @Input('heading') heading: any
  @Input('sliderValue') sliderValue: any
  @Input('chartName') chartName: string = ""
  @Input('expand') expand: any
  @Input('fullscreen') isFullscreen: any
  @Input('widgetTitle') widgetTitle: any; selectedValue: any
  isActive = false;
  pagination:boolean=false
  pivot: boolean = true
  dateFormat: boolean = true
  @ViewChild("menu") info!: MatMenuPanel;
  @ViewChild("Exportmenu") Exportmenu!: MatMenuPanel;
  infoData: any
  gridData: any=[]
  columnDefs: any=[]
  expandChart: boolean = false;
  PdfPosition: any = 'l'
  choroDeta: any
  sourceName: any
  SettingValue: any
  compareChart: boolean = false;
  Stddeviation: boolean = true;
  Thresholdsvalues: boolean = true;
  SubHeading:any =''
  MeanAvg: boolean = false;
  expandCollapseIcon = "gridload_circle"
  expandOrCollapse = "Expand All"
  selectedType: string = "percent"; annotations: boolean = false; settingpopup: Boolean = false
  @Input('headerConfig') headerConfig: any;
  @Input('alertIcons') alertIcons: any;
  @Input('selectedFilter') selectedFilter: any;
  @Input('sourceHeaderName') sourceHeaderName: any;
  @Input('apiKey') apiKey: any;
  setting = "setting-sm"
  counter: any = 2;
  plus = "plus_icons"
  minus = "frame 4"
  sliderArray: any = []
  iconList1: any = [];
  gridsize: any = 30;
  cardName: any;
  cardId: any;
  clicked:Boolean=false
  clientKey: any; thresholdoldData: any
  dialogRef!: MatDialogRef<any>;
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  sortByArray: any = [];
  Buffer: boolean = false
  isPortraitMode: boolean = false
  iconsWithoutPopup: boolean = false;
  minValue = 1;
  maxValue = 100; showByvalue = 'sp'
  inputPagenationFromDir = true;
  userForm!: FormGroup;
  Previews: boolean = false
  look_back_period: any = 0
  no_of_std_deviations: any = 0
  upper_threshold: any = 0
  lower_threshold: any = 0
  buffer_range: any = 0
  selectedCount: any = 2
  reqSubcription: Subscription[] = [];
  moment=moment
  detailData:any=[];
  clickedevent :boolean=false
  nav_header_name:any=''

  @Output() typeToSearch = new EventEmitter();
  @Output() onPageSizeChange = new EventEmitter();
  @Output() phiFromGrid = new EventEmitter();
  @Output() exportXls = new EventEmitter();
  @Output() screenChanges = new EventEmitter();
  @Output() timeCycleChanges = new EventEmitter();
  @Output() sliderValueEmitter = new EventEmitter();

  onWindowResize(event: any) {
    this.checkOrientation();
  }
  checkOrientation() {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

  constructor(private LineChart: LineChartComponent, private currency: CurrencyPipe,
    private GanttChart: GanttChartComponent,
    private BarChart: BarChartComponent,
    // @Inject('DispenseMapComponent') private bubbleChart: DispenseMapComponent,
    private bubbleChart: BubbleChartComponent,
    private SparklinesGrid: SparklinesGridComponent,
    private comboBar: ComboBarChartComponent,
    private dataService: DataService,
    private router: ActivatedRoute,
    private dialogService: DialogService,
    private excelService: ExcelExportService,
    private numberPipe: NumberPipe,
    public filterService: FilterService,
    private Csvexport: CsvExportService,
    private ChoroplethChart: ChoroplethMapChartComponent,
    private NetworkChart: NetworkChartComponent,
    // private wordColud: WordCloudChartComponent,
    private wordColud: horizontalHarChartComponent,
    private GroupedHbar: GroupedHbarChartComponent,
    private smallBar: SmallBarChartComponent,
    private areaLine: stackedComboBarChartComponent,
    private bulletChart: BulletChartComponent,
    private paymentGrid: HierarchicalGridChartComponent,
    private slopeChart: SlopeChartSComponent,
    private HBarChart: HbarChartComponent,
    private basicHeatMap: BasicHeatmapComponent,
    private KpiHeatMap: KpiBreakdownHeatmapComponent,
    private userActivityService: UserActivityService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public newFilterService: NewFilterService,
    public deviceDetectionService: DeviceDetectionService,
    private controlChart: ControlChartComponent,
    public pivotGrid: WidgetPivotGridComponent,
    public statusCodeRef: StatusCodesComponent,
    private StackAreaBar: StackedAreabarComponent, private fb: FormBuilder, public calculationStd: CalculationService,
    // private TerritoryMap: TerritoryMapComponent,
    private apiService: ApiService,
    private exportPPT :ExportPPTService,
    private navigationService: NavigationService,
    public enhancementService:EnhancementService
  ) {

    this.filterService.expendTree.subscribe((r: any) => {
      if(r.key==this.item.config.widgetKey){this.expandChart = r.value}
    })

    this.filterService.settingpopupclose.subscribe((res: any) => {
      this.settingpopup = false

    })
    this.filterService.inputValueOfPagenation.subscribe((re: any) => {
      this.inputPagenationFromDir = re
    })
    this.filterService.filterQuery.subscribe((query: any) => {
      this.compareChart = false;
      // this.cardName = this.route.snapshot.params['cardName'] || sessionStorage.getItem('recdetailGrid')
    });

    
    this.route.params.subscribe((p: any) => {
      this.sourceName = p["cardName"];
      this.cardName = p["cardName"];
      
      this.route.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
  
        this.clientKey = decryptedParams["cl_key"];
        this.cardId = decryptedParams["cardId"] || '';
        this.cardId = decryptedParams["cardId"] || '';

        // Payment Calc Record Level Header 
        if(params?.['record']=='yes' && sessionStorage.getItem("filterData")) {
          let filter:any = sessionStorage.getItem("filterData")
          const queryFilterData = JSON.parse(filter) || {}; 
          if(queryFilterData instanceof Array && queryFilterData?.length) {
            this.detailData = queryFilterData;
            const lastElement = this.detailData.pop();
            this.detailData.unshift(lastElement);
          } else {
            this.detailData = [];
          }
        }
      });



    });

    this.route.params.subscribe((p: any) => {
      // this.clientKey = p["clientKey"];
      // this.sourceName = p["sourceName"];
      // this.cardName = p["cardName"] || sessionStorage.getItem('recdetailGrid');
      // this.cardId = p["cardId"];

      // if (this.cardName == 'pwt') {
      //   this.route.queryParams.subscribe((params: any) => {
      //     if (this.cardId == 'paCard') {
      //       this.cardId = params.paId
      //     }
      //   })
      // };

    })
    this.reqSubcription.push(this.filterService.kpiBreakDownSelectedType.subscribe((p: any) => { this.compareChart = false }))
  }
  openDialogIcon() {
    this.dialogRef = this.dialog.open(this.dialogTemplate, {
      width: '280px',
    });

    this.dialogRef.afterClosed().subscribe(() => {
      // Handle the result here if needed
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
  selectedVal() {
    this.counter = this.selectedCount
  }
  cancel() {
    document.getElementById("setting")?.click()
    this.settingpopup = false
    this.reset()
  }
  movingavgs() {
    this.counter = this.selectedCount
    document.getElementById("moving_average")?.click()
  }
  annotationmethod() {
     
    this.annotations = !this.annotations
    this.filterService.annotations = this.annotations
    this.controlChart.props.showDragSelect = !this.annotations
    this.controlChart.getControlChartData()
    d3.select("#d3pincontrolChartTooltip")
      .style('visibility', 'hidden')
    d3.select("#d3controlChartTooltip")
      .style('visibility', 'hidden');
    d3.select("#d3annotationsTooltip")
      .style('visibility', 'hidden');
  }

  // checkedPreview = false;
  preview() {
    if (this.upper_threshold < this.lower_threshold) {
      this.upper_threshold = 0
      this.lower_threshold = 0
      alert("lower Threshold should not be greater than upper Threshold")
      return
    }
    // if(this.upper_threshold>this.lower_threshold){
    //   this.upper_threshold=0
    //   this.lower_threshold=0
    //   alert("upper Threshold should not be less than  lower  Threshold")
    //   return
    // }
    this.controlChart.props.showMeanLine = this.MeanAvg
    if (this.Stddeviation) {
      this.controlChart.ControlChartData['properties'][0]['look_back_period'] = this.look_back_period || 0
      this.controlChart.ControlChartData['properties'][0]['no_of_std_deviations'] = this.no_of_std_deviations || 0
    } else {
      this.controlChart.ControlChartData['properties'][0]['look_back_period'] = null
      this.controlChart.ControlChartData['properties'][0]['no_of_std_deviations'] = null
    }
    if (this.Thresholdsvalues) {
      this.controlChart.ControlChartData['properties'][0]['lower_threshold'] = this.lower_threshold == 0 ? 0 : this.lower_threshold || null
      this.controlChart.ControlChartData['properties'][0]['upper_threshold'] = this.upper_threshold || null
    }
    else {
      this.controlChart.ControlChartData['properties'][0]['lower_threshold'] = null
      this.controlChart.ControlChartData['properties'][0]['upper_threshold'] = null
    }
    if (this.Buffer) {
      this.controlChart.ControlChartData['properties'][0]['buffer_range'] = (this.buffer_range / 100) || null
    } else {
      this.controlChart.ControlChartData['properties'][0]['buffer_range'] = null
    }
    // this.controlChart.ControlChartData['properties'].forEach((element:any) => {
    //   if(this.Stddeviation==false && this.Thresholdsvalues==false && this.Buffer==false){
    //     return element["showCalculations"]= "false"
    //   }else if(this.Stddeviation==false){
    //     return element["showCalculations"]= "false"
    //   }else{
    //   return element["showCalculations"]= "true"}

    //  });
    this.calculationStd.calculationControlChart(this.controlChart.ControlChartData['data'], this.controlChart.ControlChartData['properties'][0])
    this.controlChart.plotChart()
  }
  updateSetting(event: any) {
    this.gridsize = event.value;
    // debugger
    this.filterService.sliderValue = this.gridsize

    this.filterService.refreshAlertDelete.next(this.gridsize)
    this.sliderValueEmitter.emit(event.value)
  }
  
  isButtonClicked=false;

  changeStatus() {
    if (!this.isButtonClicked) {
      this.isButtonClicked = true;
      this.filterService.baseQuery = this.newFilterService.getPatientStatusBaseQuery()
      // patient status and patient reason we need to know, when navigate to Patient Card
      const obj = {
        api_key: 6001,
        report_typ: "D",
        patient_id: this.cardId,
        client_key: this.clientKey || "",
      }

      this.reqSubcription.push(this.filterService.executePatientQuery(obj).subscribe((res: any) => {
        let response: any = res ? res : []
        let findPatient = response.find((fnd: any) => fnd.patient_id == this.cardId)

        let resData = {
          api_key: 100202,
          patient_status: null,
          report_typ: "D",
          patient_case_status: findPatient.case_status,
          patient_reason_status: findPatient.status_reason,
          patient_id: findPatient.patient_id
        }

        if (this.cardName == 'pwt' || this.cardName == 'paCard' || this.cardName == 'card') {

          this.newFilterService.sendData(resData);

          this.dialog.open(PopupStatusComponent, {
            disableClose: true,
            width: '400px',
            height: '75',
          });
        }
      })) 

      setTimeout(() => {
        this.isButtonClicked = false;
      }, 2000);
    }
  }

  setSettingValue(value: any) { }
  settingsm = "setting-sm"

  reset() {
    this.settingpopup = true;
    if (!this.controlChart.props) {
      this.controlChart.props = {};
    }
    this.controlChart.props.showMeanLine = false
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      this.reqSubcription.push(
        this.filterService.kpiBreakdownexecuteQuery('100186').subscribe((res: any) => {
          this.filterService.thresholdoldData = res == null ? [] : res;
          this.controlChart.ControlChartData['properties'] = this.filterService.thresholdoldData
          this.controlChart.ControlChartData['properties'].forEach((element: any) => {
            return element["showCalculations"] = "true"
          });
          this.calculationStd.calculationControlChart(this.controlChart.ControlChartData['data'], this.controlChart.ControlChartData['properties'][0])
          this.controlChart.plotChart()
          this.onSubmit()
  
        }, (error: any) => {
          alert("can't able to reset , data not found , refresh the screen")
        })
      )
    }

  }
  Apply() {

    if (this.upper_threshold < this.lower_threshold) {
      this.upper_threshold = 0
      this.lower_threshold = 0
      alert("lower Threshold should not be greater than upper Threshold")
      return
    }
    // if(this.upper_threshold>this.lower_threshold){
    //   this.upper_threshold=0
    //   this.lower_threshold=0
    //   alert("upper Threshold should not be less than  lower  Threshold")
    //   return
    // }
    if (this.Stddeviation == false && this.Thresholdsvalues == false && this.Buffer == false) return
    
    let postData: any = {
      "data_source_dim_key": this.controlChart.ControlChartData['data'][0].source_key,
      "kpi_dim_key": this.controlChart.ControlChartData['data'][0].kpi_key,
      "lower_threshold": this.lower_threshold,
      "upper_threshold": this.upper_threshold,
      "look_back_period": this.look_back_period,
      "no_of_std_deviations": this.no_of_std_deviations,
      "buffer_range": this.buffer_range,
      "kpi_properties_key": this.controlChart.ControlChartData['properties'][0].kpi_properties_key,
      "api_key" : '100186'
    }

    this.reqSubcription.push(this.filterService.thresholdsexecuteQuery(postData).subscribe(
      (res: any) => {
        // this.filterService.thresholdoldData = res == null ? [] : res;
        // this.controlChart.ControlChartData['properties']=this.filterService.thresholdoldData
        // this.controlChart.plotChart()
        // this.onSubmit()
      },
      // (error: any) => {
      //   alert("Error")
      // }
    ))
    document.getElementById("setting")?.click()
    this.settingpopup = false
  }

  onSubmit() {
    // this.reset()
    // this.settingpopup = true
    this.Stddeviation = true
    this.Thresholdsvalues = true
    this.Buffer = true
    this.MeanAvg = false
    let threshold: any = this.controlChart.ControlChartData['properties'][0]
    this.look_back_period = threshold["look_back_period"] || this.look_back_period || null
    this.no_of_std_deviations = threshold["no_of_std_deviations"] || this.no_of_std_deviations || null
    this.buffer_range = (threshold["buffer_range"] > 1 && threshold["buffer_range"] < 101) ? threshold["buffer_range"] : threshold["buffer_range"] * 100 || null
    this.lower_threshold = threshold["lower_threshold"] || null
    this.upper_threshold = threshold["upper_threshold"] || null

  }
  ngOnInit(): void {
    this.sliderValue = this.filterService.sliderValue;
    
    this.checkOrientation();
    // this.timeCycleUserStatue();
    if (this.item.config?.sortBy) {
      this.sortByArray = this.item.config?.sortBy?.controls
    }
    // debugger
    this.selectedType=this.headerConfig?.find((fnd: any) => fnd.value == "selection_toggle")?.toggle_btn?.[0]?.value
    this.headerConfig = this.headerConfig?.filter((flr: any) => flr.add_header == true)
    let headerName = this.headerConfig?.find((fnd: any) => fnd.value == "page_header")
    this.showByvalue= this.headerConfig?.find((fnd: any) => fnd.value == "show_by")?.show_by_value?.[0]?.value
    this.heading = headerName ? headerName.page_header_name : "No Header"

    // nav header config
    if(headerName?.['nav_header']){
      debugger
      const value:any = JSON.parse(sessionStorage.getItem("navHeaderData") || '');
      let arr = headerName.nav_header_var.split(",");
      arr.forEach((m:any)=> {
        this.nav_header_name +=" " + value[m];
    });
    }

    this.headerConfig?.find((i: any) => {
      if (i.value == 'icons') {
        this.iconsWithoutPopup = false
      } else {
        this.iconsWithoutPopup = true
      }
    })
    this.headerConfig?.forEach((res: any) => {
      if (res.slider) {
        this.sliderArray = []

        for (let i = res.sliderLenth[0].min || 1; i < res.sliderLenth[0].max || 7; i++) {
          this.sliderArray.push({ 'name': i, 'value': i })
          if (i == res.sliderLenth[0].max) break;
        }
      }
    })

    this.headerConfig?.find((i: any) => {
      if (i.value == 'icons') {
        i.iconList.forEach((j: any) => {
          if (j.activated) {
            this.iconList1.push(j)
          }
        })
      } else if (i.value == 'slider') {
        this.iconList1.push(i)
      }
    })
    this.iconList1?.find((i: any) => {
      if (i.name == 'dotmenu_circle') {
        i.event?.forEach((k: any) => {
          if (k.is_true) {
            this.iconList1.push(k)
          }
        })
      }
    })
    const uniqueArrayOfObjects = [];
    const uniqueNames: any = [];
    for (const obj of this.iconList1) {
      if (!uniqueNames.includes(obj.name)) {
        uniqueNames.push(obj.name);
        uniqueArrayOfObjects.push(obj);
      }
    }

    this.iconList1 = uniqueArrayOfObjects
    const activityDaysIndex = this.iconList1.findIndex((obj: any) => obj.name === 'activity_days');
    if (activityDaysIndex > 0) {
      const activityDaysObj = this.iconList1.splice(activityDaysIndex, 1)[0];
      this.iconList1.unshift(activityDaysObj);
    }
    this.dataService.chartData.subscribe((resp: any) => {
      this.NetworkChartData = resp
    })
    this.dataService.choroplethData.subscribe((resp: any) => {
      this.choroDeta = resp
    })

  


  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.dataService.expandCollapseChart.subscribe((resp: any) => {
    //   this.expandChart = resp
    // })
    if(this.isFullscreen== false){
      document.getElementById("setting")?.click()
    }

    // Get the modal element
    var modal = document.getElementById('exampleModal');

    // Remove the 'modal-backdrop' class
    modal?.classList.remove('modal-backdrop');

    // Remove the 'show' class
    modal?.classList.remove('show');

    // Attach the event listener to the window object
    window.addEventListener("resize", (event) => {
      // console.log('size changed')

    });
  }
  Compare() {
    
    if (this.compareChart == true) {
      this.compareChart = !this.compareChart
      this.KpiHeatMap.props.showSelections = false
      this.KpiHeatMap.plotChart()
    } else {
      this.compareChart = !this.compareChart
      this.KpiHeatMap.props.showSelections = true
      this.KpiHeatMap.plotChart()
    }

  }
  CompareBtn() {
    this.KpiHeatMap.compareClick('route')
  }

  getInfoDetail() {
    let data = {
      api_key: this.item.config.list_of_api?.api_key || this.item.config.list_of_api || this.apiKey || 0,
      widget_key: this.item.config.widgetKey,
      page_key: this.pageKey,
      report_typ: 'D',
      brand_key: this.filterService.baseQuery?.brand_key
    }
    if (this.chartName == "bubbleMapDiv") {
      data.api_key = 100115
    }
    if (this.chartName == "statusCodesUI") {
      data.api_key = 100180
    }
    if (this.chartName == "IRSummery") {
      data.api_key = 100134
    }
    if (this.chartName == "kpiBreakdownChartDiv") {
      data.api_key = 100203
    }
    if (this.chartName == "paymentSummary") {
      data.api_key = 100199
    }
    if (this.chartName == "GridListdetails") {
      data.api_key = 100250
    }
    // if (this.chartName == "") {
    //   data.api_key = 
    // }if (this.chartName == "") {
    //   data.api_key = 
    // }
     if(data.api_key==0){alert('apiKey not found')} 
    // debugger
    this.dataService.getInfoDetail(data).subscribe((res: any) => {
      this.infoData = res && res[0] ? res[0] : { info_header: "No Info Data Found" }
    },(err)=>{
      this.infoData ={ info_header: "No Info Data Found" }
      this.dialogService.SnackBar('Info Data API Failed','OK')
    })
  }
  
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger?.closeMenu();
  }
  
  showByChange(values: any) {
    // debugger
  let val:any={
    widget:this.item['key'] ,
    value:values
  }
    this.filterService.chartshowBy.next(val);
  }

  openPDF(imgData: any) {
    // Generate PDF

    var doc = new jsPDF(this.PdfPosition, 'pt', 'a4');
    if (this.PdfPosition == 'l') {
      doc.text(this.heading, 350, 30);
      doc.addImage(imgData, 'PNG', 0, 50, 830, 550);
    } else {
      doc.text(this.heading, 235, 30);
      doc.addImage(imgData, 'PNG', 0, 50, 600, 500);
    }

    doc.save(this.heading + '_export_' + moment(new Date()).format("DD-MMM-YYYY-hh.mma") + '.pdf');
  }

  onSaveChartClicked(pdfValue?: any): void {
    let value: any
    const theElement = this.config;
    // this.exportPPT.exportToPpt(theElement,this.heading)
    // return
    htmlToImage.toPng(theElement).then((dataUrl) => {
      this.openPDF(dataUrl);
      // value =dataUrl
      // const documentDefinition =  {
      //         content: [

      //           {
      //             style: "tableExample",
      //             table: {
      //               widths: ['*'],
      //               body: [
      //                 [

      //                   {
      //                     image:value,
      //                     fit: [500, 800]
      //                   }
      //                 ]
      //               ]
      //             }
      //           }
      //         ]
      //       };
      // pdfMake.createPdf(documentDefinition).open() 
    });
  }
  openPDFcustom(PDFtype:any) {
  //  debugger
    if ( PDFtype=='pdftable') {
      this.dialogService.openDialog(this.ExportToTablePDF, "80vw")
      return
    }
    if (this.chartName == 'PaymentGrid') {
      this.paymentGrid.openPDFcustom()
    }
    else {
      this.dialogService.openDialog(this.pdfCustom, '250')
    }
    
  }

  openFullscreen(): void {
    const el = this.expand
    if (!document.fullscreenElement    // alternative standard method
    ) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.msRequestFullscreen) {

        el.msRequestFullscreen();
      }
    }
    setTimeout(() => {
      if (this.chartName == "lineChart") {
        this.LineChart.isFullscreen = true;
        this.LineChart.props.chartHeight = window.outerHeight - 60
      } else if (this.chartName == "barChart") {
        this.BarChart.props.chartHeight = window.outerHeight - 60
        this.BarChart.isFullscreen = true;
      } else if (this.chartName == "bubbleMapDiv") {
        // this.bubbleChart.props.chartHeight = window.outerHeight - 60
        this.bubbleChart.isFullscreen = true;
      }
      else if (this.chartName == "barGridDiv") {
        this.SparklinesGrid.isFullscreen = true;
        this.SparklinesGrid.gridHeight = window.outerHeight + "px"
      } else if (this.chartName == "GanttChart") {
        this.GanttChart.isFullscreen = true;
      } else if (this.chartName == "comboBar") {
        this.comboBar.props.chartHeight = window.outerHeight - 60
        this.comboBar.isFullscreen = true;
      } else if (this.chartName == "wordCloud") {
        // this.wordColud.props.fontSizeRange = [12, 40]
        this.wordColud.props.chartHeight = window.outerHeight - 60
        this.wordColud.isFullscreen = true;
      } else if (this.chartName == "GroupHBar") {
        this.GroupedHbar.props.chartHeight = window.outerHeight - 60
        this.GroupedHbar.isFullscreen = true;
      } else if (this.chartName == "smallBar") {
        this.smallBar.props.chartHeight = window.outerHeight - 60
        this.smallBar.isFullscreen = true;
      } else if (this.chartName == "BulletChartDiv") {
        this.bulletChart.props.chartHeight = window.outerHeight - 60
        this.bulletChart.isFullscreen = true;
      } 
      // else if (this.chartName == "areaLineChart") {
      //   this.areaLine.isFullscreen = true;
      //   this.areaLine.props.chartHeight = window.outerHeight - 70
      // }
       else if (this.chartName == "slopeChartDiv") {
        this.slopeChart.props.chartHeight = window.outerHeight - 60
        this.slopeChart.isFullscreen = true;
      } else if (this.chartName == "HBarChartDiv") {
        this.HBarChart.props.chartHeight = window.outerHeight - 60
        this.HBarChart.isFullscreen = true;
      } else if (this.chartName == "PaymentGrid") {
        this.paymentGrid.isFullscreen = true;
        this.paymentGrid.isActive = true
      } else if (this.chartName == "BasicHeatMapChartDiv") {
        this.basicHeatMap.isFullscreen = true;
        this.basicHeatMap.props.chartHeight = window.outerHeight - 60
      } else if (this.chartName == "kpiBreakdownChartDiv") {
        this.KpiHeatMap.isFullscreen = true;
        this.KpiHeatMap.props.chartHeight = window.outerHeight - 60
      } else if (this.chartName == "controlChartDiv") {
        this.controlChart.isFullscreen = true;
        this.controlChart.props.singleChartHeight = window.outerHeight - 230
      } else if (this.chartName == "StackAreaBar") {
        this.StackAreaBar.isFullscreen = true;
        this.StackAreaBar.props.chartHeight = window.outerHeight - 60
      } else if (this.chartName == "ChoroplethChart") {
        this.ChoroplethChart.isFullscreen = true;
      } else if (this.chartName == "NetworkChart") {
        this.isFullscreen = true;
        this.isActive = true
      } else if (this.chartName == "GridList") {
        this.isFullscreen = true;
        this.isActive = true
      } else if (this.chartName == "TerritoryDiv") {
        // this.TerritoryMap.isFullscreen = true;
        // this.TerritoryMap.isActive = true
      }

      // ---------------------------//

      if (this.chartName == "lineChart") {
        this.LineChart.isActive = true;
        this.LineChart.plotChart()
      }
      else if (this.chartName == "barChart") {
        this.BarChart.isActive = true;
        this.BarChart.plotChart()
      }
      else if (this.chartName == "GanttChart") {
        this.GanttChart.isActive = true;
        // this.GanttChart.plotChart()
      }
      else if (this.chartName == "ChoroplethChart") {
        this.ChoroplethChart.isActive = true
        // this.ChoroplethChart.plotChart()
      }
      else if (this.chartName == "NetworkChart") {
        // this.NetworkChart.isActive = true
      }
      else if (this.chartName == "bubbleMapDiv") {
        this.bubbleChart.isActive = true;
        // this.bubbleChart.getBubbleChartData()
        // this.bubbleChart.plotChart() 
      } else if (this.chartName == "barGridDiv") {
        this.SparklinesGrid.isActive = true;
      } else if (this.chartName == "comboBar") {
        this.comboBar.isActive = true;
        this.comboBar.plotChart()
      } else if (this.chartName == "wordCloud") {
        this.wordColud.isActive = true;
        this.wordColud.plotChart()
      } else if (this.chartName == "GroupHBar") {
        this.GroupedHbar.isActive = true;
        this.GroupedHbar.getGroupedHbarChartData()
      } else if (this.chartName == "smallBar") {
        this.smallBar.isActive = true;
        this.smallBar.plotChart()
      } else if (this.chartName == "BulletChartDiv") {
        this.bulletChart.isActive = true;
        this.bulletChart.plotChart()
      }
      //  else if (this.chartName == "areaLineChart") {
      //   this.areaLine.isActive = true;
      //   this.areaLine.plotChart()
      // }
       else if (this.chartName == "slopeChartDiv") {
        this.slopeChart.isActive = true;
        this.slopeChart.plotChart()
      } else if (this.chartName == "HBarChartDiv") {
        this.HBarChart.isActive = true;
        this.HBarChart.plotChart()
      } else if (this.chartName == "BasicHeatMapChartDiv") {
        this.basicHeatMap.isActive = true;
        this.basicHeatMap.plotChart()
      } else if (this.chartName == "kpiBreakdownChartDiv") {
        this.KpiHeatMap.isActive = true;
        this.KpiHeatMap.plotChart()
      } else if (this.chartName == "controlChartDiv") {
        d3.select("#d3pincontrolChartTooltip")
          .style('visibility', 'hidden')
        d3.select("#d3controlChartTooltip")
          .style('visibility', 'hidden');
        d3.select("#d3annotationsTooltip")
          .style('visibility', 'hidden');
        this.controlChart.isActive = true;
        this.controlChart.plotChart()
      } else if (this.chartName == "StackAreaBar") {
        this.StackAreaBar.isActive = true;
        this.StackAreaBar.plotChart()
      }
      else if (this.chartName == "TerritoryDiv") {
        // this.TerritoryMap.isActive = true;
      } 
     
      this.isFullscreen=true
      this.isActive=true
      this.screenChanges.emit(this.isFullscreen);
    }, 100);
  }

  closeFullscreen(): void {
    this.settingpopup = false;
    document.getElementById("setting")?.click() 
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (this.chartName == "bubbleMapDiv") {
      this.bubbleChart.isActive = true;
      // this.bubbleChart.getBubbleChartData()
    }
    this.screenChanges.emit(this.isFullscreen);
  }
  showByChanges(ShowBy: any, fieldName: any) {
    if (ShowBy == 'tablets') {
      return fieldName
    } else {
      return "number_of_bottles"
    }


  }
  // underline data popup method for all chart
  openDialog() {
    var val = this.item.config?.time_cycle ? this.item.config?.time_cycle?.find((f: any) => f.value == (this.filterService?.report_type || 'D')) : undefined  
    if (this.chartName == "lineChart" ) {
      this.columnDefs = [
        { field: (this.item.config.list_of_api.api_key == 100133 && this.filterService.showByKey=="N") ?this.item.config["show_by_ndc"]["api_resp_column_nm"] : this.item.config.l1_col['api_resp_column_nm'], rowGroup: true },
        { field: this.item.config.x_axis_column_2['api_resp_column_nm'], cellStyle: { textAlign: 'center' }, pivot: true },
        { field: ((this.item.config.list_of_api.api_key == 100133) || (this.item.config.list_of_api.api_key == 100196)) ? this.item.config.value_column_2['api_resp_column_nm'] : this.showByChanges(this.LineChart.showBy, this.item.config.value_column_2['api_resp_column_nm']), aggFunc: 'sum', cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.item.config.list_of_api.api_key == 100196 ? this.currency.transform(params.value) : this.numberPipe.transform(params.value) },
      ];
      this.gridData = this.data
      this.pivot = true
  }else if (this.chartName == "clusterBarChart") {
    this.columnDefs = [
      { field: "source_name", rowGroup: true },
      { field: "period", cellStyle: { textAlign: 'center' }, pivot: true },
      { field: 'difference', aggFunc: 'sum', cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
    ];
    this.gridData = this.data
    this.pivot = true
  }else if (this.chartName == "IRSummery") {
      this.columnDefs = [
        { field: 'source_name', rowGroup: true ,sort:'asc'},
        { field: 'period', cellStyle: { textAlign: 'center' }, pivot: true ,pivotComparator: (valueA: any, valueB: any) => valueB.localeCompare(valueA)},
        { field: 'qty_difference', aggFunc: 'sum', cellStyle: { "text-align": "center" }, valueFormatter: (params: any) =>  this.numberPipe.transform(params.value) },
      ];
      this.gridData = this.data
      this.pivot = true
    }
    else if (this.chartName == "barChart") {
      let val = this.item.config?.time_cycle.find((f: any) => f.value == (this.filterService.report_type || 'D'))
      this.columnDefs = [
        { headerName: "Shipment Date", field: this.item.config.list_of_api_col_2[0]['api_resp_column_nm'], valueFormatter: (params: any) => moment(params.value).format(val.format || 'DD/MM/YYYY'), cellStyle: { "text-align": "center" } },
        { headerName: "Quantity Shipped", field: this.item.config.list_of_api_col_2[1]['api_resp_column_nm'], cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        // { headerName: this.BarChart.showBy == "tablets" ? "Quantity Shipped("+ this.BarChart.showBy +")" : "Quantity Shipped("+ this.BarChart.showBy +")", field: this.item.config.list_of_api_col_2[1]['api_resp_column_nm'], cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        { field: this.item.config.list_of_api_col_2[2]['api_resp_column_nm'], cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
      ];
      // this.LineChart.showBy =="tablets" ? this.item.config.list_of_api_col_2[1]['api_resp_column_nm']: "number_of_bottles"
      this.gridData = this.data[0] // this.BarChart.showBy =="tablets" ? this.data[0] : this.BarChart.data
      this.pivot = false
    } else if (this.chartName == "bubbleMapDiv") {
      let val = this.item.config?.time_cycle.find((f: any) => f.value == (this.filterService.report_type || 'D'))
      this.columnDefs = [
        { headerName: "Shipment Date", field: "period", valueFormatter: (params: any) => moment(params.data.period).format(val.format || 'DD MMM YYYY'), cellStyle: { "text-align": "center" } },
        { headerName: "Source Name", field: "sourcename", cellStyle: { "text-align": "center" } },
        { headerName: "Pharmacy Location Name", field: "locationname", cellStyle: { "text-align": "center" } },
        { headerName: "Quantity Shipped", field:  "number_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        // { headerName: this.bubbleChart.showBy == "tablets" ? "Quantity Shipped("+this.bubbleChart.showBy+ ")": "Quantity Shipped("+this.bubbleChart.showBy+ ")", field: this.bubbleChart.showBy == "tablets" ? "quantityshipped" : "number_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
      ];
      this.gridData = this.data

      this.pivot = false
    }
    else if (this.chartName == "comboBar") {
      let userStatedata = this.userActivityService.getData()
      if (this.item.config.chart_type.value == "group") {
        this.columnDefs = [

          { headerName: "Shipment Date", field: "period", valueFormatter: (params: any) => moment(params.value).format(val.format || 'DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
          { headerName: 'Source Name', field: "data_source_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Shipped Patients', field: "shipped_patients", cellStyle: { "text-align": "center" } },
          { headerName: "Quantity Shipped", field: userStatedata.showBy == "tablets" ? "shipped_quantity" : "no_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
          // { headerName: (userStatedata.showBy == "tablets" && userStatedata.showBy != undefined) ? "Quantity Shipped("+this.comboBar.showBy+ ")" : "Quantity Shipped("+this.comboBar.showBy+ ")", field: userStatedata.showBy == "tablets" ? "shipped_quantity" : "no_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      } else {
        this.columnDefs = [

          { headerName: "Shipment Date", field: "period", valueFormatter: (params: any) => moment(params.value).format(val.format || 'DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
          { headerName: 'Shipments', field: "shipments", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
          { headerName: "Quantity Shipped", field: userStatedata.showBy == "tablets" ? "shipped_quantity" : "no_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
          // { headerName: userStatedata.showBy == "tablets" ? "Quantity Shipped("+this.comboBar.showBy+ ")" : "Quantity Shipped("+this.comboBar.showBy+ ")", field: userStatedata.showBy == "tablets" ? "shipped_quantity" : "no_of_bottles", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      }
      this.gridData = this.comboBar.data.lineData
      this.pivot = false
    }
    else if (this.chartName == "GanttChart") {
      {
        this.columnDefs = [
          { field: "PatientID", cellStyle: { "text-align": "center" } },
          { field: "Level1", cellStyle: { "text-align": "center" } },
          { field: "Level2", cellStyle: { "text-align": "center" } },
          { field: "Status", cellStyle: { "text-align": "center" } },
          { field: "StatusGroup", cellStyle: { "text-align": "center" } },
          { field: "SubStatusgroup", cellStyle: { "text-align": "center" } },
          { field: "StatusReason", cellStyle: { "text-align": "center" } },
          { field: "TransactionID", cellStyle: { "text-align": "center" } },
          { field: "TransactionDate", valueFormatter: (params: any) => moment(params.value).format('DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
          { field: "startDate", valueFormatter: (params: any) => moment(params.value).format('DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
          { field: "endDate", valueFormatter: (params: any) => moment(params.value).format('DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
          { field: "TransactionType", cellStyle: { "text-align": "center" } },
          { field: "TransactionName", cellStyle: { "text-align": "center" } },
          { field: "name", cellStyle: { "text-align": "center" } },
          { field: "rootParent", cellStyle: { "text-align": "center" } },
          { field: "descendantIndex", cellStyle: { "text-align": "center" } },
        ];
      }

      this.gridData = this.data
      this.pivot = false
    }
    else if (this.chartName == "NetworkChart") {
      this.columnDefs = [
        { field: "avg_days", headerName: 'Average Days', cellStyle: { "text-align": "center" } },
        { field: "lagging_patients", headerName: 'Lagging Patients', cellStyle: { "text-align": "center" } },
        { field: "node_1", headerName: 'Node 1', cellStyle: { "text-align": "center" } },
        { field: "node_2", headerName: 'Node 2', cellStyle: { "text-align": "center" } },
        { field: "node_3", headerName: 'Node 3', cellStyle: { "text-align": "center" } },
        { field: "node_4", headerName: 'Node 4', cellStyle: { "text-align": "center" } },
        { field: "node_5", headerName: 'Node 5', cellStyle: { "text-align": "center" } },
        { field: "node_6", headerName: 'Node 6', cellStyle: { "text-align": "center" } },
        { field: "patient_count", headerName: 'Patient Count', cellStyle: { "text-align": "center" } },
        { field: "process", headerName: 'Process', cellStyle: { "text-align": "center" } },
        { field: "seq_no", headerName: 'Sequence Number', cellStyle: { "text-align": "center" } },
      ];
      this.gridData = this.NetworkChartData
      this.pivot = false
    }
    else if (this.chartName == "ChoroplethChart") {
      this.columnDefs = [
        { field: "patient_id", headerName: 'Patient ID', cellStyle: { "text-align": "center" } },
        // { field: "prescriber_ID", headerName: 'Prescriber ID', cellStyle: { "text-align": "center" } },
        { field: "location_name", headerName: 'Location Name', cellStyle: { "text-align": "center" } },
        { field: "prescriber_address", headerName: 'Link Label', cellStyle: { "text-align": "center" } },
        { field: "state_cd", headerName: 'State Code', cellStyle: { "text-align": "center" } },
        { field: "zip_code", headerName: 'Zip Code', cellStyle: { "text-align": "center" } },
        { field: "pending_patient", headerName: 'Pending Patient', cellStyle: { "text-align": "center" } },
        { field: "lagging_patient", headerName: 'Lagging Patient', cellStyle: { "text-align": "center" } },
      ];
      this.gridData = this.choroDeta
      this.pivot = false
    } else if (this.chartName == "wordCloud") {
      this.columnDefs = [
        { headerName: 'Field Name', field: "field_name", cellStyle: { "text-align": "center" } },
        { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
      ];
      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "GroupHBar") {
      if (this.item.config.list_of_api == 100175) {
        this.columnDefs = [
          { headerName: 'File Type', field: "file_type_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Result type', field: "error_type_code", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Range', field: "range_nm", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      } else {
        this.columnDefs = [
          { headerName: 'Source Name', field: "data_source_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Result type', field: "error_type_code", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Range', field: "range_nm", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      }
      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "smallBar") {
      if (this.item.config.chart_type == "onebar") {
      
        this.columnDefs =this.item.config.list_of_api == 100609 ? [
          { headerName: 'Date', field: "period", cellStyle: { "text-align": "center" } },
          { headerName: 'Time to Therapy', field: "time_to_therapy", cellStyle: { "text-align": "center" } },
        ]:[
          { headerName: 'Field Name', field: "field_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      } else {
        this.columnDefs = [
          { headerName: 'Source Name', field: "data_source_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Result type', field: "error_type", cellStyle: { "text-align": "center" } },
          { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
        ];
      }
      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "areaLineChart") {
      this.columnDefs = [
        { headerName: 'Date', field: "period", valueFormatter: (params: any) => moment(params.value).format(val?.format || 'DD-MMM-YYYY'), cellStyle: { "text-align": "center" } },
        { headerName: 'No of Days', field: "no_of_days", cellStyle: { "text-align": "center" } },
        { headerName: 'Result Type', field: "error_type", cellStyle: { "text-align": "center" } },
        { headerName: 'Defect Count', field: "defect_count", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.numberPipe.transform(params.value) },
      ];
      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "slopeChartDiv") {

      //   let data :any=[]
      //    this.slopeChart.slopeFormattedData.forEach((element:any) => {
      //     element[1].forEach((val:any) => {
      //   data.push({'source_name' :element[0],'kpi_name':val.xVar,'kpi_value':val.yVal})
      // });
      //    });

      this.columnDefs = [
        { headerName: ' ', field: this.item.config.chart_type == 'scorecard' ? 'source_name' : "brand_name", cellStyle: { "text-align": "center" }, rowGroup: true },
        { field: 'kpi_name', pivot: true },
        {
          field: 'kpi_value', aggFunc: (params: any) => {
            let total: any = 0;
            let value: any = 0
            params.values.forEach((value: any) => total += value);
            if (typeof total != 'number') {
              if (params.values.length > 1) {
                total = total.slice(1);
                total = total.split('%')
                total.pop()
                total = total.forEach((v: any) => value += parseInt(v));
                total = (value / params.values.length) + '%'
              } else {
                total = total.slice(1);
              }
              return total;
            } else {

              return this.currency.transform(total, '', '', '1.0-2')
            }
          }, cellStyle: { "text-align": "center" }
        }
      ];

      this.gridData = this.item.config.list_of_api == "100195" ? this.data : this.slopeChart.slopeChartFormattedData
      this.pivot = true
      this.dateFormat = false
    } else if (this.chartName == "HBarChartDiv") {
     
      this.columnDefs = this.item.config.chart_type=='small'?[
        { headerName: 'Field Name', field: "field_name", cellStyle: { "text-align": "center" } },
        { headerName: 'This Account', field: "this_account", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2') },
        { headerName: 'Network', field: "network", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2') },
      ]:[
        { headerName: 'Brand Name ', field: "brand_name", cellStyle: { "text-align": "center" } },
        { headerName: 'Amount Per Record', field: "amount_per_record", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value) },
      ]
      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "BulletChartDiv") {
      if(this.item.config['chart_type']=='bullet'){
       
        this.columnDefs = [
          { headerName: 'Date ', field: "qtr_end_date", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => moment(params.value).format('DD MMM YYYY'), },
          { headerName: 'File Type', field: "file_type_name", cellStyle: { "text-align": "center" } },
          { headerName: 'Max Records', field: "max_records", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2') },
          { headerName: 'Current Records', field: "actual_records", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2') },
          { headerName: 'Buffer Records', field: "buffer_records", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2') },
          { headerName: 'Buffer percentage', field: "buffer_pct", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => params.value + "%" },

  
        ];
      }else{
      this.columnDefs = [
        { headerName: 'Date ', field: "qtr_end_date", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => moment(params.value).format('DD MMM YYYY'), },
        { headerName: 'File Type', field: "file_type", cellStyle: { "text-align": "center" } },
        { headerName: 'Target Payment', field: "max_payment", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) =>this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.value) },
        { headerName: 'Payment after corrections', field: "corrected_amount", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) =>this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.value) },
        { headerName: 'Delay Penalty', field: "delay_penalty", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) =>this.enhancementService.currentQuarterFlag?'-': params.value + "%" },
        { headerName: 'Payment Earned', field: "amount_payable", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) =>this.enhancementService.currentQuarterFlag?'-': this.currency.transform(params.value) },
        
      ];}

      this.gridData = this.data
      this.pivot = false
    } else if (this.chartName == "kpiBreakdownChartDiv") {
      this.columnDefs = [
        { headerName: 'Source Name', field: "source_name", cellStyle: { "text-align": "center" }, },
        { headerName: 'KPI Name', field: "kpi_name", cellStyle: { "text-align": "center" } },
        { headerName: 'Source Type', field: "source_type", cellStyle: { "text-align": "center" } },
        { headerName: 'KPI Value', field: "kpi_value", cellStyle: { "text-align": "center" }, valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2')},

      ];
      this.gridData = this.KpiHeatMap.data
      this.pivot = false
    }else if (this.chartName == "controlChartDiv") {
      
  this.columnDefs = [
        { headerName: 'Time Period', field: "period", cellStyle: { "text-align": "center" },valueFormatter: (params: any) => moment(params.value).format('DD MMM YYYY'), },
        { headerName: 'KPI Name', field: "kpi_nm", cellStyle: { "text-align": "center" }, },
        { headerName: 'Source Name', field: "source_name", cellStyle: { "text-align": "center" } },
        { headerName: 'KPI Value', field: "kpi_value", cellStyle: { "text-align": "center" } ,valueFormatter: (params: any) => this.currency.transform(params.value, '', '', '1.0-2')},

      ];
      this.gridData = this.controlChart.ControlChartData.data
      this.pivot = false
    }else if (this.chartName == "vennChartDiv") {

      this.columnDefs = [
            { headerName: 'Patient Id', field: "patient_id", cellStyle: { "text-align": "center" }, },
            { headerName: 'Service Type', field: "service_type", cellStyle: { "text-align": "center" } },
    
          ];
          // debugger
          this.gridData = this.data
          this.pivot = false
        }else if (this.chartName == "pieChartDiv") {
      
          this.columnDefs = [
                { headerName: 'Status', field: "category", cellStyle: { "text-align": "center" }, },
                { headerName: 'Value', field: "value", cellStyle: { "text-align": "center" } },
        
              ];
              // debugger
              this.gridData = this.data
              this.pivot = false
            }else if (this.chartName == "donutPieChartDiv") {
      
              this.columnDefs = [
                    { headerName: 'Payer Type ', field: "label", cellStyle: { "text-align": "center" }, },
                    { headerName: 'Sub Type', field: "type", cellStyle: { "text-align": "center" } },
                    { headerName: 'Value', field: "value", cellStyle: { "text-align": "center" } },
            
                  ];
                  // debugger
                  this.gridData = this.data
                  this.pivot = false
                }
               let  headerId:any=this.heading.replaceAll(' ', '')==''?'header':this.heading.replaceAll(' ', '')
      const element = document.querySelector('#'+headerId);
      if (element) {
        // console.log(element.textContent); // Output the text content to console
        this.SubHeading =element.textContent
      }else{
        this.SubHeading = ' '
      }
    this.dialogService.openDialog(this.prompt)
  }
  controlChartPopup() {
    this.columnDefs=[]
    this.gridData=[]
    this.clicked=false
    let postData: any = { report_typ: "D", api_key: "1001851"};
    //  api_key: "10018503" generalapi key
    if(this.filterService?.dragDate?.length>0){
      postData['from_date']=moment(this.filterService.dragDate[0]).format('yyyy-MM-DD')
      postData['to_date']=moment(this.filterService.dragDate[1]).format('yyyy-MM-DD')
    }
    this.dialogService.openDialog(this.controlPopup)
    this.reqSubcription.push(this.filterService.dragexecuteQuery(postData).subscribe(
      (res: any) => {
        if(res.length==0){ this.columnDefs=[], this.gridData=[] ,this.clicked=true;return}
      let coldef= Object.keys(res[0])
      coldef.forEach((element:any) => {
        this.columnDefs.push( { headerName: element.toUpperCase().replaceAll('_',' '), field: element, cellStyle: { "text-align": "center" } })
      });
      this.columnDefs=[...new Set(this.columnDefs)]
      this.clicked=true
      this.gridData=res
      this.pivot = false
      this.pagination = true
      },
      (error: any) => {
        this.columnDefs=[]
        this.gridData=[]
        this.clicked=true
      this.dialogService.openSnackBar("Transaction Details API Failing","OK")
      }
    ))


  }

  // Export to excel  for all chart
  excelExport() {
    var columns: any
    if (this.chartName == "lineChart") {
      this.excelService.customExportExcel(this.data, this.item.config["list_of_api"].api_key == 100133 ? this.filterService.baseQuery.source_nm + "-" + this.item.config['excel_heading'] : this.item.config['excel_heading'], this.item.config.excel_column)
    }else if (this.chartName == "barChart") {
      this.data = this.data[0]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], this.item.config.excel_column)
    } 
     else if (this.chartName == "clusterBarChart") {
      let colDef: any = [
        {
          "header": "Date",
          "key": "period",
          "type": "date",
        },
        {
          "header": "Source Name",
          "key": "source_name",
          "type": "string",
        },
        // {
        //   "header": "Location Name",
        //   "key": "sp_location_name",
        //   "type": "string",
        // },
        // {
        //   "header": "NDC",
        //   "key": "ndc_product_form",
        //   "type": "string",
        // },
        {
          "header": "Dispense Quantity",
          "key": "dispense_qty",
          "type": "number",
        },
        {
          "header": "Inventory Quantity",
          "key": "inventory_qty",
          "type": "number",
        },
              {
          "header": "Difference",
          "key": "difference",
          "type": "number",
        },
        {
          "header": "Inventory On Hand",
          "key": "inventory_on_hand",
          "type": "number",
        },
      ]
      this.excelService.customExportExcel(this.data, this.filterService.baseQuery.source_nm + "-"+ this.item.config['report_heading'], colDef)
        }else if (this.chartName == "IRSummery") {
          let colDef: any = [
            {
              "header": "Date",
              "key": "period",
              "type": "date",
            },
            {
              "header": "Source Name",
              "key": "source_name",
              "type": "string",
            },         
            {
              "header": "Dispense Quantity",
              "key": "dispense_qty",
              "type": "number",
            },
            {
              "header": "Inventory Quantity",
              "key": "inventory_qty",
              "type": "number",
            },
                  {
              "header": "Difference",
              "key": "qty_difference",
              "type": "number",
            },
            {
              "header": "Difference Percentage",
              "key": "pct_difference",
              "type": "percent",
            },
            {
              "header": "Inventory On Hand",
              "key": "inventory_on_hand",
              "type": "number",
            },
          ]
          this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
            } else if (this.chartName == "bubbleMapDiv") {
      this.data = this.data
      this.item.config.excel_column = [
        {
          "header": "Shipment Date",
          "type": "date",
          "key": "period"
        },
        {
          "header": "Source Name",
          "type": "string",
          "key": "sourcename"
        },
        {
          "header": "Pharmacy Location Name",
          "type": "string",
          "key": "locationname"
        },
        // {
        //     "header": "Quantity Shipped(Tablets)",
        //     "type": "string",
        //     "key": "quantityshipped"
        // },
        {
          "header": "Quantity Shipped",
          "key": "number_of_bottles",
          "type": "number"
        }
      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], this.item.config.excel_column)
    }
    else if (this.chartName == 'GanttChart') {
      this.excelService.exportAsExcelFile(this.data, this.item.config['report_heading'])

    }
    else if (this.chartName == 'NetworkChart') {
      this.excelService.exportAsExcelFile(this.NetworkChartData, this.item.config['report_heading'])

    }
    else if (this.chartName == 'ChoroplethChart') {
      this.excelService.exportAsExcelFile(this.choroDeta, this.item.config['report_heading'])

    }
    else if (this.chartName == 'comboBar') {
      let colDef: any
      if (this.item.config.chart_type.value == "group") {
        colDef = [
          {
            "header": "Time Period",
            "key": "period",
            "type": "date",
          },
          {
            "header": "Source Name",
            "key": "data_source_name",
            "type": "string",
          },
          {
            "header": "Shipped Patient",
            "key": "shipped_patients",
            "type": "string",
          },
          // {
          //   "header": "Quantity Shipped(Tablets)",
          //   "key": "shipped_quantity",
          //   "type": "string",
          // },
          {
            "header": "Quantity Shipped",
            'key': "no_of_bottles",
            "type": "number"
          }
        ]
      } else {
        colDef = [
          {
            "header": "Time Period",
            "key": "period",
            "type": "date",
          },
          {
            "header": "Shipments",
            "key": "shipments",
            "type": "string",
          },
          // {
          //   "header": "Quantity Shipped(Tablets)",
          //   "key": "shipped_quantity",
          //   "type": "string",
          // },
          {
            "header": "Quantity Shipped",
            'key': "no_of_bottles",
            "type": "number"
          }
        ]
      }
      this.excelService.customExportExcel(this.comboBar.data.lineData, this.item.config['report_heading'], colDef)
      // this.excelService.exportAsExcelFile(this.comboBar.data.lineData, this.item.config['report_heading'])
      // this.excelService.customExportExcel(this.comboBar.data.lineData, this.item.config['report_heading'], colDef)
    }
    else if (this.chartName == "wordCloud") {

      let colDef: any = [
        {
          "header": "Field Name",
          "key": "field_name",
          "type": "string",
        },
        {
          "header": "Defect Count",
          "key": "defect_count",
          "type": "string",
        },

      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)

    } else if (this.chartName == "GroupHBar") {
      let colDef: any = [
        {
          "header": "	Source Name",
          "key": "data_source_name",
          "type": "string",
        },
        {
          "header": "Result type",
          "key": "error_type_code",
          "type": "string",
        },
        {
          "header": "Defect Range",
          "key": "range_nm",
          "type": "string",
        },
        {
          "header": "Defect Count",
          "key": "defect_count",
          "type": "string",
        },
        {
          "header": "Result type description",
          "key": "dv_error_type_desc",
          "type": "string",
        }
      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    } else if (this.chartName == "smallBar") {
      let colDef: any
      if (this.item.config.chart_type == "onebar") {
        
        colDef = this.item.config.list_of_api == 100609 ?[
          {
            "header": "Date",
            "key": "period",
            "type": "date",
          },
          {
            "header": "Time to Therapy",
            "key": "time_to_therapy",
            "type": "string",
          }, ]:[
          {
            "header": "Field Name",
            "key": "field_name",
            "type": "string",
          },
          {
            "header": "Defect Count",
            "key": "defect_count",
            "type": "string",
          },
        ]
      } else {
        colDef = [
          {
            "header": "Source Name",
            "key": "data_source_name",
            "type": "string",
          },
          {
            "header": "Result type",
            "key": "error_type",
            "type": "string",
          },
          {
            "header": "Defect Count",
            "key": "defect_count",
            "type": "string",
          },
        ]
      }
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    } else if (this.chartName == "areaLineChart") {
      let colDef: any = [
        {
          "header": "Defect received date",
          "key": "period",
          "type": "date",
        },
        {
          "header": "Result type",
          "key": "error_type",
          "type": "string",
        },
        {
          "header": "Defect Count",
          "key": "defect_count",
          "type": "string",
        },
      ]

      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    } else if (this.chartName == "areaLineChart") {
      let colDef: any = [
        {
          "header": "Defect received date",
          "key": "date",
          "type": "string",
        },
        {
          "header": "Result type",
          "key": "error_type",
          "type": "string",
        },
        {
          "header": "Defect Count",
          "key": "defect_count",
          "type": "string",
        },
        {
          "header": "Response time(In days)",
          "key": "no_of_days",
          "type": "string",
        },

      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    } else if (this.chartName == "slopeChartDiv") {

      let colDef: any = [
        {
          "header": "Time period",
          "key": " period",
          "type": "date",
        },
        {
          "header": "Source Name",
          "key": "source_name",
          "type": "string",
        },
        {
          "header": "KPI Value",
          "key": "kpi_value",
          "type": "string",
        },
        {
          "header": "KPI Name",
          "key": "kpi_name",
          "type": "string",
        },
        {
          "header": "KPI Type",
          "key": "kpi_type",
          "type": "string",
        },

      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    } else if (this.chartName == "GridList") {
      let colDef: any
      if (this.item.config?.list_of_api?.api_key == 100200) {
        colDef = [
          {
            "header": "Field Name",
            "key": "business_column_name",
            "type": "string",
          },
          {
            "header": "DV Check Description",
            "key": "dv_description",
            "type": "string",
          },
          // {
          //   "header": "Applicable Source",
          //   "key": "source_name",
          //   "type": "string",
          // },
          {
            "header": "DV Check Result type",
            "key": "dv_error_type",
            "type": "string",
          },
          {
            "header": "DV Check Sequence",
            "key": "dv_check_sequence",
            "type": "string",
          },
          // {
          //   "header": "DV Check Error Message",
          //   "key": "dv_error_message",
          //   "type": "string",
          // }                
        ]
        this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
      } else {
        this.exportXls.emit(true)

        // this.filterService.exportXls.next(true);
        // this.excelService.exportAsExcelFile(this.data, this.item.config['report_heading'])
      }
    }
    else if (this.chartName == 'barGridDiv') {
      this.excelService.exportAsExcelFile(this.data, this.sourceName ? (this.filterService.baseQuery.source_nm + " " + "Location" + " " + this.item.config['report_heading']) : this.filterService.baseQuery.source_nm + " " + this.item.config['report_heading'])

    } else if (this.chartName == "HBarChartDiv") {
      let colDef: any = this.columnDefs = this.item.config.chart_type=='small'?[
        { header: 'Field Name', key: "field_name", type: "string" },
        { header: 'This Account', key: "this_account", type: "number" },
        { header: 'Network', key: "network", type: "number" },
      ]: [
        {
          "header": "Brand Name",
          "key": "brand_name",
          "type": "string",
        },
        {
          "header": "Amount Per Record",
          "key": "amount_per_record",
          "type": "currency",
        },

      ]
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    }
    else if (this.chartName == 'pivotGrid') {
      this.pivotGrid.exportXls();

    }
    else if (this.chartName == "BulletChartDiv") {
      let colDef: any
      if(this.item.config['chart_type']=='bullet'){
        colDef = [ 
          { header: 'Date ', key: "qtr_end_date", "type": "date", },
          { header: 'File Type', key: "file_type_name", "type": "string",},
          { header: 'Max Records', key: "max_records", "type": "number",},
          { header: 'Current Records', key: "actual_records", "type": "number", },
          { header: 'Buffer Records', key: "buffer_records", "type": "number", },
          { header: 'Buffer percentage', key: "buffer_pct", "type": "percent", }]
      }else{
      colDef = [
        {
          "header": "File Type",
          "key": "file_type",
          "type": "string",
        },
        {
          "header": "Target Payment",
          "key": "max_payment",
          "type":  this.enhancementService.currentQuarterFlag?'masking': "currency",
        },
        {
          "header": "Payment after corrections",
          "key": "corrected_amount",
          "type":   this.enhancementService.currentQuarterFlag?'masking':"currency",
        },
        {
          "header": "Delay Penalty",
          "key": "delay_penalty",
          "type":  this.enhancementService.currentQuarterFlag?'masking': "percent",
        },
        {
          "header": "Payment Earned",
          "key": "amount_payable",
          "type":  this.enhancementService.currentQuarterFlag?'masking': "currency",
        },


      ]
    }
    // debugger
      this.excelService.customExportExcel(this.data, this.item.config['report_heading'], colDef)
    }

    else if (this.chartName == 'statusCodesUI') {
      this.statusCodeRef.exportXls();

    }
    else if (this.chartName == "PaymentGrid") {

      this.paymentGrid.exportToExcel()
    } else if (this.chartName == "BasicHeatMapChartDiv") {
      
      let colDef: any =[]
      this.data.columnDetails.forEach((element:any) => {
        colDef.push({
          "header": element.field_display_nm,
          "key": element.field_nm,
          "type": "percentage",
        })

      });
      const columnDef = this.getUniqueListBy(colDef, 'key')

      this.excelService.customExportExcel(this.data.data, this.item.config['report_heading'], columnDef)
      // this.excelService.exportAsExcelFile(this.data.data, this.item.config['report_heading'])
    } else if (this.chartName == "controlChartDiv") {
      this.excelService.exportAsExcelFile(this.controlChart.ControlChartData.data, 'Controlchart')
    } else if (this.chartName == "TerritoryDiv") {
      // let jsonData: any
      // this.apiService.getDoc("territoryMap-data").subscribe((data: any) => {
      //   jsonData = data;
        this.excelService.exportAsExcelFile(this.data, this.item.config['report_heading'])
      // });

    }
    else {
      this.excelService.exportAsExcelFile(this.data, this.item.config['report_heading'])
      // this.Csvexport.downloadFile(this.data, columns, header, this.item.config['report_heading'])
      // this.excelService.customExportExcel(this.data, this.item.config['report_heading'], columns)
    }
  }

  @Output() expendCol = new EventEmitter();


  expandCollapse() {
    // this.dataService.expandCollapseChart.next(!this.expandChart)
    this.expandChart = !this.expandChart
    this.expendCol.emit(this.expandChart)
  }
  pivotexpandCollapse() {
    if (this.expandCollapseIcon == "collapse_new") {
      this.expandCollapseIcon = "gridload_circle"
      this.expandOrCollapse = "Expand All"
      this.pivotGrid.gridApi.collapseAll()

    } else {
      this.expandCollapseIcon = "collapse_new"
      this.expandOrCollapse = "Collapse All"
      this.pivotGrid.gridApi.expandAll();
    }
  }
  getUniqueListBy(arr: any, key: any) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()]
  }

  isPHIFunc(event: any) {
    this.phiFromGrid.emit(event.checked)
    // this.filterService.isPHIFunc.next(event.checked)
  }

  onQuickFilterChanged(event: any) {
    this.typeToSearch.emit(event.target.value)
  }

  paginationPageSize = 10;

  onPageSizeChanged() {
    this.onPageSizeChange.emit(this.paginationPageSize)
  }

  sortByChange(event: any) {
    this.filterService.sortByChanged.next(event)
  }

  changeDisplayType(selectedType: any) {
    this.selectedType = selectedType
    this.filterService.changeDisplays.next(selectedType);
  }

  increment() {
    if (this.counter >= 9) {
      this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )","OK")
      return
    } else {
      if (this.counter == '') {
        this.counter = 2
        return
      }
      this.counter++;
    }
  }
  decrement() {
    if (this.counter < 3) {
      this.counter = 2
      this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )","OK")
      return
    } else {
      this.counter--;
    }
  }

  numberOnly(event: any): boolean {

    if (!this.clickedevent) {
      this.clickedevent = true;
      setTimeout(() => {
      this.clickedevent = false;
      }, 300);
        const charCode = (event?.which) ? event.which : event.keyCode;
        if(event.code=="NumpadDecimal" || event.code=="Quote" || event.code=="Period" ){ this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )", "OK") ;return false}
        if (charCode == 8 || charCode == 46 || charCode == 37 || charCode == 39 || charCode == 38 || charCode == 40) { return true }
        if ((charCode < 113 && charCode > 96) || charCode == 110|| charCode == 39 ) { this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )", "OK"); return false }
        if (charCode > 31 && (charCode < 50 || charCode > 57) && (charCode < 97 || charCode > 105)) {
            this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )", "OK")
            return false;
          } else {return true;}
    } else {
      return false
    }
  }

  findFromtheFilter(filterKey:any) {
    
    return ''
  }

  countSave(ctrl: any) {

    if (this.counter != '') {
      this.selectedCount = this.counter
      this.filterService.movingAverage.next(this.counter);
      document.getElementById("moving_average")?.click()
    } else {
      this.counter = 2
      this.dialogService.openSnackBar("Moving Average Limit ( 2 - 9 )","OK")
    }
  }

  // timeCycleUserStatue(){
  //   let userStateDate: any = this.userActivityService.getData();
  //   let fnded = this.headerConfig.find((fnd:any)=> fnd.add_header ==true && fnd.time_cycle && fnd.value == "time_cycle")
  //   

  //   if(userStateDate) {
  //     if(userStateDate.selectedType) {
  //       if(fnded) {
  //         let isTimeCycle = fnded.timeValues.find((re:any)=> re.value == userStateDate.selectedType)
  //         if(isTimeCycle) {
  //           this.selectedTypeTimeCyle = userStateDate.selectedType
  //           this.filterService.changeTimeCycle.next(this.selectedTypeTimeCyle)
  //         }
  //       }
  //     }
  //   }
  // }

  changeTimeCycleEvent(event:any,selectedType: any) {
    event.preventDefault();
    event.stopPropagation()
    if(this.selectedTypeTimeCyle==selectedType)return
    this.userActivityService.setData({ "selectedType": selectedType })
    this.timeCycleChanges.emit(selectedType);
    // this.filterService.changeTimeCycle.next(selectedType)
  }

  followUP() {
    this.filterService.followUP.next(true);
  }

  testIcon() {
    alert('No Icon Event')
  }
  numbedPipe(value: any) {
    if (value == null || undefined) return
    if (typeof value == 'number') {
      return this.currency.transform(value, '', '', '1.0-2');
    }
    if (value.includes('%')) return value
    if (value.includes(',')) {
      let val = value.replaceAll(/,/g, "");
      return this.currency.transform(val, '', '', '1.0-2');
    }
  }

  findWhichKey(key:any, value:any) {
    if(!this.filterService.previousSingleSelectedItems?.[key]) return '';
    let queryFilter = Object.entries(this.filterService.previousSingleSelectedItems[key]).map(([key, value]) => ({ key, value }));
    let ifQuery: any = queryFilter.find((fnd: any) => fnd.key == value?.valueName)

    return ifQuery.value || '.'
  }

  findPrefixDateFormat(data:any) {
    if(!this.filterService.baseQuery?.[data.sp_nm_visibility_key]) return '';
    if(data?.date_format_prefix == 'custom') return moment(this.filterService.baseQuery?.[data.sp_nm_visibility_key], 'YYYY-MM-DD').format(data.custom_date_format_prefix);
    return moment(this.filterService.baseQuery?.[data.sp_nm_visibility_key], 'YYYY-MM-DD').format(data.date_format_prefix) + ', ';
  }
  
  findSuffixDateFormat(data:any) {
    if(!this.filterService.baseQuery?.[data.spb_nm_visibility_key]) return '';
    if(data?.date_format_suffix == 'custom') return moment(this.filterService.baseQuery?.[data.spb_nm_visibility_key], 'YYYY-MM-DD').format(data.custom_date_format_suffix)
    return ' - ' + moment(this.filterService.baseQuery?.[data.spb_nm_visibility_key], 'YYYY-MM-DD').format(data.date_format_suffix);
  }

  numberminMax(event:any){
    
    if(this.buffer_range>=0 && this.buffer_range<=100){
      this.buffer_range=this.buffer_range
    }else{
      this.buffer_range=null
      this.buffer_range=0
      alert('Buffer Range  ( 1 - 100)')

    }
  }
  checkLength2(e: any, input: any) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];

    this.numberminMax(e)
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 100 || newValue.length > 3) {
      alert('Buffer Range  ( 1 - 100)')
      e.preventDefault();
    }
  }
  replaceSelection(input: any, key: any) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }
  restictnumberneg(e: any, input: any) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];


    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (newValue.length > 120) {
      e.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.filterService.showByHeader='false'
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
  getPeriodtext(){
    switch (this.filterService.report_type) {
      case 'D': return "Days"
      case 'W': return "Weeks"
      case 'M': return "Months"
      case 'Q': return "Quarters"
      case 'Y': return "Years"
    }
  }
  tooltipForShowBy(data:any,value:any):string{
   let label:string = data.find((fnd: any) => fnd.value == value).title
    return label

  }
}
