
  export const environment = {
    production: true,
    configType: false,
    topAlertSetting: false,
    logoUrl:'https://www.dataunveil.com/assets/img/logo.png',
    apiBaseUrl:'https://prod2-api.scraii.com',
    widgetConfigEndPoint : 'kpi/get-widget-details-by-id',
    apiListEndPoint:'kpi/get-widget-details',
    apiColumnListEndPoint: 'kpi/get-list-of-value',
    getListOfWork:'kpi/get-list-of-work',
    getWorkDetails : 'kpi/get-work-details-by-id',
    addNewWorkList: 'kpi/insert-work-layout',
    infoIconText:"FRMPortal/get-info-text",
    pageAddEditEndPoint: 'kpi/insert-page-details',
    pageListEndPoint: 'kpi/get-page-list',
    ConfigTypeEndPoint: "kpi/get-config-type",
    EnvironmentEndPoint:"kpi/get-environment-type",
    patientListFRM: 'FRMPortal/get-patient-list',
    getCycleData : "kpi/get-execution-details",
     AMPLIFY: {
      region: "us-east-2",
      userPoolId: "us-east-2_IBRNnm00y",
      authenticationFlowType: "USER_PASSWORD_AUTH",
      userPoolWebClientId: "2oubd6j8aae431u2disld0fnge",
  
      getCycleData : "kpi/get-execution-details",
      
    },
    getUserInfo : "api/get-user-info",
    getBrandDate : "KPI/get-brand-date",
    MixPanelToken:"7278d47b389953115b5b55ab14a83cbb",
    MixPanelTrack:true
  };
  