import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-widget-card1kpi',
  templateUrl: './card1kpi.component.html',
  styleUrls: ['./card1kpi.component.scss']
})
export class Widget1kpiCardComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  static key = 100101;
  @Input('config') config: any = {}
  @Input('item') item: any;
  @Input('pageKey') pageKey: any;
  kpi_1_value: any;
  reqSubcription: Subscription[] = [];
  isOpen: boolean = false;
  infoData: any
  loader: boolean = true;
  cardName: any;
  cardId: any
  presId: any;

  obj: any = {
    header_alignment: {
      name: "BOTTOM",
      value: "bottom"
    }
  }

  constructor(private filterService: FilterService,
    private dataService: DataService,
    private currency: CurrencyPipe,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData();
    }))
  }

  ngOnInit(): void {

    // this.route.params.subscribe((p: any) => {
    //   let clientKey = p["clientKey"];
    //   let moduleKey = p["moduleKey"];
    //   let dashboardKey = p["dashboardKey"];
    //   this.cardName = p["cardName"];
    //   this.cardId = p["cardId"];


    // if(this.cardName == 'prCard') {
    //   this.presId = this.cardId 
    // } else if (this.cardName=='pwt') {
    //   this.route.queryParams.subscribe((params:any) => {
    //     if(this.cardId == 'prCard') {
    //       this.presId = params.presId 
    //     }
    //   })
    // }
    // })

    if (!this.item.config.header_alignment) {
      Object.assign(this.item.config, this.obj)
    }

    this.item.config.integer_format.value

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.item) {
      if (!this.item.config['icon'])
        this.item.config['icon'] = { name: 'question_circle' }
    }
  }

  getInfoDetail() {
    let data = {
      api_key: this.item.config.kpi_1_value_api?.api_key || 0,
      widget_key: this.item.config.widgetKey,
      page_key: this.pageKey,
      report_typ: 'D',
      brand_key: this.filterService.baseQuery?.brand_key
    }
    this.reqSubcription.push(this.dataService.getInfoDetail(data).subscribe((res: any) => {
      this.infoData = res && res[0] ? res[0] : { info_header: "No Info Data Found" }
    }))
    return this.isOpen = !this.isOpen
  }

  getStyle(): string {
    return 'background-color:#9900ff'
  }
  getValue(item: any, value: any) {
    return item ? item[value] : ''
  }
  numbedPipe(value: any) {
    if (value == 'NA') return value
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }

  isPortrait = false
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortrait = window.innerWidth < window.innerHeight;
    if (this.isPortrait) {
    } else {
    }
  }

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger.closeMenu();
  }

  ngAfterViewInit() {
    this.checkOrientation()
  }

  cancel() {
    document.getElementById("setting")?.click()
  }

  loadApiData() {
    this.loader = true;
    let kpi1 = this.item.config['kpi_1_value_api']

    if (kpi1.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj: any = { "api_key": kpi1.api_key } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          if (res && res.length > 0) {
            let selectedColumn = this.item.config['kpi_1_value_api_col']['api_resp_column_nm']
            this.kpi_1_value = res[0][selectedColumn]
          } else {
            this.kpi_1_value = ""
          }
          this.loader = false;
        }, (err: any) => {
          // if(err.status==200) {
          // }
          console.log('kpi1', err)
          this.loader = false
          this.kpi_1_value = ""
        })
      )

      // this.reqSubcription.push(this.filterService.executeQuery(obj).subscribe((res:any)=>{
      //     if (res && res.length>0) {
      //       let selectedColumn = this.item.config['kpi_1_value_api_col']['api_resp_column_nm']
      //       this.kpi_1_value = res[0][selectedColumn]
      //     } else {
      //       this.kpi_1_value = ""
      //     }
      //     this.loader=false;
      //   }, (err: any) =>{
      //     // if(err.status==200) {
      //     // }
      //     console.log('kpi1',err)
      //     this.loader=false
      //     this.kpi_1_value = ""
      //   })
      // )
    }
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }
}
