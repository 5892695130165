import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';;
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { DeviceDetectionService } from 'src/app/services/detectipad.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { FollowupPopupComponent } from '../follow-up-popup/follow-up-popup.component';

@Component({
  selector: 'app-patients-follow-up',
  template: `
  <style>
    .createFollowUp:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .createFollowUp:hover {
      outline: none;
      box-shadow: none;
      color: #fff !important;
      border: 2px solid #1363DF !important;
      background-color: #1363DF !important;
    }
    .createFollowUp {
      color: #1363DF !important;
      border: 2px solid #1363DF !important;
      background-color: #fff !important;
    }

    ::ng-deep .example-tooltip-Follow {
      padding: 0 5px !important;
      margin: 0 !important;
      position: relative !important;
      background-color: rgb(0, 0, 0, 0.7) !important;
      top: 5px !important;
      line-height: 14px;
      /* text-wrap: nowrap !important; */
      user-select: none !important;
      pointer-events: none;
    }
  </style>
  
  <span *ngIf="!this.params.data['follow_up_reason']">
    <mat-icon [svgIcon]="plus_grid_icon" (click)="createFollowUp(true)"
      matTooltip="Create follow-up" [matTooltipPosition]="'above'" matTooltipClass="example-tooltip-Follow"
         style="width: 14px;height: 14px;cursor: pointer;margin-left: 10px;overflow: visible;"></mat-icon>
    <!-- <button type="button" style="font-size: 12px;width: 150px;" class="btn btn-outline-primary createFollowUp" (click)="createFollowUp(true)">
    <span>+</span> &nbsp;
    <span>Create follow-up</span>
    </button> -->
  </span>
  
  <span *ngIf="this.params.data['follow_up_reason']">
    <div *ngFor="let follow of this.followUpData;let i=index;" style="display: flex;justify-content: space-between;">
      
      <!-- Reason -->
      <span>{{ follow.follow_up_reason}}</span>

      <!-- Edit Icon -->
      <span>
        <mat-icon [svgIcon]="editGridIcon" *ngIf="follow.highlight_flg!='Y'" (click)="createFollowUp(false, follow)" style="width: 14px;height: 14px;cursor: pointer;margin: 0 10px;overflow: visible;">edit</mat-icon>
  
        <!-- Flag Icon false -->
        <span *ngIf="!follow.highlight_flg || follow.highlight_flg=='N'">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33301 2.33301V12.333" stroke="#8A98AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6663 9.65217C5.99967 12.0145 4.42467 7.28083 1.33301 9.65217C1.33301 7.21843 1.33301 4.70366 1.33301 2.26992C4.25599 0.0279564 6.33301 4.95437 10.6663 2.26991C10.6663 4.72446 10.6663 7.19763 10.6663 9.65217Z" stroke="#8A98AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
  
        <!-- Flag Icon true -->
        <span *ngIf="follow.highlight_flg=='Y'">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33301 2.33301V12.333" stroke="#3AC97C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6663 9.65217C5.99967 12.0145 4.42467 7.28083 1.33301 9.65217C1.33301 7.21843 1.33301 4.70366 1.33301 2.26992C4.25599 0.0279564 6.33301 4.95437 10.6663 2.26991C10.6663 4.72446 10.6663 7.19763 10.6663 9.65217Z" fill="#3AC97C" stroke="#3AC97C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </span>

    </div>  
    <mat-icon [svgIcon]="plus_grid_icon"
      matTooltip="Create follow-up" [matTooltipPosition]="'above'" matTooltipClass="example-tooltip-Follow"
     (click)="createFollowUp(true)" style="width: 14px;height: 14px;cursor: pointer;margin-left: 10px;overflow: visible;"></mat-icon>
  </span>
  
  `
})
export class PatientsFollowUpComponent implements ICellRendererAngularComp {
  
  // Variables
  params!: ICellRendererParams | any;
  followUpData:any=[];
  editGridIcon = "edit_grid_icon";
  plus_grid_icon = "plus_grid_icon";

  constructor(
    private dialog: MatDialog,
    public filterService: FilterService
  ) {}
  
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams<any>): void {
    this.params = params;
    this.followUpData = this.params['data']?.follow_up_reason ? JSON.parse(this.params['data']?.follow_up_reason) : [];
  }

  createFollowUp(condition: boolean, data?:any) {
    let dialogRef = this.dialog.open(FollowupPopupComponent, {
      width: "450",
      disableClose: true,
      panelClass: "du-custom-modalbox",
      data: {params: this.params, toBeShow: condition, data : data}
    });

    dialogRef.afterClosed().subscribe((value) => {
      if(value) {
        this.params.data['follow_up_reason'] = JSON.stringify(value);
        this.filterService.flagUpdateOnscreen.next(true)
      }
    });
  }
}