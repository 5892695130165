import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-pipeline-update-popup',
  templateUrl: './pipeline-update-popup.component.html',
  styleUrls: ['./pipeline-update-popup.component.scss']
})
export class PipelineUpdatePopupComponent implements OnInit {
  @Input("updatesObj") updatesObj: any
  @Input("NetworkChartTooltip") NetworkChartTooltip: boolean = false
  @Output() hideUpdateTooltip = new EventEmitter();
  @Output() refreshNetworkChart = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter();
  UpdateObjLength: any = 0;
  refreshToolTipData: any
  status_tick = "status_tick"
  status_pending = "status"
  trash = "trash_delete"
  deletes = "delete"
  cancel_x = "cancel_x"
  cardName: any
  cardId: any
  state: any
  @ViewChild('DeleteDialog') DeleteDialog: any;
  @ViewChild('DeleteDialog1') DeleteDialog1: any;
  patientID: any;
  update: any
  idx: any
  length: any
  cancelClicked = false;


  constructor(
    public filterService: FilterService, 
    public route: ActivatedRoute, 
    public dialog: MatDialog,
    public navigationService: NavigationService
  ) { }

  DelDialog(enterAnimationDuration: string, exitAnimationDuration: string, update: any, idx: any, length: any): void {
    this.update = update
    this.idx = idx;
    this.length = length
    this.dialog.open(this.DeleteDialog, {
      width: 'auto',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }


  DelDialog1(enterAnimationDuration: string, exitAnimationDuration: string, update: any, idx: any, length: any): void {
    this.update = update
    this.idx = idx;
    this.length = length

    this.dialog.open(this.DeleteDialog1, {
      width: 'auto',
      enterAnimationDuration,
      exitAnimationDuration,
    });

  }

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);

        this.patientID = decryptedParams["cardId"];
        this.cardId = decryptedParams["cardId"];
      });
    });

    // this.route.params.subscribe((p: any) => {
    //   this.cardName = p["cardName"];
    //   this.cardId = p["cardId"];

    //   if (this.cardName == 'State') {
    //     this.state = this.cardId
    //   }
    // })

    // this.route.params.subscribe((r: any) => {
    //   if (this.cardName == "paCard") {
    //     this.patientID = this.cardId
    //   } else if (this.cardName == "pwt") {
    //     this.route.queryParams.subscribe((params: any) => {
    //       this.patientID = params.paId
    //     })
    //   }
    // })

    if (!this.NetworkChartTooltip) {

      if (this.filterService?.toolTipAlertData?.Alert_Type == "Status Changes") {
        this.updatesObj = this.filterService.toolTipAlertData.Data.sort((a: any, b: any) => {
          const date1 = new Date(b.alert_dt);
          const date2 = new Date(a.alert_dt);

          return date1.getTime() - date2.getTime();
        })
      } else if (this.filterService.toolTipAlertData.Alert_Type != "Status Changes") {

        let GridfilterData: any

        if (this.filterService.toolTipAlertData.col_Config) {
          GridfilterData = this.filterService.toolTipAlertData.col_Config.filter((flr: any) => flr.isConfigSelect == true)
          GridfilterData.sort(function (a: any, b: any) {
            return a.arrayIndex - b.arrayIndex;
          });
        }

        let data = this.filterService.toolTipAlertData.Data
        this.updatesObj = []
        // Object into Array of Object
        data.forEach((res: any) => {
          let upd: any = Object.entries(res).map(([key, value]) => ({ key, value }));

          // remove last Object as alert_dt
          upd = upd.slice(0, -1)

          // concat with patient id to orginal data 
          let b = {
            "key": "Patient ID",
            "value": this.filterService.toolTipAlertData.Patient_key || this.filterService.toolTipAlertData.id
          }
          upd = upd.concat(b)

          // position change
          upd.forEach((item: any, i: any) => {
            if (item.key == "Patient ID") {
              upd.splice(i, 1);
              upd.unshift(item);
            }
          });

          this.updatesObj.push(upd)
        })
        if (GridfilterData) {
          for (let i = 0; i < this.updatesObj.length; i++) {
            let newUpd: any[] = []
            GridfilterData.forEach((element: any) => {
              let ab = this.updatesObj[i].find((fnd: any) => element.col_name.replaceAll('_', ' ').toLowerCase() == fnd.key.replaceAll('_', ' ').toLowerCase())

              let obj: any = {
                key: element.col_name,
                value: ab ? ab.value : ''
              }
              newUpd.push(obj)
            })
            this.updatesObj[i] = newUpd
          }
        }

      }
    }
    else if (this.NetworkChartTooltip) {
      this.UpdateObjLength = this.updatesObj?.length
      //  Network Chart Data
    }
  }

  hideTooltip() {
    if (this.UpdateObjLength != this.updatesObj.length) {
      this.refreshNetworkChart.emit(true)
      this.hideUpdateTooltip.emit(false);
    } else {
      this.hideUpdateTooltip.emit(false);
    }
  }

  funcTest() {
    this.filterService.hideTools.next(true)
    if (this.updatesObj.length != this.filterService.toolTipAlertData.Count) {
      this.filterService.refreshAlertDelete.next(true)
      this.filterService.refreshAlertDeleteDups.next(true)
    }
  }


  confirmClicked(update: any, idx: any, length: any) {
    // let api_key = 100135

    let query:any = {}
    query["api_key"] = '100135';
    query["report_typ"] = 'D';
    query["Alert_key"] = this.filterService.toolTipAlertData.Alert_key || undefined
    query["alert_dt"] = update.alert_dt || this.filterService.toolTipAlertData.Data[idx].alert_dt || undefined
    query["patient_id"] = this.filterService.toolTipAlertData.Patient_key || this.filterService.toolTipAlertData.id || undefined
    query["prescriber_id"] = this.filterService.locationPresId || undefined
    query["hcp_state"] = this.state || undefined
    query["activity_since_days"] = this.filterService.sliderValue || undefined;

    this.filterService.frmRemoveAlerts(query).subscribe((res: any) => {
      if (res.length == 0) {
        if (this.updatesObj.length > 1) {
          this.updatesObj.splice(idx, 1);
          this.valueChange.emit(query.alert_dt);
        } else {
          this.filterService.refreshAlertDelete.next(true)
          this.filterService.refreshAlertDeleteDups.next(true)
        }
      }
    }, err => {
      if (err.status) {
        if (this.updatesObj.length > 1) {
          this.updatesObj.splice(idx, 1);
          this.valueChange.emit(query.alert_dt);
        } else {
          this.filterService.refreshAlertDelete.next(true)
          this.filterService.refreshAlertDeleteDups.next(true)
        }

      }
    })
  }

  updateAlertsNetwork() {
    // let api_key = 100135
    
    if (this.length > 1) {
      this.updatesObj.splice(this.idx, 1);
    } else if (this.length == 1) {
      this.updatesObj.splice(this.idx, 1);
      this.hideUpdateTooltip.emit(false);
      this.refreshNetworkChart.emit(true)
    }
    let query:any = {}
    query["api_key"] = '100135';
    query["report_typ"] = 'D'
    query["Alert_key"] = '100103' || undefined
    query["alert_dt"] = this.update.date || undefined
    query["patient_id"] = this.update.id || undefined

    this.filterService.frmRemoveAlerts(query).subscribe(
      (res: any) => {
        if (res) {

        }
      },
      (error: any) => {

      })
  }
}
