import { HeatMapChartComponent } from './heat-map-chart/heat-map-chart.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { MatComponentsModule } from 'src/app/mat-components.module';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { IconChartComponent } from './iconEvent-chart/icon-chart.component';
import { GanttChartComponent } from './gantt-chart/gantt-chart.component';
import { PivotGridComponent } from './pivot-Grid/pivot-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { BubblemapLineChartComponent } from './bubblemap-line-chart/bubblemap-line-chart.component';
import { SparklinesGridComponent } from './sparklines-Grid/sparklines-grid.component';
import { NetworkChartComponent } from './network-chart/network-chart.component';
import { PipelineTooltipLinkComponent } from '../pipeline-tooltip-link/pipeline-tooltip-link.component';
import { BarChartTempComponent } from './barchart/barchart.component';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TransactionDetailPopupComponent } from '../transaction-detail-popup/transaction-detail-popup.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { PendingInformationPopupComponent } from '../pending-information-popup/pending-information-popup.component';
import { GeneralTooltipPopupComponent } from '../general-tooltip-popup/general-tooltip-popup.component';
import { PjBarChartComponent } from './pj-bar-chart/pj-bar-chart.component';
import { MatRadioModule } from '@angular/material/radio';
import { ChoroplethMapChartComponent } from './choropleth-map-chart/choropleth-map-chart.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ComboBarChartComponent } from './combo-bar-card/combo-bar-chart.component';
import { SparkLineBarGridComponent } from './spark-line-bar-grid/spark-line-bar-grid.component';
import { PipelineUpdatePopupComponent } from '../pipeline-update-popup/pipeline-update-popup.component';
import { WordCloudChartComponent } from './word-cloud-chart/word-cloud-chart.component';
import { GroupedHbarChartComponent } from './grouped-hbar-chart/grouped-hbar-chart.component';
import { AreaLineComboChartComponent } from './area-line-combo-chart/area-line-combo-chart.component';
import { SmallBarChartComponent } from './small-bar-chart/small-bar-chart.component';
import { BulletChartComponent } from './bullet-chart/bullet-chart.component';
import { HierarchicalGridChartComponent } from './hierarchical-grid-chart/hierarchical-grid-chart.component';
import { SimpleChangePopupComponent } from '../simple-change-popup/simple-change-popup.component';
import { ColorPickerComponent } from './PDF-EXPORT/color-picker.component';
import { PDFExportPanel } from './PDF-EXPORT/pdf-export-panel.component';
import { SlopeChartSComponent } from './slope-chart/slope-chart.component';
import { HbarChartComponent } from './hbar-chart/hbar-chart.component';
import { MatMenuModule } from '@angular/material/menu';
import { BasicHeatmapComponent } from './basic-heatmap/basic-heatmap-chart.component';
import { KpiBreakdownHeatmapComponent } from './kpi-breakdown-heatmap/kpi-breakdown-heatmap-chart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { AlertIconsCommomComponent } from '../alert-icons-commom/alert-icons-commom.component';
import { CustomTooltipDirective } from 'src/app/directives/custom-tooltip.directive';
import { MatBadgeModule } from '@angular/material/badge';
import { ControlChartComponent } from './control-chart/control-chart.component';
import { CustomHeader } from './sparklines-Grid/custom-header.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingComponent } from './area-line-combo-chart/loading';
import { NoENotationInputDirective } from 'src/app/directives/noENotationInput.directive';
// import { SimpleChangePopupComponent } from '../simple-change-popup/simple-change-popup.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {OverlayModule} from '@angular/cdk/overlay';
import { PdfExportPopupComponent } from './pdf-export-popup/pdf-export-popup.component';
import { MatTreeModule } from '@angular/material/tree';
// import { SimpleChangePopupComponent } from '../simple-change-popup/simple-change-popup.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { StackedAreabarComponent } from './stacked-areabar/stacked-areabar.component';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { ClusterBarChartComponent } from './cluster-bar-chart/cluster-bar-chart.component';
import { NewHeatmapChartComponent } from './new-heatmap-chart/new-heatmap-chart.component';
import { ProgressIndefinite } from '../progress-indicator/indefinite';
import { horizontalHarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart.component';
import { stackedComboBarChartComponent } from './stacked-combo-bar-chart/stacked-combo-bar-chart.component';
import { rowEditComponent } from './hierarchical-grid-chart/row-edit-component';


@NgModule({
  declarations: [
    DonutChartComponent,
    HeatMapChartComponent,
    LineChartComponent,
    BarChartComponent,
    ClusterBarChartComponent,
    IconChartComponent,
    GanttChartComponent,
    PivotGridComponent,
    BubbleChartComponent,
    BubblemapLineChartComponent,
    SparklinesGridComponent,
    NetworkChartComponent,
    PipelineTooltipLinkComponent,
    PipelineUpdatePopupComponent,
    BarChartTempComponent,
    TransactionDetailPopupComponent,
    RadarChartComponent,
    PendingInformationPopupComponent,
    GeneralTooltipPopupComponent,
    PjBarChartComponent,
    ChoroplethMapChartComponent,
    ComboBarChartComponent,
    SparkLineBarGridComponent,
    WordCloudChartComponent,
    horizontalHarChartComponent,
    GroupedHbarChartComponent,
    AreaLineComboChartComponent,
    SmallBarChartComponent,
    BulletChartComponent,
    HierarchicalGridChartComponent,
    SimpleChangePopupComponent,
    PDFExportPanel, 
    ColorPickerComponent,
    SlopeChartSComponent,
    HbarChartComponent,
    BasicHeatmapComponent,
    KpiBreakdownHeatmapComponent,
    AlertIconsCommomComponent,
    CustomTooltipDirective,
    LoadingComponent,
    NoENotationInputDirective,
    ControlChartComponent, 
    CustomHeader,
     PdfExportPopupComponent,
    StackedAreabarComponent,
    AlertPopupComponent,
    NewHeatmapChartComponent,
    horizontalHarChartComponent,
    stackedComboBarChartComponent,
    rowEditComponent
  ],
  imports: [
    MatRadioModule,
    CommonModule,
    MatComponentsModule,
    MatSlideToggleModule,
    FormsModule,
    DragDropModule,
    MatTooltipModule,
    AgGridModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    MatMenuModule,
    MatSliderModule,
    MatBadgeModule,
    MatExpansionModule,
    OverlayModule,
    MatTreeModule,
    MatButtonToggleModule,
    ProgressIndefinite
  ],
  exports: [
    DonutChartComponent,
    HeatMapChartComponent,
    LineChartComponent,
    BarChartComponent,
    ClusterBarChartComponent,
    IconChartComponent,
    GanttChartComponent,
    PivotGridComponent,
    BubbleChartComponent,
    BubblemapLineChartComponent,
    SparklinesGridComponent,
    NetworkChartComponent,
    BarChartTempComponent,
    TransactionDetailPopupComponent,
    RadarChartComponent,
    PendingInformationPopupComponent,
    GeneralTooltipPopupComponent,
    PjBarChartComponent,
    ChoroplethMapChartComponent,
    PipelineUpdatePopupComponent,
    ComboBarChartComponent,
    SparkLineBarGridComponent,
    WordCloudChartComponent,
    horizontalHarChartComponent,
    GroupedHbarChartComponent,
    AreaLineComboChartComponent,
    SmallBarChartComponent,
    BulletChartComponent,
    HierarchicalGridChartComponent,
    PDFExportPanel, 
    ColorPickerComponent,
    SlopeChartSComponent,
    HbarChartComponent,
    SimpleChangePopupComponent,
    BasicHeatmapComponent,
    AlertIconsCommomComponent,
    KpiBreakdownHeatmapComponent,
    ControlChartComponent,
    StackedAreabarComponent,
    LoadingComponent,
    NewHeatmapChartComponent,
    NoENotationInputDirective,
    horizontalHarChartComponent,
    stackedComboBarChartComponent,
    rowEditComponent
  ],
  
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChartsModule { }
