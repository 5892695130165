import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { LicenseManager } from  'ag-grid-enterprise'
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043433 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data Unveil )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SCRAII )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SCRAII )_need_to_be_licensed___( SCRAII )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 June 2024 )____[v2]_MTcxNzcxNDgwMDAwMA==8211f96f946c59b6a3dfa3ea03a04f25")
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
} from "ag-grid-community";
import { Subscription } from "rxjs";
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: "app-data-grid",
  templateUrl: "./data-grid.component.html",
  styleUrls: ["./data-grid.component.scss"],
})
export class WidgetDataGridComponent implements OnInit, OnDestroy {
  static key = 100501;

  @Input("item") item: any = {};
  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("dataGridDiv", { static: true }) dataGridDiv!: ElementRef;
  @ViewChild('fs') fs!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  isActive = false;
  isDataLoaded = false;
  agGridShowStatus = false;
  loader:Boolean=true
  menuTabs = ["columnsMenuTab", "filterMenuTab"];
  data: any[] = [];
  config: any = {};
  heading = "Processing Status";
  rowStyle: any;
  rowHeight: any;
  private gridApi!: GridApi;
  columnDefs: ColDef[] = [];
  dataRange: any[] = [];
  prefix = "";
  suffix = "";
  autoGroupColumnDef: ColDef<any> = {};
  dataAlignment: any;
  reqSubcription: Subscription[] = [];
  expandCollapseIcon:any = "expand";
  expandOrCollapse = "Expand All";
  noData: Boolean = false
  public defaultColDef: ColDef<any> = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: false,
    suppressMenu: true
  };

  constructor(private filterService: FilterService, private dataService: DataService, private httpClient: HttpClient, private apiService: ApiService) {
    this.reqSubcription.push(
      this.filterService.filterQuery.subscribe((query: any) => {
        this.loadApiData();
        this.loader=true
      })

      );
    // this.dataService.expandCollapseChart.subscribe((res:any) => {
    //   // this.expandCollapse()
    // })
    
    this.rowHeight = 0;
    this.rowStyle = {
      padding: "0px 0px",
      "border-bottom": "#f3f3f3 1px solid",
    };
  }
  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.dataGridDiv.nativeElement);
    this.loadDataGridConfig();
    this.loadDataNewGridConfig();
 this.expandCollapseIcon = "expand";

  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }

  ngOnChanges(changes: any): void {}

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('rowGroupOpened', this.onRowGroupOpened.bind(this));
  }

  onRowGroupOpened(event: any): void {
    // Check if all autoGroupColumnDef groups are closed
    let allGroupsClosed = false;
    
    this.gridApi.forEachNode((node) => {
      if (node.group && node.expanded) {
        allGroupsClosed = true;
      }
    });

    if(allGroupsClosed) {
      this.expandCollapseIcon = "collapse";
      this.expandOrCollapse = "Collapse All";
    } else {
      this.expandCollapseIcon = "expand";
      this.expandOrCollapse = "Expand All";
    }

    this.filterService.expendTree.next({'key':WidgetDataGridComponent.key,'value':allGroupsClosed})
  }

  expendCol(event:any) {
     
    if (this.expandCollapseIcon == "collapse") {
      this.expandCollapseIcon = "expand";
      this.expandOrCollapse = "Expand All";
      this.gridApi?.collapseAll();
    } else {
      this.expandCollapseIcon = "collapse";
      this.expandOrCollapse = "Collapse All";
      this.gridApi?.expandAll();
    }
  }

  loadDataGridConfig() {
    this.data = [];
    let cfg = this.item.config;
    this.config["heading"] = cfg["report_heading"] || "Data Grid";
    if (cfg["status_column"]) {
      this.config.status_column = cfg["status_column"]["api_resp_column_nm"];
      this.config.levels=[]
      for (let i = 1; i < 10; i++) {
        const element = cfg?.['l' + i + '_col']?.['api_resp_column_nm']
        if (element != undefined) {
          this.config.levels.push(element)
        }
      }
      // this.config.levels = [
      //   cfg["l1_col"]["api_resp_column_nm"],
      //   cfg["l2_col"]["api_resp_column_nm"],
      //   cfg["l3_col"]["api_resp_column_nm"],
      // ];
    }
  }

  loadDataNewGridConfig() {
    this.data = [];
    let cfg = this.item.config;
    this.heading = cfg["report_heading"] || "Data Grid";
    this.dataRange = cfg["status_color_code"]
      ? cfg["status_color_code"].data
      : [];
    this.prefix = cfg["status_color_code"].prefix || "";
    this.suffix = cfg["status_color_code"].suffix || "";
    this.dataAlignment =
      cfg["data_alignment"]["value"].toLowerCase() || "center";

    this.columnDefs = [
      // {
      //   field: cfg.l1_col["api_resp_column_nm"],rowGroup: true,hide: true,suppressColumnsToolPanel: true, // sort: 'asc'
      // },
      // {
      //   field: cfg.l2_col["api_resp_column_nm"],rowGroup: true,hide: true,suppressColumnsToolPanel: true,// sort: 'asc'
      // },      
      {
        field: cfg.status_column["api_resp_column_nm"],headerName: cfg.status_column["api_resp_column_display_nm"],
        aggFunc: "avg",
        menuTabs: this.menuTabs,
        suppressMenu: true,
        filter: "agNumberColumnFilter",
        // sort: 'asc',
        valueFormatter: (params) =>
          this.prefix + parseFloat(params.value + "").toFixed(2) + this.suffix,
        cellStyle: (param) => {
          return {
            "text-align": this.dataAlignment,
            color: this.getDataColor(this.dataRange, param),
            "font-size": "13px",
          };
        },
        cellRendererParams: {
          config: cfg
        },
      },
    ];
    // dynamic column group definition
    this.config.levels.forEach((groupColDef:any, index:number) => {
      if (this.config.levels.length>1 && index === this.config.levels.length - 1) {
          return; // Skip the last iteration
      }
      this.columnDefs.push({
          field: groupColDef,
          rowGroup: true,
          hide: true,
          suppressColumnsToolPanel: true,
          // sort: 'asc'
      });
  });


    cfg["heading_colors"].map((col: any) => {
      if (col["isSelected"]) {
        this.columnDefs.push({
          sortable: true,
          resizable: true,
          menuTabs: this.menuTabs,
          suppressMenu: true,
          filter: "agNumberColumnFilter",
          field:
            col["api_resp_column_nm"] == "delay"
              ? "delayed"
              : col["api_resp_column_nm"],
          cellStyle: { "text-align": this.dataAlignment, "font-size": "13px" },
          type: "numericColumn",
          aggFunc: "sum",
          headerComponentParams: {
            template: `
                  <div class="ag-cell-label-container" role="presentation">
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                        <span class="ag-header-cell-text">
                        <i style="font-size: 9px;color:${col['color']};padding-right:5px;" class="fa fa-circle"></i>
                        ${col["api_resp_column_display_nm"]}
                        </span>
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                    </div>
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                  </div>
                `,
          },
        });
      }
    });
    this.autoGroupColumnDef = {
      flex: 1,
      minWidth: 400,
      headerName: cfg.l1_col["api_resp_column_display_nm"],
      field:  this.config.levels.length==1 ? undefined : this.config.levels[this.config.levels.length-1],// cfg.l1_col["api_resp_column_nm"],
      cellRendererParams: {
        suppressCount: true,
      },
      sortable: false,
      resizable: false,
      cellClassRules: {
        'autoGroupClass': params => params.node.level === 0
      }
      // menuTabs: this.menuTabs,
      // filter: "agNumberColumnFilter",
      // suppressMenu: true,
      // sort: 'asc'
    };
  }

  // getValue(prefix:any,param:any,suffix:any) {
  //     return prefix + ' ' + parseFloat(param).toFixed(2) + suffix
  // }

  getDataColor(rangeValue: any, param: any) {
    if (!rangeValue) return null;

    for (let idx in rangeValue) {
      let r = rangeValue[idx];
      if (
        (parseInt(r.startValue) <= param.value ||
          parseInt(r.startValue) <= param.value.value) &&
        (param.value.value <= parseInt(r.endValue) ||
          param.value <= parseInt(r.endValue))
      ) {
        return r.color;
      }
    }
    return null;
  }

  loadApiData() {

    let api = this.item.config["list_of_api"] || {};
    this.data = [];
    this.isDataLoaded = false
    
    if(api.api_key && (this.filterService.isEmptyObject(this.filterService.baseQuery) == false)) {
      
      // API Payload
      let obj:any = {"api_key" : api.api_key}

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          this.loader=false
          this.isDataLoaded = true;
          if(res.length == 0){ this.noData = true}else{this.noData = false}
          this.data = res == null ? [] : res;
        },(err:any)=>{
          console.log('grid',err)
          this.isDataLoaded = true;
          this.noData = true
          this.loader=false;
        })
      )

      // this.reqSubcription.push(
      //   this.filterService.executeQuery(obj).subscribe((res: any) => {
      //     if(res!=null) {
      //       this.isDataLoaded = true;
      //       this.data = res == null ? [] : res;
      //     }
      //   }, (err: any)=> {
      //     this.isDataLoaded = true;
      //     console.log(err)
      //   })
      // );
    }
  }

  fullscreenChanges(fullscreen:any){
    this.isFullscreen = fullscreen;
    this.isActive = fullscreen;
  }

  // Grid options for ag-Grid
  gridOptions: any = {
    getContextMenuItems: (params:any) => {
      return [
        'copy', // Default copy options
        'copyWithHeaders', // Default copy with headers option
        'copyWithGroupHeaders', // Default copy with group headers option
        'paste', // Default paste option
        {
          name: 'Export to Excel',
          action: () => this.exportXls(),
        },
      ];
    },
  };

  exportXls() {
    this.gridApi.exportDataAsExcel({
      fileName: this.item.config['report_heading'],
      sheetName: this.filterService.selectedBrandName || this.item.config['report_heading'],
      processCellCallback: (params: any) => {

        let enhancement = params.column.colDef?.cellRendererParams
        let prefix = enhancement?.config?.['status_color_code'].prefix
        let suffix = enhancement?.config?.['status_color_code'].suffix

        return (prefix ? prefix : '')  + params.value + (suffix ? suffix : '');
      }
    });
  }
}
