<div *ngIf="this.tooltipValue">
    <span *ngIf="this.fndFormats">
        <mat-icon class="svgIconValue" [customTooltipOver]="tooltipTemplate" [aria-hidden]="true"  [svgIcon]="fndFormats.icon" [style.color]="fndFormats.color"></mat-icon>
    </span>
    <span *ngIf="!this.fndFormats">
        <mat-icon class="svgIconValue" [customTooltipOver]="tooltipTemplate" [aria-hidden]="true" [svgIcon]="fixNull"></mat-icon>
    </span>
</div>
<div *ngIf="!this.tooltipValue">
    <span *ngIf="this.fndFormats">
        <mat-icon class="svgIconValue" [aria-hidden]="true"  [svgIcon]="fndFormats.icon" [style.color]="fndFormats.color"></mat-icon>
    </span>
    <span *ngIf="!this.fndFormats">
        <mat-icon class="svgIconValue" [aria-hidden]="true" [svgIcon]="fixNull"></mat-icon>
    </span>
</div>


<ng-template #tooltipTemplate>
    <div style="background-color: #000;border-radius: 10px;max-width: 200px;">
        <div class="row">
            <div class="col-12" style="background-color: #343435;padding: 10px;border-radius: 10px 10px 0 0;">
                <span style="font-family: Poppins;font-size: 14px;font-weight: 600;line-height: 16px;color: #fff;">Number of records</span>
            </div>
            <div class="col-12" style="background-color: #000000;padding: 10px;border-radius: 0 0 10px 10px;">
                <span style="font-family: Poppins;font-size: 12px;font-weight: 500;line-height: 16px;color: #fff;">{{ tooltipValue + " RECORDS"}}</span>
            </div>
        </div>
    </div>
</ng-template>