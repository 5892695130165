import { Injectable, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe, PlatformLocation } from '@angular/common';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService implements OnInit {
  public loggedUser: any

  constructor(
    public loc: PlatformLocation,
    public datePipe: DatePipe
    ) {
  }

  public ngOnInit() {
  }

imagetoDataURL(url:any){
    return fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))
  }

  isEmpty(data: string) {
    if (data === "") return true;
    return false;
  }

  /**
   *
   * This method will dynamically collect the all the form fields and apply the validation rules
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getDataFromRow(row:any, col: any, type:any, prefix?:any,suffix?:any):any {
    if (!row) return ""
     if (col instanceof Array) {
        let result = ""
        for(let idx=0;idx<col.length;idx++) {
             result += this.getDataFromRow(row,col[idx],type,prefix,suffix)
        }
        return result;
     } else {
      if (col instanceof Object) {
         return  this.getDataFromRow(row,col.name,type,col.prefix,col.suffix)
      } else {
        var dot = col.indexOf('.')
        if (dot > 0) {
          if (row[col.substr(0, dot)])
            return this.getDataFromRow(row[col.substr(0, dot)], col.substr(dot + 1), type,prefix,suffix)
        }
        if (row) {
          let val = this.formatData(row[col], type)
          return  val!=""? (prefix || '') + val + (suffix || ''):""
        }
    }
    }
    return ""
  }

  formatData(data:any, type:any) {
    if (!data) return ''
    if (type == "date") {
      return this.showDate(data)
    } else if (type == "time") {
      return this.showTime(data)
    } else if (type == "dateTime") {
      return this.showDateTime(data)
    } else if (type == "duration") {
      return this.convertMinsToHrsMins(data)
    }
    return data
  }


  getDataByPath(data:any, path:string) {
    if(!path) return data; // if path is undefined or empty return data
    if (path.startsWith("'"))
        return path.replace(/'/g,"")
    var paths = path.split(".");
    for(var p in paths) {
        if(!paths[p]) continue;
        data = data[paths[p]]; // new data is subdata of data
        if(!data) return data;
    }
    return data;
  }

  newGetDataByPath(data:any, path:String) {
    if(!path) return data; // if path is undefined or empty return data
    if (path.startsWith("'"))
        return path.replace(/'/g,"")
    var paths = path.split(".");
    let filter = "";

    for(var p in paths) {
        if(!paths[p]) continue;
        if(data[paths[p]]) {
          data = data[paths[p]]; // new data is subdata of data
        } else {
          if(data.length) {
            data.forEach((res:any)=> {
              filter += res[paths[p]] + ','
            })
            data = filter.slice(0, filter.lastIndexOf(","))
          }
        }
        if(!data) return data;
    }
    return data;
  }

  processText(exp:any,data:any) {
    if (data !== null) {
      exp = exp.replace(
        /{{(\w+)}}/g, // this is the regex to replace %variable%
        (match:any, field:any) => {
          return this.getDataByPath(data,field) || ''
        }
      );
      return exp.trim();
    }
  }

  buildOptions(res:any,to:any) {
    var data = res.data ? res.data : res
          if (to.labelPropTemplate) {
                data.map((e:any) => {
                    e[to.labelProp] = this.processText(to.labelPropTemplate,e)
                })
          }
          data = _.sortBy(data,to.labelProp)
          to.options = data
  }


  convertMinsToHrsMins(minutes: any) {
    let hours, mins;
    var h = Math.floor(minutes / 60);
    var m = Math.round(minutes % 60);
    hours = h < 10 ? '0' + h : h;
    mins = m < 10 ? '0' + m : m;
    return hours + ':' + mins;
  }

  showDate(dt: any) {
    if (!dt) return ""
    try {
      return this.datePipe.transform(dt, 'dd-MMM-yy')
    } catch (e) {
      return ""
    }
  }

  showDateTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datePipe.transform(dt, 'dd-MM-yy hh:mm a')
    } catch (e) {
      return ""
    }
  }

  showFullDate(dt: any) {
    if (!dt) return ""
    try {
      return this.datePipe.transform(dt, 'EEE, MMM d, y')
    } catch (e) {
      return ""
    }
  }

  showFullDateTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datePipe.transform(dt, 'EEE, MMM d, y, hh:mm a')
    } catch (e) {
      return ""
    }
  }

  showTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datePipe.transform(dt, 'hh:mm a')
    } catch (e) {
      return ""
    }
  }

  convertMinutesToReadableFormat(min: string): string {
    // Convert minutes to milliseconds
    const duration = moment.duration(parseFloat(min) * 60 * 1000);
  
    // Calculate each time unit
    const years = Math.floor(duration.asDays() / 365);
    const days = Math.floor(duration.asDays() % 365);
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = Math.floor(duration.minutes());
    const seconds = duration.seconds();
  
    // Return the largest time unit, and only that
    if (years > 0) {
      return `${years}y`;
    }
    if (days > 0) {
      return `${days}d`;
    }
    if (hours > 0) {
      return `${hours}h`;
    }
    if (minutes > 0) {
      return `${minutes}m`;
    }
    // If no other units apply, return seconds
    return `${seconds}s`;
  }
  
}
