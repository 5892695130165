<div class="widget-container patient_journey_card" #patientJourneyDiv>
  <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">

    <icon-chart 
      [headerConfig]="widgets.config?.icon"
      [data]="ganttData" 
      [pageKey]="pageKey" 
      [heading]="heading"
      [chartName]="'GanttChart'" 
      [config]="GanttChartContainer" 
      [expand]="fs" 
      [fullscreen]="isFullscreen"
      [item]="item"
      [alertIcons]="alertIcons"
      [apiKey]="100104"
    ></icon-chart>

    <div style="display: block;height: calc(100% - 65px);overflow-y: auto;overflow-x: hidden;" #fs>
      <div class="row">
        <div class="col-11" #GanttChartContainer>
          <app-gantt-chart *ngIf="!isPortraitMode" [widgets]="widgets"></app-gantt-chart>
          <div class="container-fluid d-flex justify-content-center" *ngIf="isPortraitMode" style="height: 258px;background: #F6F8FB;">
            <div class="row">
              <div class="col-12 landModeText">
                To see patient journey rotate phone to landscape mode
              </div>
            </div>
          </div>
        </div>
        <div class="col-1 d-flex align-items-center" *ngIf="!isPortraitMode">
          <button mat-button class="time_to_fill"><span class="inner_txt_time_to_fill"
              (click)="kpiPopup()">KPI's</span></button>
          <div class="kpi_popup" id="kpi_gantt_popup" *ngIf="showKPIPopup">
            <div class="row mt-3">
              <div class="col-6 kpi_heading">
                <span class="ml-3">KPI Comparison</span>
              </div>
              <div class="col-5">
                <select class="time_to_fill_kpi" name="Time To Fill" id="timeToFill">
                  <option default value="time_To_Fill">Time To Fill</option>
                </select>
              </div>
              <div class="col-1">
                <img src="../../../../assets/Vector.png" (click)="showKPIPopup=false" />
              </div>
            </div>
            <hr class="kpi_divider" />
            <div class="kpi_chart_container">
              <!-- <app-radar-chart></app-radar-chart> -->
              <div class="row">
                <div class="col-1 pr-4"
                  style="writing-mode: vertical-rl;text-orientation: mixed;transform:rotate(180deg);text-align: center;">
                  <span class="time_Fill">Time To Fill </span><span class="days_css">(Days)</span>
                </div>
                <div class="col-11 pl-0">
                  <app-pj-bar-chart></app-pj-bar-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>