import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-value-as-icon-snippet',
  templateUrl: './value-as-icon-snippet.component.html',
  styleUrls: ['./value-as-icon-snippet.component.scss']
})
export class ValueAsIconSnippetComponent {
  params:any;
  conditionalFormat:any;
  fndFormats:any;
  tooltipValue:any;
  fixNull = 'fix_null'

  constructor() { }

  agInit(params: ICellRendererParams): void {
   this.params = params;
   this.conditionalFormat = this.params.conditionalFormat
   this.fndFormats = this.conditionalFormat.values.find((f:any)=> f.col_value.toLowerCase() == this.params.valueFormatted.toLowerCase())
   this.tooltipValue = this.params.data[this.params.res?.valueIconSelectTooltip];
  }

}
