import { Directive, Input, TemplateRef, OnInit, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { ComponentType, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';

@Directive({
  selector: '[customTooltipOver]'
})
export class CustomTooltipOverDirective implements OnInit {

  @Input('customTooltipOver') tooltipContent!: TemplateRef<any> | ComponentType<any>;

  private _overlayRef!: OverlayRef;
  private showTimeout!: any;
  private hideTimeout!: any;
  private isTooltipVisible: boolean = false; // Track visibility state

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    if (this.tooltipContent) {
      const position = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX: 0,
            offsetY: 8,
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: 0,
            offsetY: -8,
          }
        ]);

      this._overlayRef = this.overlay.create({
        positionStrategy: position,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'custom-tooltip-over',
      });
    } else {
      console.error('[ERROR] Tooltip content is required.');
    }
  }

  private _attachContent(): void {
    if (!this._overlayRef.hasAttached()) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;

      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      } else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }

      this._overlayRef.attach(containerPortal);
      this.isTooltipVisible = true; // Update state
    }
  }

  private _detachContent(): void {
    if (this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
      this.isTooltipVisible = false; // Update state
    }
  }

  @HostListener('mouseenter')
  private _show(): void {
    clearTimeout(this.hideTimeout); // Cancel any pending hide operation

    // Add a small delay before showing to handle fast mouse movements
    this.showTimeout = setTimeout(() => {
      if (!this.isTooltipVisible) {
        this._attachContent(); // Attach content if not visible
      }
    }, 100); // Small delay before showing tooltip (adjust as needed)
  }

  @HostListener('mouseleave')
  private _hide(): void {
    clearTimeout(this.showTimeout); // Cancel any pending show operation

    // Add a small delay before hiding to handle fast mouse movements
    this.hideTimeout = setTimeout(() => {
      if (this.isTooltipVisible) {
        this._detachContent(); // Detach content if visible
      }
    }, 100); // Small delay before hiding tooltip (adjust as needed)
  }

  @HostListener('click')
  private _forceHide(): void {
    // Ensure immediate hide on click
    clearTimeout(this.showTimeout);
    clearTimeout(this.hideTimeout);
    this._detachContent(); // Detach content on click
  }
}
