import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'slope-chart',
  templateUrl: './slope-chart.component.html',
  styleUrls: ['./slope-chart.component.scss']
})
export class SlopeChartSComponent implements OnInit, OnChanges {


  @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  @ViewChild('lineChart') lineChart!: ElementRef;
  // @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  noData:boolean=false;
  slopeFormattedData = [];
  slopeChartFormattedData: any = [];
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  SlopeChartData: any=[];
  props: any;
  divId: any = "slopeChartDiv";
  dataTurn: number = 0
  renderLinePopup: any = false
  initiateChart: boolean = false
  showBy: any
  isTooltip: boolean = false
  mytooltipData: any
  value1: any = "Business KPIs"
  value2: any = "Data Quality"
  tooltiptype: any
  hei: number = 0;
  @ViewChild("showMoreData", { static: true }) showMoreData!: ElementRef;
  constructor(public filterService: FilterService, private dataService: DataService, private container: ElementRef, private currency: CurrencyPipe, private newFilterService: NewFilterService, private dialogService: DialogService) {
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      // this.closeFullscreen();
      this.isFullscreen = false
    }
  }


//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  closeFullscreen(): void {
    // this.isFullscreen = false;
    // this.props.chartHeight = 550
    // this.isActive = false;
    // if (document.fullscreenElement) {
    //   document.exitFullscreen();
    // }
    // setTimeout(() => {
    // this.plotChart()
    // }, 100);
  }
  totalTooltipData: any = []
  showMore: boolean = false
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    this.showMore = false
    this.tooltiptype = myType
    this.mytooltipData = myData
    this.hei = 0
    this.dataTurn = 0
    this.hei = chartHeight - myY
    this.dataTurn = chartWidth - myX
    let value: any = []

    if (this.item.config.chart_type == 'scorecard' && this.tooltiptype != "sp") {
      value['Business'] = []
      value['Data quality'] = []
      myData.values.forEach((element: any) => {
        if (element[2] == this.value1) {
          value['Business'].push(element)
        } else {
          value['Data quality'].push(element)
        }
      });
      this.mytooltipData.values = value
    }
    else {
      const copiedData = myData
      this.totalTooltipData = copiedData;
      this.mytooltipData = JSON.parse(JSON.stringify(copiedData));
      const tooltipData = copiedData;

      if (copiedData.values.length > 4) {
        this.showMore = true;
        const data1 = copiedData.values.slice(0, 4);
        this.mytooltipData.values = data1;
      } else {
        this.showMore = false;
      }
    }

    if (this.isFullscreen == true) {
      if (this.tooltiptype == 'sp') {

        if (this.hei < 140) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', this.hei - 42 + 'px')
            .style('top', 'unset')
        }
        else if (this.hei > 140) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY + 30) + 'px')
            .style('bottom', 'unset')
        }

        if (this.dataTurn < 250) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }
        this.isTooltip = true
      }

      else {
        if (this.hei < 300) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', 0 + 40 + 'px')
            .style('top', 'unset')
        }
        else if (this.hei > 300) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', myY - 40 + 'px')
            .style('bottom', 'unset')
        }

        if (this.dataTurn < 250) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }
        this.isTooltip = true
      }
    } else {

      if (this.tooltiptype == 'sp') {

        if (this.hei < 140) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', (this.hei - 42) + 'px')
            .style('top', 'unset')
        }
        else if (this.hei > 140) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', (myY + 30) + 'px')
            .style('bottom', 'unset')
        }

        if (this.dataTurn < 250) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }
        this.isTooltip = true

      }

      else {
        if (this.hei < 300) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', 0 + 40 + 'px')
            .style('top', 'unset')
        }
        else if (this.hei > 300) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('top', myY - 40 + 'px')
            .style('bottom', 'unset')
        }

        if (this.dataTurn < 250) {
          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select("#d3SlopeChartTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }
        this.isTooltip = true
      }
    }

  }

   hideTooltip(myType: any): void {
    if (!this.showMore) {
      this.isTooltip = false
    } else { }

  }

  ngOnInit(): void {
      this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      // this.formatPercentageData(this.data);
      this.getSlopeChartData();

    }
  }

  openDialog() {
    this.isTooltip = false
    this.dialogService.openDialog(this.showMoreData, '300px')
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg
      .append("defs")
      .append("linearGradient")
      .attr("id", "kpiGradient");

    mySvg.append("g").attr("id", "barGroup");
    mySvg.append("g").attr("id", "lineGroup");
    this.initiateChart = true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    let width: any = mySvg.node().getBoundingClientRect().width;
    let height = myChart.props.chartHeight;
    const margins = { left: 0, right: 0, top: 70, bottom: 28, gradient: 20} ;
    const kpiColWidth = 44;
    const minKpiColWidth = 118;
    const dotRadius = 8;
    const chartHeight = height - margins.top - margins.bottom;
    const columnVars: any = [];
    const columnFormats: any = {};
    const columnDirection: any = {};
    let chartData: any = []
    let columnSet: any = new Set();
    let lineSet: any = new Set();
    let allColumns: any = [];
    let columnsGroups: any = {};
    let dataByKpi: any = {};
    let kpiCatGroups: any = {};
    if(myChart.props.groupVar === null){myChart.props.showGroups = false};
    myChart.SlopeChartData.forEach((d: any) => {
        const myGroupVar = myChart.props.groupVar === null ? "" : d[myChart.props.groupVar];
        if(columnsGroups[myGroupVar] === undefined){
          columnsGroups[myGroupVar] = new Set();
        }
        columnSet.add(myGroupVar);
        columnsGroups[myGroupVar].add(d[myChart.props.kpiVar])
        lineSet.add(d[myChart.props.lineVar])
    });
    columnSet = Array.from(columnSet);
    columnSet.forEach((d: any) => {
        columnVars.push({
          name: d,
          columns: Array.from(columnsGroups[d])
        })
        allColumns = allColumns.concat(Array.from(columnsGroups[d]));
    })
    lineSet = Array.from(lineSet);
    lineSet.forEach((d: any) => {
        let myEntry: any = {};
        myEntry[myChart.props.lineVar] = d;
        allColumns.forEach((c: any) => {
          if(dataByKpi[c] === undefined){dataByKpi[c] = {}};
          const matchingRow = myChart.SlopeChartData.find((f: any) => f[myChart.props.kpiVar] === c && f[myChart.props.lineVar] === d);
          if(matchingRow === undefined){
            console.log("ALERT - data missing!!")
          } else {
            dataByKpi[c][d] = matchingRow[myChart.props.valueVar];
            myEntry[c] = matchingRow[myChart.props.valueVar];
          }
        })
        chartData.push(myEntry);
    })
    allColumns.forEach((d: any) => {
      const firstMatching = myChart.SlopeChartData.find((f: any) => f[myChart.props.kpiVar] === d);
      columnFormats[d] = firstMatching[myChart.props.formatVar];
      columnDirection[d] = [+firstMatching[myChart.props.rangeVars[0]],+firstMatching[myChart.props.rangeVars[1]]];
    })
    mySvg.attr("height", height);
    mySvg.select("#kpiGradient")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%");

    const gradientStops = [
      ["#EBFAF2FF",0],
      ["#EBFAF220",50],
      ["#FAE7EC20",50],
      ["#FAE7ECFF",100]
    ]

    mySvg.select("#kpiGradient")
      .selectAll("stop")
      .data(gradientStops)
      .enter()
      .append("stop")
      .style("stop-color",  (d: any)  => d[0])
      .attr("offset", (d: any) => d[1] + "%");

    let kpiColumns: any = [];
    const kpiGroups: any = {};
    columnVars.forEach((d: any) => {
      kpiGroups[d.name] = d.columns;
      d.columns.forEach((c: any) => kpiCatGroups[c] = d.name);
      kpiColumns = kpiColumns.concat(d.columns);
    })
    const kpiScales: any = {};
    const kpiScaleTicks: any = {};
    kpiColumns.forEach((d: any) => {
      const columnMax: any = d3.max(myChart.SlopeChartData.filter((f: any) => f[myChart.props.kpiVar] === d),
        (m: any) => m[myChart.props.valueVar]);
      const topIndex = columnDirection[d][0] < columnDirection[d][1] ? 1 : 0;
      if((columnMax/columnDirection[d][topIndex]) < 0.85){
        columnDirection[d][topIndex] = Math.ceil(columnMax/100) * 100
      }
      kpiScales[d] = d3.scaleLinear()
        .domain(columnDirection[d])
        .range([height - margins.top - (margins.gradient * 2) - margins.bottom,0])
        .nice();
      ;
      const start: any = columnDirection[d][topIndex === 1 ? 0 : 1];
      const stop: any = columnDirection[d][topIndex];
      let step: any = (stop - start)/5;
      if(start > stop){ step = -step}
      kpiScaleTicks[d] = Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    })
    const lineVars: any = [];
    chartData.forEach((d: any) => lineVars.push(d[myChart.props.lineVar]));
    const lineData: any = [];
    const columnData: any = [];
    lineVars.forEach((d: any, i: any) => {
      const myLineData = chartData.find((f: any) => f[myChart.props.lineVar] === d);
      const currentLine: any = [];
      kpiColumns.forEach((k: any) => {
        if(myLineData[k] !== undefined){
          currentLine.push({
            xVar: k,
            yVal: myLineData[k],
            yPosition: kpiScales[k](myLineData[k])
          })
        }
        columnData.push({
          kpi: k,
          value: myLineData[k]
        })
      })
      lineData.push([d, currentLine, myChart.props.colors[i], false])
    })
    if(myChart.props.showAverage === true){
      const currentLine: any = [];
      kpiColumns.forEach((d: any) => {
        const myColumnData = columnData.filter((f: any) => f.kpi === d);
        const myMean = d3.mean(myColumnData, (m: any) => m.value);
        currentLine.push({
          xVar: d,
          yVal: myMean,
          yPosition: kpiScales[d](myMean)
        })
      })
      lineData.push(["Network Average", currentLine, myChart.props.averageColor, true])
    }
    myChart.slopeFormattedData = lineData;
    if(width < (kpiColumns.length * minKpiColWidth)){
      width = kpiColumns.length * minKpiColWidth;
      mySvg.attr("width", width)
    }
    const xScale: any = d3.scaleBand().domain(kpiColumns).range([0,width]);

    const barGroup = mySvg.select('#barGroup')
      .selectAll('.barsGroup')
      .data(kpiColumns)
      .join((group: any): any => {
        const enter = group.append('g').attr('class', 'barsGroup');
        enter.append('rect').attr('class', 'kpiBar');
        enter.append('text').attr('class', 'kpiBarLabel');
        enter.append('g').attr('class', 'yAxis');
        enter.append('g').attr('class', 'yAxisLineGroup');
        return enter;
      });

    barGroup.attr("transform", (d: any) => "translate(" + (margins.left + xScale(d) + xScale.bandwidth()/1.75 - kpiColWidth/2) + ","
    + margins.top + ")")

    barGroup.select(".kpiBarLabel")
      .attr("transform", "translate(" + ( kpiColWidth/2) + ",-10)")
      .attr('font-weight', '700')
      .attr('font-size', '12')
      .attr('font-family', 'Poppins')
      .attr("text-anchor", "middle")
      .attr("height", chartHeight)
      .attr("fill", "#000000")
      .text((d: any) => d)
      .call(wrap, xScale.bandwidth() - 5);

    barGroup.select(".kpiBar")
      .attr("width", kpiColWidth)
      .attr("height", chartHeight)
      .attr("fill", "url(#kpiGradient)");

    barGroup.select(".yAxis")
      .attr("id", (d: any) => getCorrectLabel(d))
      .attr("transform", (d: any) => "translate(0," + margins.gradient + ")")
      .each((d: any) => {
        const myGroup: any = d3.select(".yAxis#" + getCorrectLabel(d));
        myGroup.call(d3.axisLeft(kpiScales[d])
          .tickValues(kpiScaleTicks[d])
          .tickFormat((f: any) => d3.format(columnFormats[d])(f)))
    });

    const axisLineGroup = barGroup.select('.yAxisLineGroup')
      .selectAll('.axisLines')
      .data((d: any) => {
        const myData = kpiScaleTicks[d].map((m: any) => m = kpiScales[d](m))
        return myData;
      })
      .join((group: any): any => {
        const enter = group.append('g').attr('class', 'axisLines');
        enter.append('line').attr('class', 'axisLine');
        return enter;
      });

    axisLineGroup.select(".axisLine")
      .attr("x1", 0)
      .attr("x2", kpiColWidth)
      .attr("y1", (d: any) => margins.gradient + d)
      .attr("y2", (d: any) => margins.gradient + d)
      .attr("stroke", "#8A98AB")
      .attr("stroke-dasharray","4,4")
      .attr("stroke-width", 1);

    mySvg.selectAll(".yAxis text")
      .attr('font-weight', '700')
      .attr('font-size', '12')
      .attr('font-family', 'Poppins')
      .attr('fill','#101D42');

    mySvg.selectAll(".yAxis path")
      .attr("display", "none");

    mySvg.selectAll(".yAxis line")
      .attr("stroke", "#8A98AB");

    const line = d3.line()
      .x((d: any) => d.x + xScale(d.xVar))
      .y((d: any) => d.y);

    let allDots: any = [];
    lineData.forEach((d: any) => allDots = allDots.concat(d[1]));

    let currentCount = 0;
   columnVars.forEach((d: any ) => {
      d.xPosition = currentCount * xScale.bandwidth()
      currentCount += d.columns.length;
    })

    const groupGroup = mySvg
      .selectAll('.groupsGroup')
      .data(columnVars)
      .join((group: any): any => {
        const enter = group.append('g').attr('class', 'groupsGroup');
        enter.append('rect').attr('class', 'groupsRect');
        enter.append('text').attr('class', 'groupsRectLabel');
        enter.append('line').attr('class', 'groupsRectLine');
        return enter;
      });

    groupGroup.select(".groupsRectLine")
      .attr("stroke", (d: any, i: any) => i > 0 ? "#8A98AB" : "transparent")
      .attr("stroke-dasharray", "2,2")
      .attr("x1", (d: any) => d.xPosition)
      .attr("x2", (d: any) => d.xPosition)
      .attr("y1", margins.top)
      .attr("y2", height)

    groupGroup.select(".groupsRect")
      .attr("x", (d: any) => d.xPosition)
      .attr("y", height - margins.bottom)
      .attr("width", (d: any) => xScale.bandwidth() * d.columns.length)
      .attr("height", myChart.props.showGroups === true ? margins.bottom : 0)
      .attr("fill", "#E8EDF3")

    groupGroup.select(".groupsRectLabel")
      .attr("x", (d: any) => d.xPosition + (xScale.bandwidth() * d.columns.length)/2)
      .attr("y", height - (margins.bottom/2) + 5)
      .attr('fill', '#101D42')
      .attr('font-weight', '700')
      .attr('font-size', '12')
      .attr('font-family', 'Poppins')
      .attr('text-anchor', 'middle')
      .text((d: any) => myChart.props.showGroups === true ? d.name : "");



    const lineGroup = mySvg.select('#lineGroup')
      .selectAll('.linesGroup')
      .data(lineData)
      .join((group: any): any => {
        const enter = group.append('g').attr('class', 'linesGroup');
        enter.append('path').attr('class', 'linePath');
        enter.append('path').attr('class', 'linePathMouseover');
        enter.append('g').attr('class', 'dotsGroup');
        return enter;
      });

    lineGroup.select(".linePath")
      .attr("id", (d: any, i: any) => "linePath" + i)
      .attr("stroke", (d: any) => d[2])
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", (d: any) => d[3] === true ? "2,2" : "")
      .attr("fill", "none")
      .attr("transform", "translate(" + (margins.left + xScale.bandwidth()/2 + kpiColWidth/2)
        + "," + (margins.top + margins.gradient) + ")");

    lineGroup.select('.dotsGroup')
      .attr("stroke", (d: any) => d[2])
      .attr("stroke-dasharray", (d: any) => d[3] === true ? "2,2" : "")
      .attr("fill", (d: any) => d[2])
      .attr("transform", "translate(" + (margins.left + xScale.bandwidth()/2 + kpiColWidth/2)
        + "," + (margins.top + margins.gradient) + ")");

    lineGroup.select(".linePathMouseover")
      .attr("id", (d: any, i: any) => "linePath" + i)
      .attr("stroke", "transparent")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("transform", "translate(" + (margins.left + xScale.bandwidth()/2 + kpiColWidth/2)
        + "," + (margins.top + margins.gradient) + ")")
      .on("mouseover", (event: any, d: any) => {
          d3.select(".linePath#" + event.currentTarget.id)
            .attr("stroke-width", 2);
        const tooltipValues: any = d[1].map((m: any) => m = [m.xVar, d3.format(columnFormats[m.xVar].includes("%") ? columnFormats[m.xVar]: ",")(m.yVal), kpiCatGroups[m.xVar]]);
        const tooltipData = {name: d[0], color: d[2], values: tooltipValues};
        myChart.showTooltip('slopeChart', tooltipData, event.offsetX, event.offsetY, width,height);
      })
      .on("mouseout", (event: any) => {
        d3.selectAll(".linePath").attr("stroke-width", 1);
        myChart.hideTooltip('sp');
      });

    const dotGroup = lineGroup.select('.dotsGroup')
      .selectAll('.lineDotsGroup')
      .data((d: any, i: any) => {
        d[1].map((m: any) => {
          m.color = d[2];
          m.lineId =  "linePath" + i;
          m.selected = d[0];
        })
        return d[1]})
      .join((group: any): any => {
        const enter = group.append('g').attr('class', 'lineDotsGroup');
        enter.append('circle').attr('class', 'backgroundDotCircle');
        enter.append('circle').attr('class', 'dotCircle');
        return enter;
      });

    dotGroup.select(".backgroundDotCircle")
      .attr("fill", "white")
      .attr("stroke-width", 0)
      .attr("r", dotRadius)
      .attr("cx", (d: any) => xScale(d.xVar));

    dotGroup.select(".dotCircle")
      .attr("fill-opacity", 0.2)
      .attr("stroke-width", 2)
      .attr("r", dotRadius)
      .attr("cx", (d: any) => xScale(d.xVar))
      .on("mouseover", (event: any, d: any) => {
        d3.select(".linePath#" + d.lineId)
          .attr("stroke-width", 2);
        let tooltipValues =  Object.keys(dataByKpi[d.xVar]).map((m: any) =>
          m = [m, d3.format(columnFormats[d.xVar].includes("%") ? columnFormats[d.xVar]: ",")(dataByKpi[d.xVar][m]),
            lineData.find((f: any) => f[0] === m)[2]]);
        const tooltipData = {xVar: d.xVar, xGroup: kpiCatGroups[d.xVar], values: tooltipValues};
        if(myChart.props.showAverage){
          const averageValues = lineData.find((f: any) => f[0] === 'Network Average');
          tooltipData.values.push([
            'Network Average',
            d3.format(columnFormats[d.xVar].includes("%") ? columnFormats[d.xVar]: ",")(averageValues[1].find((f: any) => f.xVar === d.xVar).yVal),
            averageValues[2]
          ])
        }
        tooltipData.values = tooltipData.values.sort((a: any,b: any) =>  a[0] == d.selected ? -1 : b[0] == d.selected ? 1 : 0);

        myChart.showTooltip('sp', tooltipData, event.offsetX, event.offsetY, width,height);
      })
      .on("mouseout", (event: any, d: any) => {
        d3.selectAll(".linePath").attr("stroke-width", 1);
        myChart.hideTooltip('slopeChart');
      });


    d3.forceSimulation(allDots)
      .force("x", d3.forceX((d: any) =>  0).strength(0.3))
      .force("y", d3.forceY((d: any) => d.yPosition).strength(1))
      .force("collision", d3.forceCollide(dotRadius * 0.6))
      .on("tick", ticked);

    drawLegend(lineData);
    function ticked(){

      mySvg.selectAll(".linePath")
        .attr("d", (d: any) => line(d[1]));

      mySvg.selectAll(".linePathMouseover")
        .attr("d", (d: any) => line(d[1]));

      mySvg.selectAll(".dotCircle")
        .filter((d: any) => !isNaN(d.y))
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')');

      mySvg.selectAll(".backgroundDotCircle")
        .filter((d: any) => !isNaN(d.y))
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')');

    }


    function drawLegend(legendData: any){

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(" + (15) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + getCorrectLabel(d[0]))
        .attr('fill', (d: any) => d[2])
        .attr('stroke', (d: any) => d[2])
        .attr('fill-opacity', 0.2)
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', (d: any) => d[3] === true ? "4,2" : "")
        .attr('r', dotRadius);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any) => 'legendItem' + getCorrectLabel(d[0]))
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => d[0].toUpperCase())

      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + (dotRadius * 2));
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (40 + myWidth)
      });


    }

    function getCorrectLabel(d: any) {
      // cheat as could not work out the regex
      let myString = d.split('');
      myString = myString.filter((f: any) => f !== ' ')
      myString = myString.filter((f: any) => f !== '(')
      myString = myString.filter((f: any) => f !== ')')
      myString = myString.filter((f: any) => f !== '-')
      myString = myString.join('');
      return myString
    }
    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function(): any {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'em');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          // @ts-ignore
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0){
              tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);

            }
          }
        }
        if (lineNumber > 1){
          text.selectAll('tspan').remove();
          text.attr('id', originalValue)
          text.text(originalValue.substr(0, 5) + '..');
        } else if (text.selectAll("tspan").nodes().length === 2){
          const myTransform = text.attr("transform");
          const transformX = +myTransform.split("(")[1].split(",")[0];
          const transformY = +myTransform.split(",")[1].split(")")[0];
          text.attr("transform","translate(" + transformX + "," + (transformY - 12) + ")")
        }
      });

    }
    this.stop(10).then(() => this.isLoading = false);

  }
// property & data for the chart
  getSlopeChartData() {
    this.isTooltip = false
    // this.SlopeChartData=this.data
    if (this.item.config.chart_type == 'scorecard') {
      // 100198
      this.SlopeChartData = this.data

    } else {
      this.SlopeChartData = this.data
      // 100195
    }
    this.data = this.SlopeChartData

    this.props = {
      chartHeight: this.isFullscreen ? 800 : 550,
      colors: ["#FF6636", "#655CF4", "#FFCD4A", "#2DC887", "#7BD437", "#D19806", "#BC4231"],
      lineVar: this.item.config.chart_type == 'scorecard' ? 'source_name' : 'brand_name',
      kpiVar: 'kpi_name',
      valueVar: 'kpi_value',
      groupVar: 'kpi_type',
      formatVar: 'kpi_format',
      rangeVars: ['range_start', 'range_end'],
      showAverage: this.item.config.chart_type == 'scorecard' ? true : false, //boolean
      showGroups: this.item.config.chart_type == 'scorecard' ? true : false, //boolean
      averageColor: "#8A98AB"
    }
    // @ts-ignore
    if ( this.SlopeChartData.length > 0) {
      this.noData=false;
      this.plotChart();
      this.formatPercentageData(this.data);
    }else{
      this.noData=true;
      this.isLoading=false;
    }
  }
  // numberFormat
  numbedPipe(value: any) {
    if (value.includes('%')) return value
    if (value.includes(',')) {
      let val = value.replaceAll(/,/g, "");
      return this.currency.transform(val, '', '', '1.0-2');
    }


    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }
  removeUnderScore(value: string) {
    return value.replace(/_/g, ' ').toUpperCase()
  }
  formatPercentageData(data: any) {
    if (data && data.length > 0) {
      this.slopeChartFormattedData = data
      this.slopeChartFormattedData.forEach((element: any) => {
        if (element.kpi_format == ".0%") {
          element.kpi_value = (element.kpi_value * 100) + "%"

        }
        return element
      });
    }
  }
}
