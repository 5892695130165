import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";
import { debounceTime, Subscription } from "rxjs";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectionPopupComponent } from "../shared/selection-popup/selection-popup.component";
import { ConstantsService } from "src/app/services/constants.service";
import { UserActivityService } from "src/app/services/useractivity.service";
import { ApiService } from "src/app/services/api.service";
import { NavigationService } from "src/app/services/navigation.service";
import { WidgetService } from "src/app/services/widget.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
  userName: string = "";
  clientKey: any;
  moduleKey: any;
  module: any;
  pages: any;
  title: any;
  logoImage: any;
  colorCodes: any = [];
  colors: any;
  pageLogo: any;
  pageInfo: any;
  selectConfig!: any;
  DbPages: any;
  newColor: any;
  clientLogo: any;
  checkFlag = true

  brandKeyData: any[] = []
  test: any
  page_nm: any
  reqSubcription: Subscription[] = [];
  moduleName:any;

  // for Auto Complete

  @ViewChild("brandkeySelection", { static: true }) brandkeySelection!: ElementRef;

  constructor(
    private dashboardService: DashboardService,
    private sessionService: SessionService,
    private router: ActivatedRoute,
    private route: Router,
    private dataService: DataService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private apiService :ApiService,
    public filterService: FilterService,
    public deviceDetectionService: DeviceDetectionService,
    private constantsService: ConstantsService,
    private userActivityService: UserActivityService,
    private cdr: ChangeDetectorRef,
    private widgetService: WidgetService
  ) {
  }

  isIpad = false;
  ngOnInit(): void {
    
    // Router
    this.router.params.subscribe((p) => {
    
      this.moduleName = p['module'] || '';

      this.router.queryParams.subscribe((params:any) => {
        let decryptedParams = this.navigationService.decryptData(params);
  
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
      })
      

      if(this.clientKey) {
        this.newColor = this.sessionService.getColorCodes();
        this.userName = this.sessionService.getUsername();
  
        // Brand Name
        // this.getBrandName()
  
        this.filterService.refreshData = false;
        // this.filterService.refreshTheDashboard = false;
        // get data from sessionservice
        this.selectConfig = sessionStorage.getItem("selectConfig");
  
        // for user menu icons color css classes.
        this.dashboardService.moduleKey = this.moduleKey;
  
         // FRM Portal Brand key Selections
         if (this.moduleKey == '100110') {
          this.pages = [];
          let getBrand = sessionStorage.getItem("brandKeyForFilters");
  
          if (!getBrand) {
            this.checkFlag = false;
            this.gettingDatafromPopup();
          } else {
            this.checkFlag = true;
            this.getDashboardData();
            this.clientLogo = this.sessionService.getClintLogo();
          }
        } else {
          this.getDashboardData();
          this.clientLogo = this.sessionService.getClintLogo();
        }
        this.cdr.detectChanges()
        if (this.selectConfig == "DB") {
          this.getWorknames();
        } else {
          this.selectConfig = "S3";
        }

      }
    });

    // if(this.clientKey) {
    //   // get Logos
    //   this.apiService.getlogos().subscribe((res:any)=>{
    //     // console.log(res)
    //     if(res.length>0){
    //       sessionStorage.setItem('logos', JSON.stringify(res))
    //     }else{
    //       alert(" brand logos not found")
    //     }
    //   },(err:any)=>{alert(" brand logos not found")})
    // }

  }
  

  gettingDatafromPopup() {
    let dialogRef = this.dialog.open(SelectionPopupComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: true,
      panelClass: "du-custom-modalbox",
    });

    dialogRef.afterClosed().subscribe((value) => {
      if(value) this.setBrandKey(value?.brand_key)
    });
  }
  
  getDashboardData() {
    let body = {
      username: this.userName,
      client_key: this.clientKey,
      module_key: this.moduleKey,
    };

    this.reqSubcription.push(this.dashboardService.getDashboardAccess(body).pipe(debounceTime(500)).subscribe((dashboard) => {
      this.pages = dashboard;
    }, err => {
      console.log(err);
    }));
  }

  openPage(page: any) {
    sessionStorage.setItem("navbar_page_nm", JSON.stringify(page))
    this.userActivityService.setData({ "pageTab": 0 })
    this.checkFlag = true;
    this.filterService.sliderValue = 1;

    let query = {
      tab_id: 0,
      cl_key: this.clientKey,
      md_key: this.moduleKey,
      ds_key: page.dashboard_key || page.work_key
    }

    this.widgetService.pageHeaderWidgets = [];
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'page'}`, query)
    // this.route.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${page.dashboard_key || page.work_key}`]);
  }

  getWorknames() {
    this.dataService.getWorkList().subscribe((req) => {
      this.DbPages = req;
    });
  }

  getBrandName() {
    let url = "kpi/get-brand-filter"
    this.filterService.loadData('post', url, 'client_key', '').subscribe((res: any) => {
      this.brandKeyData = res ? res : []
    })
  }

  setBrandKey(brand: any) {
    if (brand) {
      sessionStorage.setItem("brandKeyForFilters", brand);
      this.brandKeyData.find((i: any) => {
        if (i.brand_key == brand) {
          sessionStorage.setItem("brandName", i.brand_name);
        }
      })
      this.checkFlag = true;
      this.getDashboardData();
      this.clientLogo = this.sessionService.getClintLogo();
    }
  }

  onFilterTextBoxChanged($event: any) {
    return ($event.target as HTMLInputElement).value
  }

  selectedWork: any;

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }

}
