import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding, HostListener, Output, EventEmitter } from "@angular/core";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "src/app/services/dialog.service";
import { DataService } from "src/app/services/data.service";
import { ColDef } from "ag-grid-community";
import { Observable, Subscription } from "rxjs";
import { AgGridAngular } from "ag-grid-angular";
import { GanttChartComponent } from "../../shared/charts/gantt-chart/gantt-chart.component";
import { IconChartComponent } from "../../shared/charts/iconEvent-chart/icon-chart.component";
import { PopupStatusComponent } from "../../shared/popup-status/popup-status.component";
import { NewFilterService } from "src/app/services/new-filter.service";
import { ActivatedRoute } from "@angular/router";
import { FilterService } from "src/app/services/filter.service";
import { NavigationService } from "src/app/services/navigation.service";

@Component({
  selector: "app-patient-journey-card",
  templateUrl: "./patient-journey-card.component.html",
  styleUrls: ["./patient-journey-card.component.scss"],
})
export class PatientJourneyCardComponent implements OnInit {
  static key = 100503;
  showKPIPopup: boolean = false;
  isOpen: boolean = false;
  popupOpen: boolean = false;
  isActive: boolean = false;
  widgets: any = [];
  gridData: any;
  columnDefs: ColDef[] = [];
  @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  @Input("heading") heading: string = "";
  @ViewChild("GanttChartContainer", { read: ElementRef }) sample!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  @ViewChild("fs") fs!: ElementRef;
  @Input('item') item: any
  iconList: any = [];
  ganttData: any;
  rowData: any;
  defaultColDef = {
    sortable: true,
    filter: true,
  };
  tooltipInfo: any;
  @Input("pageKey") pageKey: any;
  sampleVariable!: GanttChartComponent;
  reqSubcription: Subscription[] = [];
  alertIcons: any;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardName: any;
  cardId: any;
  patientId: any;
  employeeKey: any;
  isPortraitMode: boolean = false

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  @ViewChild("patientJourneyDiv", { static: true }) patientJourneyDiv!: ElementRef;


  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.checkOrientation();
  }
  checkOrientation() {
    console.log(this.isPortraitMode)
    if (this.checkIPad()) {
      this.isPortraitMode = false
    } else if (this.checkMobile()) {
      this.isPortraitMode = window.innerHeight > window.innerWidth;
    } else {
      this.isPortraitMode = false
    }
  }
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    public dataService: DataService,
    private newFilterService: NewFilterService,
    public filterService: FilterService,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {

    // Export Excel for Gantt chart
    this.reqSubcription.push(this.dataService.chartData.subscribe((res:any)=> {
      this.ganttData = res ? res : []
    }))
  }

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  // Full Screen Functionality 

  gridsize: any = 30;

  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }

  closeFullscreen(): void {
    this.isFullscreen = false;

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.isActive = false;
    setTimeout(() => {
      //  this.plotChart()
    }, 100);
  }

  ngOnInit(): void {
    
    // Auto Size from Element
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.patientJourneyDiv?.nativeElement);

    this.checkOrientation();

    this.route.params.subscribe((p: any) => {
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = decryptedParams["cl_key"];
        this.moduleKey = decryptedParams["md_key"];
        this.dashboardKey = decryptedParams["ds_key"];
        this.patientId = decryptedParams["cardId"];
        this.cardId = decryptedParams["cardId"];
      });
    });


    // this.route.params.subscribe((p: any) => {
    //   this.clientKey = p["clientKey"];
    //   this.cardName = p["cardName"];
    //   this.cardId = p["cardId"];

    //   if (this.cardName == 'pwt') {
    //     this.route.queryParams.subscribe((params: any) => {
    //       if (this.cardId == 'paCard') {
    //         this.cardId = params.paId
    //       }
    //     })
    //   }

    // })


    this.widgets = this.item ? this.item : ''
    this.iconList = this.widgets?.config?.icon
    this.alertIcons = this.item?.config.alerts_config?.controls ? this.item?.config.alerts_config?.controls : sessionStorage.getItem('alertIcons')

    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'right:50px !important;visibility:hidden')
        this.isFullscreen = true
        this.isActive = true
      } else {
        document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'right:15px;visibility:hidden')
        this.isFullscreen = false
        this.isActive = false
      }
    })

  }

  ngOnDestroy() {
    this.reqSubcription.forEach((res: any) => res.unsubscribe());
  }

  kpiPopup() {
    document.getElementById('d3Tooltip')?.setAttribute('style', 'visibility:hidden;left:unset;right:unset;top:unset;bottom:unset;position:absolute')
    this.showKPIPopup = true;
    document.getElementById('kpi_gantt_popup')?.setAttribute('style', 'visibility:visible')
  }

  resData: any;
  baseQueryFromSession: any

  changeStatus() {
    this.filterService.baseQuery = this.newFilterService.getPatientStatusBaseQuery()
    // patient status and patient reason we need to know, when navigate to Patient Card
    const obj = {
      api_key: 6001,
      report_typ: "D",
      patient_id: this.cardId,
      client_key: this.clientKey || "",
    }

    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      this.filterService.executePatientQuery(obj).subscribe((res: any) => {
        let response: any = res ? res : []
        let findPatient = response.find((fnd: any) => fnd.patient_id == this.cardId)
  
        let resData = {
          api_key: 100202,
          patient_status: null,
          report_typ: "D",
          patient_case_status: findPatient.case_status,
          patient_reason_status: findPatient.status_reason,
          patient_id: findPatient.patient_id
        }
  
        if (this.cardName == 'pwt' || this.cardName == 'paCard' || this.cardName == 'card') {
  
          this.newFilterService.sendData(resData);
  
          this.dialog.open(PopupStatusComponent, {
            disableClose: true,
            width: '400px',
            height: '75',
          });
        }
      })
    }
  }

  updateSetting(event: any) {
    this.gridsize = event.value;

    if (this.gridsize == this.filterService.sliderValue) {
      this.filterService.refreshAlertDelete.next(true)
    }
  }

  // Check if the device is an iPad
  checkIPad() {
    return navigator.userAgent.match(/iPad/i) !== null;
  }

  // Check if the device is a mobile device
  checkMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
