<div class="filterSides" *ngIf="this.globalData.length>0" [style.min-width]="this.item?.key != 100700 ? 'auto !important' : '320px !important' " [style.width.px]="this.rightExpended ? ((this.globalData.length*48)+48) : 45" [style.top.px]="this.deviceDetectionService.isDesktop ? '66' : this.deviceDetectionService.isMobile ? isPortrait ? '70' : '80' : isPortrait ? '66' : '66'">
  <div style="text-align: center;">
    <span *ngIf="this.filterService.filterLoadFlg" class="loaderForFilter iconForSidesLeft"></span>
    <span [style.visibility]="this.filterService.filterLoadFlg ? 'hidden' : 'visible'">
      <mat-icon class="iconForSidesLeft" *ngIf="!this.rightExpended" title="open" (click)="expendedRight()">keyboard_arrow_left</mat-icon>
      <mat-icon class="iconForSidesLeft" *ngIf="this.rightExpended" title="close" (click)="expendedRight()">keyboard_arrow_right</mat-icon>
    </span>
    <span class="vlLines"></span>
  </div>
  <div *ngFor="let a of this.globalData; let i=index" >
    <div (click)="globalFilter(a)" style="text-align: center;">
      <mat-icon [svgIcon]="a.icon" class="iconForSides" [title]="a.name" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"  aria-controls="offcanvasRight" ></mat-icon>
      <span class="vlLines"></span>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-end container" style="padding: 0;min-width: 320px;max-width: calc(100% - 180px)" #offcanvasRight [style.width.px]="data.width" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">

  <!-- Close -->
  <div class="btn-close-bg">
    <mat-icon class="mat-btn-close" data-bs-dismiss="offcanvas">clear</mat-icon>
  </div>

  <!-- Resize  -->
  <div 
    class="resize resize__left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRight"
    [direction]="AngularResizeElementDirection.LEFT">
  </div>

  <div *ngIf="this.dataFilter">
   
    <!-- Header Name -->
    <h2 class="px-2 pt-2 mb-0 selectedHeaderName">{{this.selectedTab.name}}</h2>
    
    <hr>
        
    <!-- Filter Block -->
    <div [style.display]="this.selectedTab.name=='Filter' ? 'block' : 'none'" class="px-2 scrollForAnnotation" style="overflow-x: hidden;overflow-y: auto;height: calc(100vh - 55px);font-family: 'Poppins' !important;">
      <div style="position: relative;">
        <formly-form  [model]="model" [fields]="this.filterService.globalFilterFields"  [options]="filterService.filterReset" [form]="form" (modelChange)="onModelChange($event)">
        </formly-form>
        <span class="clrFilter" *ngIf="this.filterService.badgeCheck" (click)="reset()" >Reset</span>
      </div>
    </div>

    <!-- Annotation Block -->
    <div [style.display]="this.selectedTab.name=='Annotation' ? 'block' : 'none'">
      <div *ngIf="messages">
        <div *ngIf="this.selectedTab['isCurrentChart']" class="d-flex align-items-center justify-content-around">
          <div>
            <span style="color: #8d8b8b;"> Show </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="min-width: 200px;">
            <span [style.font-weight]="this.showAll ? 'normal' : 'bold'">{{this.selectedTab['isPrefixName']}}</span>
            <mat-slide-toggle (click)="showAllToggle(showAll)" class="example-margin slidertext sliderIconGLobal"[(ngModel)]="showAll"></mat-slide-toggle>
            <span [style.font-weight]="this.showAll ? 'bold' : 'normal'">{{this.selectedTab['isSuffixName']}}</span>
          </div>
        </div>

        <div class="m-2" style="width: 300px;text-align: right;">
          <input type="search" class="form-control" id="exampleInputSearch1" [(ngModel)]="searchAnnotationText" aria-describedby="searchHelp" placeholder="Search Annotations" style="font-size: 14px;font-family: 'Poppins';">
        </div>
        <hr>

        <div *ngIf="messages.length>0 && this.loadAnnotationApi">
          <cdk-virtual-scroll-viewport class="scrollForAnnotation" itemSize="10" style="overflow-y: auto;height: calc(100vh - 153px);font-family: 'Poppins' !important;">
            <ng-container *cdkVirtualFor="let message of messages | searchText: searchAnnotationText; let i = index;trackBy: trackByFnAnnotation;"> 
              <div class="px-2">
                <div class="row my-2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <span class="mr-3" style="color: #8d8b8b;font-size: 12px;">{{ '# '+ (i+1) }}</span>
                      <span style="color: #8d8b8b;font-size: 12px;">{{ moment(message['insert_timestamp']).fromNow() }}</span>
                    </div>
                    <div>
                      {{' '}}
                      <!-- <div class="btn-group">
                        <button class="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          {{'Public'}}
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item">Public</a></li>
                          <li><a class="dropdown-item">Private</a></li>
                        </ul>
                      </div> -->
                    </div>
                  </div>
                </div>
  
                <div class="row">
                  <div class="d-flex align-items-center">
                    <div class="mr-2"><img class="imageCss imageCss1" src="assets/man.png" alt="Profile"></div>
                    <div>
                      <span class="card-title fontForHeader">
                        <span style="font-family: 'Poppins'!important;font-size: 14px;">{{ message.employee_name || "No UserName"}}</span>
                        <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                        <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                      </span>
                      <!-- <span class="card-subtitle fontForHeader">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span> -->
                    </div>
                  </div>
                </div>
  
                <div class="row mt-3">
                  <p class="card-text mx-1">{{ message.comments }}</p>
                </div>
  
                <!-- <div class="row my-3">
                  <span style="font-size: 13px;">
                    <span style="color: #8d8b8b;">Data from:</span>
                    <span style="font-weight: 600;"> {{ moment(message['period']).format("MM/DD/YYYY") }}</span>
                  </span>
                </div> -->

                <div class="row my-3">
                  <div class="d-flex align-items-center" style="flex-flow: wrap;">
                    <span class="chipsTagAno" *ngIf="message['brand_name']">{{message['brand_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['source_type']">{{message['source_type']}}</span>
                    <span class="chipsTagAno" *ngIf="message['source_name']">{{message['source_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['sp_location_name']">{{message['sp_location_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['kpi_name']">{{message['kpi_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['time_cycle_nm']">{{message['time_cycle_nm']}}</span>
                    <span class="chipsTagAno" *ngIf="message['insert_timestamp']">{{ moment(message['insert_timestamp']).format("MM/DD/YYYY") }}</span>
                  </div>
                </div>

                <div class="mb-4" *ngIf="message?.dashboard_name">
                  <span class="mx-1 chipsTagAno" style="display: inline;">{{ message.dashboard_name }}</span>
                </div>

                <hr>
  
              </div>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>
        <div *ngIf="messages.length==0" style="text-align: center;margin-top: 100px;">No Annotations</div>
      </div>
    </div>

    <!-- Alert Block -->
    <div [style.display]="this.selectedTab.name=='Alerts' ? 'block' : 'none'">
      <div *ngIf="alert_notification">
        <div class="m-2" style="width: 300px;text-align: right;">
          <input type="search" class="form-control" id="exampleInputSearch1" [(ngModel)]="searchAlertText" aria-describedby="searchHelp" placeholder="Search Alerts" style="font-size: 14px;font-family: 'Poppins';">
        </div>

        <hr>

        <div *ngIf="alert_notification.length>0">
          <cdk-virtual-scroll-viewport  class="scrollForAnnotation" itemSize="10" style="overflow-y: auto;height: calc(100vh - 105px);font-family: 'Poppins' !important;margin: 4px 10px;">
            <ng-container *cdkVirtualFor="let alt of alert_notification | searchText: searchAlertText; let i = index;trackBy: trackByFnMessage;">
              <div class="d-flex my-3">
                <div class="mr-2">
                  <button mat-fab class="alert_dot"></button>
                </div>
    
                <div>
                  <span class="alert_header">
                    <span>{{alt.alert_nm || 'No Name'}}</span>
                    <span>{{alt.source_name ? ', ' + alt.source_name : ''}}</span>
                    <span>{{alt.sp_location_name ? ', ' + alt.sp_location_name : ''}}</span>
                    <!-- <b style="color: #1363df">></b>  -->
                  </span> 
                  
                  <!-- {{alt | json }} -->
                  <span class="alert_content">{{alt.alert_desc ||' '}}</span>
                  <span class="alert_date">{{ alt.alert_dt ? moment(alt.alert_dt).format('MM.DD.YYYY') : '' }}</span>
                </div>
              </div>
              <hr>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>
        <div *ngIf="alert_notification.length==0" style="text-align: center;margin-top: 100px;">No Alerts</div>
      </div>
    </div>

    <!-- Information Block -->
    <div [style.display]="this.selectedTab.name=='Information' ? 'block' : 'none'">
      <p class="infoDesc">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
    </div>

    <!-- Notification Block -->
    <div [style.display]="this.selectedTab.name=='Notification' ? 'block' : 'none'">
    </div>
  </div>

  <!-- Resize  -->
  <div 
    class="resize resize__bottom--left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRight"
    [direction]="AngularResizeElementDirection.BOTTOM_LEFT">
  </div>
</div>