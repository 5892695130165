import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-patient-pipeline',
  templateUrl: './patient-pipeline.component.html',
  styleUrls: ['./patient-pipeline.component.scss']
})
export class PatientPipelineComponent implements OnInit, OnChanges {
  static key = 100508;
  @Input('item') item: any
  disabled = false;
  max = 7;
  min = 1;
  step = 1;
  thumbLabel = true;
  value = 1;
  riple = 20;
  iconList: any = [];
  @Input("pageKey") pageKey: any;
  heading: any = "Netowrk Chart";
  optionValue1: any = "In Progress";
  color1: any = "#1363DF";
  optionValue2: any = "Lagging";
  color2: any = "#E34071";
  activeDays: any = "1"
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>();
  @ViewChild('pendingPipelineDiv', { static: true }) pendingPipelineDiv!: ElementRef;
  dynamicHeight: any;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  sliderValue: any = 1
  isActive: boolean = false
  isOpen = false;
  sortByArray = [
    {

      option: 'Default',
      default: true,
      listStyle: 'default',
      values: [
        {
          name: 'In Progress',
          color: '#1363DF',
          keyName: "patient_count"
        },
        {
          name: 'Lagging',
          color: '#E34071',
          keyName: "lagging_patients"
        }
      ]
    },
    {

      option: 'Referral Source',
      default: false,
      listStyle: 'default',
      values: [
        {
          name: 'SP',  //
          color: '#844AFF',
          keyName: "referral_source_sp"
        },
        {
          name: 'Hub', //
          color: '#FFCD4A',
          keyName: "referral_source_hub"
        }
      ]
    },
    {

      option: 'Rx Type',
      default: false,
      listStyle: 'default',
      values: [
        {
          name: 'Refill', //
          color: '#40B2E3',
          keyName: "rx_type_refill"
        },
        {
          name: 'New',      //
          color: '#E34071',
          keyName: "rx_type_new"
        }
      ]
    },
    {

      option: 'Patient View',
      default: false,
      listStyle: 'patientView',
    },
    // {

    //   option: 'Total',
    //   default:true,
    //   values: [
    //     {
    //       name: 'All',        //
    //       color: '#D4D9E3'
    //     },
    //     {
    //       name: 'In Status',      //
    //       color: '#1363DF'
    //     }
    //   ]
    // }
  ]
  pieColorObject: any = {};
  pieColorObjectSetting: any = {};
  activityArray: any = [{
    key: 1,
    default: true
  }]
  newSliderValue: any = 1
  listStyle: any = 'default';
  listStyle1: any = 'default';
  oldListStyle: any = 'default';
  selectedFilter: any;
  constructor(public filterService: FilterService) { 

    this.filterService.sortByChanged.subscribe((res:any)  => {
      this.sortByChange(res)
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  
  dropdownArray: any = [];
  dropdownArraySetting: any = [];
  sortBySelectedData: any;
  sortBySelectedData1: any;

  sortByChange(event: any) {
    this.dropdownArray = []
    this.sortByArray.find((i: any) => {
      if (i.option == event.target.value) {
        this.selectedFilter = i
        this.dropdownArray.push(i)
        this.sortBySelectedData = event.target.value
        this.sortByArray.map((i: any) => {
          if (i.option == this.sortBySelectedData) {
            i.default = true
          } else {
            i.default = false
          }
        })
        this.listStyle = i.listStyle
        this.oldListStyle = i.listStyle
        this.sortBySelectedData1 = event.target.value
        this.optionValue1 = i.values[0].name
        this.optionValue2 = i.values[1].name
        this.color1 = i.values[0].color
        this.color2 = i.values[1].color
        this.pieColorObject = {
          [this.optionValue1]: this.color1,
          [this.optionValue2]: this.color2
        }
      }
    })

  }

  sortBySettingChange(event: any) {
    this.dropdownArraySetting = []
    this.sortByArray.find((i: any) => {
      if (i.option == event.target.value) {
        this.selectedFilter = i
        this.dropdownArraySetting.push(i)
        this.listStyle = i.listStyle
        this.sortBySelectedData1 = event.target.value
        this.optionValue1 = i.values[0].name
        this.optionValue2 = i.values[1].name
        this.color1 = i.values[0].color
        this.color2 = i.values[1].color
        this.pieColorObjectSetting = {
          [this.optionValue1]: this.color1,
          [this.optionValue2]: this.color2
        }
      }
    })

  }

  activityArrayChange(event: any) {
    this.newSliderValue = event.target.value
  }

  onCancelSetting() {
    this.sortByArray.find((i: any) => {
      if (i.default) {
        this.listStyle = this.oldListStyle
        this.sortBySelectedData = i.option
        this.optionValue1 = i.values[0].name
        this.optionValue2 = i.values[1].name
        this.color1 = i.values[0].color
        this.color2 = i.values[1].color
        this.pieColorObjectSetting = {
          [this.optionValue1]: this.color1,
          [this.optionValue2]: this.color2
        }
      }
    })
  }

  applySettings() {
    this.activityArray.map((i: any) => {
      if (i.key == this.newSliderValue) {
        i.default = true
      } else {
        i.default = false
      }
    })
    this.sortByArray.map((i: any) => {
      if (i.option == this.sortBySelectedData1) {
        i.default = true
      } else {
        i.default = false
      }
    })
    this.filterService.sliderValue = this.sliderValue
    this.dropdownArray = this.dropdownArraySetting
    this.pieColorObject = this.pieColorObjectSetting
    this.sliderValue = this.newSliderValue
    this.sortBySelectedData = this.sortBySelectedData1
    this.value = this.newSliderValue
  }

  // Full Screen Functionality 

  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    this.isActive = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      // this.initiateCharts()
      // this.plotChart()
    }, 100);
  }

  ngOnInit(): void {

    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.pendingPipelineDiv?.nativeElement);
    
    this.min = this.item?.config.slider_min_value
    this.sliderValue = this.item?.config.slider_min_value
    this.max = this.item?.config.slider_max_value
    this.step = this.item?.config.slider_steps_value
    if (this.max) {
      this.activityArray = []
      for (let i = 1; i <= this.max; i++) {
        this.activityArray.push({
          key: i,
          default: i == this.sliderValue ? true : false
        })
      }
    }

    window.addEventListener('orientationchange', (event: any) => {
      this.isOpen = false
    });

    this.sortByArray = []
    if (this.item) {
      this.item.config.sortBy.controls.forEach((i: any, j: any) => {
        this.sortByArray.push(
          {
            option: i.dropdownName,
            default: j == 0 ? true : false,
            listStyle: i.listStyle,
            values: i.sections.map((k: any) => {
              return {
                name: k.legendName,
                color: k.legendColor,
                keyName: k.legendKeyName,
              }
            })
          },
        )
        this.sortByArray.find((i: any) => {
          if (i.default) {
            this.selectedFilter = i
          }
        })
      })
    }
    this.iconList = this.item.config.icon

    this.sortByArray.find((i: any) => {
      if (i.default == true) {
        this.dropdownArray.push(i)
        this.optionValue1 = i.values[0].name
        this.optionValue2 = i.values[1].name
        this.color1 = i.values[0].color
        this.color2 = i.values[1].color
        this.pieColorObject = {
          [this.optionValue1]: this.color1,
          [this.optionValue2]: this.color2
        }
      }
    })

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        this.isFullscreen = true
      } else {
        this.isFullscreen = false
      }
    })
  }

  getSliderValue(e: any) {
    this.filterService.sliderValue = e?.value
    this.sliderValue = e?.value
    this.activityArray.map((i: any) => {
      if (i.key == this.sliderValue) {
        i.default = true
      } else {
        i.default = false
      }
    })
  }

  displayFn(value: number): string {
    return `${value}`;
  }
}
