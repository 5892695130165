import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ICellRendererParams } from "ag-grid-community";
import { HelperService } from "src/app/services/helper.service";

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    selector: 'app-time-grid-format',
    template: `
        <span style="color: #5C6878;border: 1px solid #E8EAEE; border-radius: 5px;background-color: #F0F3F6;padding: 2px 5px;">{{ value }}</span>
    `,
})

export class TimeFormatGridComponent {
    value: any;
    params: any;

    constructor(
        private helperService: HelperService,
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.value = this.helperService.convertMinutesToReadableFormat(params.value || 0);
    }
}