import { Component, OnInit } from '@angular/core';
import * as d3 from "d3";
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-pj-bar-chart',
  templateUrl: './pj-bar-chart.component.html',
  styleUrls: ['./pj-bar-chart.component.scss']
})
export class PjBarChartComponent implements OnInit {
  PJRadarData:any;
  props: any;
  divId: any = "PJRadarChartDiv";
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.radarData.subscribe((resp: any) => {
      this.PJRadarData = resp
      if (this.PJRadarData) {
        this.initiateCharts();
        this.getPJRadarChartData();
      }
    })
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("class", "xAxis" + myClass);
    mySvg.append("g").attr("class", "yAxis" + myClass);
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    const margins = { left: 45, right: 12, top: 10, bottom: 48};

    const outerMargin = 60;

    mySvg.attr("width", width)
      .attr("height", height);


    myChart.PJRadarData.map((m: any) => m[myChart.props.valueVar] = isNaN(m[myChart.props.valueVar]) ? 0 : m[myChart.props.valueVar])
    let barData: any = d3.rollup(myChart.PJRadarData,(v: any) => d3.sum(v, (s: any) => s[myChart.props.valueVar]), (d: any) => d[myChart.props.radarVar])
    barData = Array.from(barData);
    const xTicks: any = [];
    barData.forEach((d: any) => xTicks.push(d[0]));
    barData = barData.map((m: any) => m = {name: m[0], value: m[1]});
    const yMax: any = d3.max(barData, (d: any) => d.value);

    const xScale: any = d3.scaleBand().domain(xTicks).range([0, width - margins.left - margins.right]);
    const yScale: any = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);

    mySvg.select('.xAxis' + myClass)
      // @ts-ignore
      .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(xTicks))
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

    mySvg.select('.yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5))
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.selectAll('.xAxis' + myClass + ' text')
      .attr('dy', 0)
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#101D42")
      .style('font-size', 10)
      .call(wrap,xScale.bandwidth()/2)

    mySvg.selectAll('.yAxis' + myClass + ' text')
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#8A98AB")
      .style('font-size', 8)

    mySvg.selectAll('.yAxis' + myClass + ' line')
      .attr('x1', 0)
      .attr('x2', width - margins.left - margins.right)
      .style('stroke', "#E8EAEE")
      .style('stroke-width', 1);

    mySvg.selectAll('.yAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' line')
      .style('display', "none")

    const barGroup = mySvg
      .selectAll('.chartGroup' + myClass)
      .data(barData)
      .join((group: any) => {
        const enter = group.append('g').attr('class', 'chartGroup' + myClass);
        enter.append('path').attr('class', 'barRect');
        return enter;
      });

    const barWidth = xScale.bandwidth()/4;

    barGroup.select( '.barRect')
      .attr('d', (d: any) => 'M0,' + (yScale(0) - yScale(d.value)) + 'L0,4 Q0,0 4,0 L' + (barWidth-4)
        + ',0 Q' + barWidth + ',0 ' + barWidth + ',4 L' +  barWidth + ',' + (yScale(0) - yScale(d.value)) +  'Z')
      .attr('fill','#1468E1')
      .attr('transform', (d: any) => 'translate(' + (margins.left +  xScale(d.name) + (xScale.bandwidth()*0.375))
        + ',' + (margins.top + yScale(d.value)) + ')');

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function(): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 12;
        const dy = 22;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            if(line.length > 0){
              tspan.text(line.join(' '));
              line = [word];
              if (word.trim().length > 0){
                lineNumber += 1;
                tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

              }
            } else {
              line = [word]
            }
          }
        }
      });
    }

  }
// property & data for the chart
    getPJRadarChartData(){
    // this.pJRadarService.getPJRadarChartData().subscribe((res:any)=>{
      // this.PJRadarData=res;
      this.props = {
        chartHeight: 300,
        chartColor: '#8C23E6',
        radarVar: 'program_nm',
        valueVar: 'no_of_days'
      }
      // @ts-ignore
      if (this.PJRadarData !== undefined  || this.PJRadarData.length > 0){
        this.plotChart();
      }
    // });
  }

}
