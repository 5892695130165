import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RouterHandlerService } from 'src/app/services/router-handler.service';

@Component({
  selector: 'app-pipeline-tooltip-link',
  templateUrl: './pipeline-tooltip-link.component.html',
  styleUrls: ['./pipeline-tooltip-link.component.scss']
})
export class PipelineTooltipLinkComponent implements OnInit, OnChanges {
  @Input('popupHeading') popupHeading: any = 'Hub'
  @Input('item') item: any = []
  @Input('PendingPatients') PendingPatients: any = 'Pending Patients'
  @Input('hiddenParams') hiddenParams: any
  @Input('SelectedNavigation') SelectedNavigation = false
  @Input('patientReasonCode') patientReasonCode:any
  @Input('sortBySelectedData') sortBySelectedData:any
  @Input('toolTipData') toolTipData: any[] = [
    {
      count: 15,
      status: 'Pending',
      change: '1%',
      color: 'green'
    },
    {
      count: 7,
      status: 'Lagging',
      change: '10%',
      color: 'yellow'
    }]
  @Output() hideLinkTooltip = new EventEmitter();
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardId: any;
  LinkValue1: any = "Patients";
  LinkValue2: any = "Prescribers";
  popupConfig: any = []
  moduleName:any;
  cardName:any;
  decryptedParams:any;

  constructor(
    private route: ActivatedRoute,
    private routerHandlerService: RouterHandlerService,
    private navigationService: NavigationService
  ) {

    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });

    // this.route.params.subscribe((p: any) => {
    //   this.clientKey = p["clientKey"];
    //   this.moduleKey = p["moduleKey"];
    //   this.dashboardKey = p["dashboardKey"];
    // })
    // this.router.navigate([`dashboard/${this.clientKey}/${this.moduleKey}/${this.dashboardKey}`]);
  }

  ngOnChanges(change: SimpleChanges) {}
  
  ngOnInit(): void {
    this.LinkValue1 = this.item?.config?.link_name1;
    this.LinkValue2 = this.item?.config?.link_name2;

    this.popupConfig = this.item?.config?.['popup_configuration']?.controls;

  }

  setNavigationData(config:any) {
    let nav = {
      dbJSON : config?.dbJSON,
      s3JSON : config?.s3JSON,
      pageName : config?.pageName,
      isDashboardLevel : config?.isDashboardLevel
    }
    this.routerHandlerService.navDetailInfo(nav);
  }

  routeHandler(data: any, item:any) {
    // debugger
    
    this.hideLinkTooltip.next(false);
    if (this.SelectedNavigation) {
      item.myData["hcp_state"] = item.myData["state_cd"];
      this.setNavigationData(data);
      this.routerHandlerService.storingPayloadAndFilterForGrid(data, item.myData, this.cardName);
      let query:any = Object.assign({}, this.decryptedParams, {tab_id: 0});
      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'pwt'}/${data.disp_name}`, queryMaped);
    } else {
      
      let filterData:any = {}
      let payloadData:any = {}
        
      if(this.cardName) { 
        if(sessionStorage.getItem("payloadData")) {
          let payload:any = sessionStorage.getItem("payloadData");
          payloadData = JSON.parse(payload);
        }
        
        if(sessionStorage.getItem("filterData")) {
          let filter:any = sessionStorage.getItem("filterData");
          filterData = JSON.parse(filter);
        }
      }

      // debugger

      if (this.sortBySelectedData == "Rx Type") {
        payloadData = {'rx_type' : item.status}
      } else if (this.sortBySelectedData == "Referral Source") {
        // let refData = [{"key": 100101, "name": "SP"},{"key": 100102, "name": "HUB"},{"key": 100103, "name": "DIRECT"}]
        // let find = refData.find((o:any)=> o.name.toString().toLowerCase() == item.status.toString().toLowerCase())
        // if(find) {
        //   filterData['patient_referral_source_dim_key'] = find.key;
        // } else {
        //   filterData['patient_referral_source_dim_key'] = 100104;
        // }
        payloadData['ref_source'] = item.status || ''
      } else if (this.sortBySelectedData == "Days Waiting") {

        let ae: any[] = []
        let text = item.count.toString().toLowerCase()
        
        if(text.includes('day')) {
          let aa = text.includes(">") ? text.indexOf(">")+2 : text.indexOf(" ")+1  
          let ab = text.indexOf(":")
          let ac = text.slice(aa,ab)
          ae = ac.split('-');
          payloadData['from_day'] = ae[0], 
          payloadData['to_day'] = ae[1] ? ae[1] : null;
        }
      } else {
        if(item.status == 'In Progress') {
          payloadData = {'lagging_indicator' : '0'}
        } else if (item.status == 'Lagging') {
          payloadData = {'lagging_indicator' : '1'}
        }
      }

      filterData['patient_status'] = 'pending';
      if(this.patientReasonCode) {
        filterData['patient_status_reason_code'] = this.patientReasonCode.toString()
      }

      this.setNavigationData(data);

      if(Object.keys(filterData).length>0) sessionStorage.setItem("filterData", JSON.stringify(filterData));
      else sessionStorage.removeItem("filterData");

      if(Object.keys(payloadData).length>0) sessionStorage.setItem("payloadData", JSON.stringify(payloadData));
      else sessionStorage.removeItem("payloadData");

      let query:any = Object.assign({}, this.decryptedParams, {tab_id: 0}, {page: 'list'}, {item: item ? (item?.status || '') + (item?.count) || '' : ''});
      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${'pwt'}/${data.disp_name}`, queryMaped);
    }
  }

  hidePendingLinkTooltip() {
    this.hideLinkTooltip.next(false);
  }

  ngOnDestroy() {
    this.hideLinkTooltip.next(false);
    this.popupConfig = []
    this.item = []
  }

}
