import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { divide } from "lodash";
import moment from "moment";
import { debounceTime, Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { FilterService } from "src/app/services/filter.service";
import { UserActivityService } from "src/app/services/useractivity.service";
import { FilterComponent } from "../filter/filter.component";
import { Platform } from "@angular/cdk/platform";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { ActivatedRoute } from "@angular/router";
import { WidgetService } from "src/app/services/widget.service";

@Component({
  selector: "app-time-cycle",
  templateUrl: "./time-cycle.component.html",
  styleUrls: ["./time-cycle.component.scss"],
})
export class WidgetTimeCycleComponent implements OnInit, OnDestroy {
  static key = 100504;

  @Input("config") config: any = {};
  @Input("item") item: any;
  @Output("onSelectionChange") onSelectionChange = new EventEmitter<any>();
  
  routeType:any;
  dashboardKey:any;
  selectedType: string = "";
  data: any[] = [];
  dateRange: any[] = [];
  findKey:any;
  reqSubcription: Subscription[] = [];
  hideTimeCycleFlag=false;
  
  constructor(
    private widgetService: WidgetService,
    private filterService: FilterService,
    private dataService: DataService,
    private userActivityService: UserActivityService,
    public deviceDetectionService: DeviceDetectionService,private route: ActivatedRoute

  ) {
    this.reqSubcription.push(
      this.route.params.subscribe((p: any) => {
        this.dashboardKey = p["dashboardKey"];
        this.routeType = p["cardId"];
      })
    )
  }
  
  formats = [
    {
      "value": "D",
      "format": "dd mm yyyy",
      "label": "Daily"
    },
    {
      "value": "W",
      "format": "dd mm yyyy",
      "label": "Weekly"
    },
    {
      "value": "M",
      "format": "dd mm yyyy",
      "label": "Monthly"
    },
    {
      "value": "Q",
      "format": "dd mm yyyy",
      "label": "Quarterly"
    },
    {
      "value": "Y",
      "format": "dd mm yyyy",
      "label": "Yearly"
    }
  ]
  ngOnInit(): void {
    
    this.filterService.brandBasedTime = this.item.config?.brand_based_timecycle?.['value']=='yes' ? true : false;
    this.reqSubcription.push(
      this.filterService.brandBasedTimeCycle.subscribe((res:any)=> {
        if((res?.time_cycle_string == null || res?.time_cycle_string) && this.filterService.brandBasedTime) {
          this.onLoadData(res);
        }
      })
    )

    if(!this.filterService.brandBasedTime) {
      this.onLoadData();
    }
  }

  onLoadData(report?:any) {

    this.findKey = this.widgetService.pageHeaderWidgets.find((o:any)=> o.key == this.item.key);

    this.filterService.brandBasedTime = this.item.config?.brand_based_timecycle?.['value']=='yes' ? true : false;

    if(this.filterService.brandBasedTime && (report?.time_cycle_string==null || report?.time_cycle_string)) {
      const spltReport = (report?.time_cycle_string == null) ? [this.item.config.defaultselectedType] : report?.time_cycle_string.split(',');
      this.data = this.formats.filter((f:any)=> spltReport.find((o:any)=> o == f.value));
      this.item.config.time_cycle = this.data;
    }

    this.filterService.dateRangeFilterCheck = false;
    this.filterService.defaultReport_type =  this.item.config.defaultreportType || undefined;
    
    // User State
    let userStateDate: any = this.userActivityService.getData();
    if (userStateDate?.selectedType) {
      let ab = this.item.config?.time_cycle.find((re:any)=> re.value == userStateDate.selectedType);
      if(ab) {
        this.selectedType = userStateDate.selectedType;
      } else {
        this.selectedType = this.item.config.defaultselectedType;
      }
    } else {
      this.selectedType = this.item.config?.time_cycle ? this.item.config.defaultselectedType : this.selectedType;
    }

    // Brand Based Timecycle
    if(this.filterService.brandBasedTime) {
      const fndType = this.data.find((o:any)=> o.value == this.selectedType);
      if(!fndType) {
        this.selectedType = this.data[0]?.value;
        this.item.config.defaultselectedType = this.selectedType;
      }
    }

    this.filterService.dateRangeCheck = true;
    
    let client_key: any = sessionStorage.getItem("client");
    
    let value = JSON.parse(client_key);
    (client_key = value[0].client.client_key),
      (this.data = this.item.config?.time_cycle
        ? this.item.config.time_cycle
        : this.data);

    let api = this.item.config["time_cycle_api"] || {};
    this.dateRange = [];
    let query = {
      client_key: client_key,
      report_typ: "D",
      api_key: api.api_key ,
    };

    if(api.api_key) {
      this.reqSubcription.push(
        this.dataService.getTimeCycleData(query).pipe(debounceTime(1000)).subscribe((res: any) => {
          this.dateRange = res == null ? [] : res;
          this.daysBetweenDate(this.selectedType);
        }, err => {
          console.log('timeCycle', err)
        })
      );
    } else {
      this.filterService.report_type = this.selectedType;
      this.filterService.kpiBreakDownSelectedType.next(this.selectedType);
    }

    this.filterService.time_cycle = this.selectedType;
  }

  changeTimeCycle(selectedType: any) {
    if(selectedType!=this.selectedType) {
      this.selectedType = selectedType;
      // this.userActivityService.setData({ "selectedType": selectedType })
      this.daysBetweenDate(selectedType);
      this.userActivityService.setData({ "selectedType": selectedType })
  
      // reset the filters
      sessionStorage.removeItem('singleTimeCycleQuarter')
      this.filterService.resetForBrandBased();
      this.filterService.resetForBrandBasedSingle();
    }
  }

  daysBetweenDate(selectedType: any) {
    this.filterService.report_type = selectedType;
    this.filterService.time_cycle = selectedType;

    this.filterService.kpiBreakDownSelectedType.next(selectedType);

    if(!this.filterService.dateRangeFilterCheck) {
      // this.filterService.filterQuery.next(selectedType);
    }
    

    let b = this.dateRange.find((a: any) => a.time_cycle == selectedType);
    if(b) {
      const date1 = new Date(b.start_dt);
      const date2 = new Date(b.end_dt);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.filterService.timeCycleCount = Math.round(Difference_In_Days);
      this.filterService.timeCycleDate.next(this.filterService.timeCycleCount);
      this.filterService.dateRangeCheck = false;
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
