import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, IsGroupOpenByDefaultParams, RowGroupingDisplayType, SideBarDef, ModuleRegistry, DetailGridInfo } from 'ag-grid-community';
import moment from 'moment';
import { NumberPipe } from 'src/app/pipe/number.pipe';
import { DialogService } from 'src/app/services/dialog.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { AgGridAngular } from 'ag-grid-angular';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { SessionStorage } from 'angular-web-storage';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserActivityService } from 'src/app/services/useractivity.service';
import { EnhancementService } from 'src/app/services/enhancements-service';
import { rowEditComponent } from './row-edit-component';
import config from '../../../widget/grid-page/config';

// Cell Renderer Params
function MyCellRenderer() { }

MyCellRenderer.prototype.init = function (params: any) {
  this.eGui = document.createElement('span');
  this.eGui.innerHTML = '&nbsp;' + params.value + '&nbsp;';
  this.eGui.style.backgroundColor = params['res'].isTextColorWithOpacity || '#00000040';
  this.eGui.style.color = params['res'].isTextColorWithOpacityColor || '#fff' ;
  this.eGui.style['border-radius'] = '2px';
  this.eGui.style['padding'] = '0 5px';
};

MyCellRenderer.prototype.getGui = function () {
  return this.eGui;
};

// Cell Renderer Params End

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-hierarchical-grid-chart',
  templateUrl: './hierarchical-grid-chart.component.html',
  styleUrls: ['./hierarchical-grid-chart.component.scss']
})
export class HierarchicalGridChartComponent implements OnInit, OnChanges {

  // Inputs
  @Input('data') data: any;
  @Input('pageKey') pageKey: any;
  @Input('item') item: any;
  @Input('config') config: any;
  @Input('headerConfig') headerConfig: any;
  @Input('heading') heading: string = '';
  @Input('rowData') rowData: any = [];
  @Input('columnDefs') public columnDefs: any = [];
  @Input('inputConfig') public inputConfig: any = [];
  @Input('childColumnDefs') public childColumnDefs: any = [];
  @Input('selectedGroup') selectedGroup: any;
  @Input('loader') public loader: Boolean = true;
  @Input('isDetailGrid') public isDetailGrid: Boolean | any = false;
  @Input('isPivotGrid') public isPivotGrid: Boolean | any = false;
  @Input('groupDisplayType') public groupDisplayType: RowGroupingDisplayType = 'multipleColumns';
  @Input('dividerVisibility') public dividerVisibility: Boolean | any = true;
  @Input('contextMenuCondition') public contextMenuCondition: Boolean | any = true;

  // View Childs
  @ViewChild('fs') fs!: ElementRef;
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  @ViewChild("pdfSettingGrid", { static: true }) pdfSettingGrid!: ElementRef;
  @ViewChild("paymentGrid") paymentGrid!: AgGridAngular;
  @ViewChild('paymentHGridDiv', { static: true }) paymentHGridDiv!: ElementRef;

  // Host Binds and HostListners
  @HostBinding('class.is-fullscreen') isFullscreen = false;

  private gridApi!: GridApi | any;
  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';

  isActive = false;
  currentVisibleData: any = [];
  columnApi: any;
  isIcon: any;

  iconList: any = [];
  condition: any;
  isDataLoaded: boolean = false;
  detailRowAutoHeight: boolean = true;
  reqSubcription: Subscription[] = [];

  agGroupedData: any;
  GroupedData: any
  childData: any = [];
  parentData: any = [];
  minRowHeight: any;
  rowGroupingDisplayIcon: any;
  gridRowColor = false;
  pagination: any;
  pageSizeCount: any;
  cardName: any;
  filterValue: any;
  pdfColumnDefs: any;
  dashboardKey: any;
  tabId: any;

  public defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    filter: true,
    sortable: true,
    unSortIcon: true,
    // suppressMenu: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    maxWidth: 350,
    minWidth: 80,
  };
  public autoGroupColumnDef: ColDef = {
    minWidth: 150,
    maxWidth: 350,
    sortable: false,
    cellRendererParams: {
      suppressCount: true,
    },
    cellClass: "cell-wrap-text-h"
  }

  // Detail Grid gridConfiguration
  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [],
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        unSortIcon: true,
        draggable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressMenu: true,
        maxWidth: 350,
      },
      suppressAggFuncInHeader: true,
      suppressDragLeaveHidesColumns: true,
      rowHeight: 50,
      getRowStyle: this.getRowStyle.bind(this)
    },
    getDetailRowData: (params: any) => {
      params.successCallback(params.data.callRecords);
    },
  }

  public groupRowRendererParams: any = {
    suppressCount: true,
  }

  constructor(
    private currency: CurrencyPipe,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    public filterService: FilterService,
    private navigationService: NavigationService,
    private userActivityService: UserActivityService,
    private enhancementService: EnhancementService
  ) {
    this.reqSubcription.push(this.filterService.chartshowBy.subscribe((res: any) => {
      this.condition = res
    }))
  }

  openPDFcustom() {
    this.dialogService.openDialog(this.pdfSettingGrid, "80vw")
  }

  screenChanges(fullscreen: any) {
    this.isFullscreen = fullscreen;
    this.isActive = fullscreen;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.filterService.hiraricalGridApi = params.api
    this.columnApi = params.columnApi;
    this.gridApi.addEventListener('rowGroupOpened', this.onRowGroupOpened.bind(this));
    // this.autoSizeAll(false)

  }
  // onBtStartEditing() {
  //   this.gridApi.setFocusedCell(0, "action_taken");
  //   this.gridApi.startEditingCell({
  //     rowIndex: 0,
  //     colKey: "action_taken",
  //   });
  // }
  onRowGroupOpened(event: any): void {
    this.filterService.allGroupsClosed = false;
    this.gridApi.forEachNode((node: any) => {
      if (node.group && node.expanded) {
        this.filterService.allGroupsClosed = true;
      }
    });
  }

  onFirstDataRendered(params: any) {
    params.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
  }

  // Quick Filter for Type to Search Filter
  onQuickFilterChanged(event: any) {
    this.filterValue = event;
    this.gridApi.setQuickFilter(this.filterValue);
  }

  ngOnInit(): void {
    // Route
    this.route.params.subscribe((p: any) => {

      this.route.queryParams.subscribe((params: any) => {
        let decryptedParams = this.navigationService.decryptData(params);
        this.dashboardKey = decryptedParams["ds_key"];
        this.tabId = params["tab_id"];
      })
    });


    // For Pivot Side bars
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: false,
            suppressPivots: false,
            suppressPivotMode: true,
            suppressRowGroups: false,
            // suppressSideButtons: false,
            // suppressColumnFilter: false,
            // suppressColumnExpandAll: false,
            // suppressColumnSelectAll: false,
            // suppressSyncLayoutWithGrid: true,
          },
        },
      ],
      position: 'right',
    };

    // For Icons
    this.iconList = this.item?.config?.icon ? this.item?.config?.icon : this.iconList;

    this.isIcon = this.headerConfig.find((f: any) => f.icon_selected);

    // Event Listner for ------
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        document.getElementById('pdfAgTable')?.setAttribute('style', 'height:90vh;')
      } else {
        document.getElementById('pdfAgTable')?.setAttribute('style', 'height:inherit;')
      }
    })

    this.detailCellRendererParams.detailGridOptions.columnDefs = this.childColumnDefs;
    if (this.item.config?.['comments_action_button']?.value == 'yes') {
      this.columnDefs.push({
        headerName: "Actions",
        field: "action",
        headerComponentParams: {
          template: `
                <div class="ag-cell-label-container" role="presentation">
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: ${ 'start'};text-align: ${ 'start'};padding-right: 5px;">
                      <span class="ag-header-cell-text" style="color: ${'#7c7c7c'};">
                        ${"Actions"}
                      </span>
                      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                      <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                  </div>
                  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" style="position: absolute;margin: -5px;"></span>
                </div>
              `
        },
        cellRenderer: rowEditComponent,
        cellRendererParams: (params: any) => ({
          config: this.inputConfig
      }),
         minwidth: 60
      })
    }




  }

  // Function to define row styles
  getRowStyle(params: any): any {
    if (params.node.rowIndex % 2 === 0) {
      // Even row
      return { background: '#FAFAFB' }; // Set your background color
    } else {
      // Odd row
      return {};
    }
  }

  // Grid options for ag-Grid
  gridOptions: any = {
    components: {
      myCellRenderer: MyCellRenderer,
    },
    rowStyle: this.getRowStyleInParent.bind(this),
    onCellClicked: this.onCellClicked.bind(this),
    // onFirstDataRendered: this.onFirstDataRendered.bind(this),
    // onGridReady: this.onFirstDataRendered.bind(this)
    getContextMenuItems: (params:any) => {
      return [
        'copy', // Default copy options
        'copyWithHeaders', // Default copy with headers option
        'copyWithGroupHeaders', // Default copy with group headers option
        'paste', // Default paste option
        {
          name: 'Export to Excel',
          action: () => this.exportXls(),
        },
      ];
    },
  };

  onCellClicked(event: any) {
    this.userStateGenerated();
  }

  // Function to define row styles
  getRowStyleInParent(params: any): any {
    return { padding: '5px 0' }; // Add space between rows (e.g., 5px top and bottom)
  }


  ngOnChanges(changes: SimpleChanges): void {

    let selectedRow: any = [];
    this.selectedGroup?.forEach((res: any) => {
      selectedRow.push(res.api_resp_column_nm)
    });

    this.pdfColumnDefs = this.columnDefs.filter((f: any) => !f.suppressPDFColumn)

    let page = this.item.config?.['isPagination']?.name || 'NIL';
    this.pagination = page == 'NIL' ? false : true;
    this.pageSizeCount = this.pagination ? Number(page) : 0;

    this.minRowHeight = this.item.config?.['minRowHeight'] || 50;
    this.rowGroupingDisplayIcon = this.item.config?.['rowGroupingDisplayIcon']?.value || 'arrow';
    let gridColor = this.item.config?.['GridRowColor']?.name || 'Odd Color';
    this.gridRowColor = gridColor == 'Odd Color' ? true : false;

    let notAdjust = this.item.config?.['headerAdjusting']?.value || 'adjust';
    if (notAdjust == 'fit') {
      if (this.columnApi) {
        this.autoSizeAll(false);
      }
    }

    if(this.gridApi) this.gridApi.setRowData(this.rowData);
    
    // User State for Grid
    this.restoreState();

    if (changes['rowData']?.currentValue != changes['rowData']?.previousValue) {
      setTimeout(() => {
        this.GroupedData = this.getGroupedData();
      }, 500);
    }
  }


  // Export Pdf
  exportToExcel() {
    this.exportXls();
  }
  exportXls() {
    this.gridApi.exportDataAsExcel({
      fileName: this.item.config['report_heading'], columnKeys: this.generateColumnsForExcel(),
      sheetName: this.filterService.selectedBrandName || this.item.config['report_heading'],
      processCellCallback: (params: any) => {
        // debugger
        let suffix = params.column.colDef?.selectedRowConfig?.issuffix || '';
        let prefix = params.column.colDef?.selectedRowConfig?.isPrefix || '';
        
        if (params.column.colDef.type === "date") {
          params.value = moment(params.value).format("MM/DD/YYYY")
          if (params.value == 'Invalid date') { return '' }
          return params.value;
        }
        // if (params.column.colDef.valueFormatter) {
        //   return params.column.colDef.valueFormatter({ value: params.value });
        // }
        
        let maskColumn = this.item.config['maskCurrentQuarter']?.name == 'Yes' ? true : false;
        
        // let parent = this.item.config["list_of_api_parent"]
        
        if (params.column.colDef.type === "Currency") {
          if(maskColumn && this.enhancementService.currentQuarterFlag) {
            return '-'
          } else {
            let val = params.value ? params.value : 0;
            return this.currency.transform(Number(val));
          }
        }
        if (params.column.colDef.type === "Number") {
          if(typeof params.value == 'number') {
            return prefix + this.currency.transform(Number(params.value), "", "", "1.0-2") + suffix;
          } else {
            return this.currency.transform(Number(params.value), "", "", "1.0-2")
          }
        }
        if (params.column.colDef.type === "Percentage") {
          if(maskColumn && this.enhancementService.currentQuarterFlag) {
            return '-'
          } else {
            let val = params.value ? params.value : 0;
            return val + '%'
          }
        }
        return params.value;
      }
    });
  }

  // For Excel Generation
  generateColumnsForExcel(): string[] {
    const keys = this.columnApi.getAllDisplayedColumns()
      .map((column: any) => column.getColId())
    return keys;
  }

  // Grouped Data
  getGroupedData() {
    // let rowGroupData: any = [];
    // const rowNodes = this.gridApi?.rowModel?.rowsToDisplay //getDisplayedRowAtIndex(0)?.allLeafChildren;
    const displayedRows: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort((node: any) => {
      if (node.aggData) {
        const group = {
          'file_type_name': node.key, 'tran_date': '', "error_bucket": " ",
          "delay_bucket": " ",
        };
        displayedRows.push(Object.assign({}, group, node.aggData));
      }
    });
    // console.log(displayedRows);
    return displayedRows;
  }

  // On Load Opened Tree
  public isGroupOpenByDefault: (params: IsGroupOpenByDefaultParams) => boolean =
    (params: IsGroupOpenByDefaultParams) => {
      // User State for opened Tree
      if(this.userActivityService.hierarchicalGridState?.length==0) return false;
      let findState = this.userActivityService.hierarchicalGridState.find((o:any)=> o.dash_key == this.dashboardKey && o.tab_id == this.tabId);
      if(!this.columnApi) return false;
      if(!findState) return false;
      
      if(findState?.getRenderedRow?.length==0) return false;

      let findExpention = findState.getRenderedRow.find((data:any) => data.expanded && data.group && params.field == data.field && params.key == data.key)

      return findExpention ? true : false
  };

  // Auto width Size for ALL the Columns
  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.columnApi.getAllColumns()!.forEach((column: any) => {
      allColumnIds.push(column.getId());
    });
    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  expendCol(event: any) {
    debugger
    if (event) {
      this.gridApi.expandAll();
    } else {
      this.gridApi.collapseAll()
    }
  }

  matchObjects(obj1: any, obj2: any) {
    return Object.keys(obj2).every(key => obj1[key] == obj2[key]);
  }

  // Custom Agg 
  public aggFuncs: any = {
    'custom': (params: any) => {

      let finalValue = 0;

      if (this.rowData?.length > 0 && params.rowNode?.field && params.rowNode?.group) {

        let node = params.rowNode || params.node;
        let level = node?.level;
        let condition: any = {};
        condition = {};

        for (let index = 0; index <= level; index++) {
          condition[node.field] = node.key;
          node = node.parent;
        }

        let newData = this.rowData.filter((res: any) => {
          return Object.keys(condition).every(key => res[key] === condition[key]);
        });

        // let newData = this.rowData.filter((flr: any) => flr[params?.rowNode?.field] == params?.rowNode?.key);

        const value = params.colDef.selectedRowConfig.aggValueColumnName;
        const maxValue = params.colDef.selectedRowConfig.aggMaxValueColumnName;

        const newValue = newData.reduce((acc: any, name: any) => acc + name[value], 0);
        const newMaxValue = newData.reduce((acc: any, name: any) => acc + name[maxValue], 0);

        // const sum = newValue + newMaxValue;

        // 1+2
        // 2/3*100
        if (newMaxValue == 0 && newValue == 0) {
          finalValue = 0;
        } else {
          finalValue = (newValue / newMaxValue) * 100;
        }

        return finalValue;
      }
    },
    'roundSum': (params: any) => {

      if (this.rowData?.length > 0 && params.rowNode?.field) {
        const values = params.values;
        let sum = values.reduce((a: number, b: number) => a + b, 0);
        let roundSum = 0;

        // let data = "ag-Grid-AutoColumn-" + params.rowNode.field;
        // let autoGroup = params.rowNode.groupData[data];
        let autoGroup = params.rowNode.key;

        if (autoGroup) {
          roundSum = Math.round(sum);
        } else {
          roundSum = sum
        }
        return roundSum;
      }
    }
  }

  // On User Sorted to Store the Col state
  onSortChanged(event: any) {
    this.userStateGenerated();
  }

  restoreState() {
    // debugger
    if(this.userActivityService.hierarchicalGridState?.length==0) return;
    let findState = this.userActivityService.hierarchicalGridState.find((o:any)=> o.dash_key == this.dashboardKey && o.tab_id == this.tabId);
    
    if(!this.columnApi) return false;
    if(!findState) return false;

    let colDefs = this.columnApi.columnModel.columnDefs;
    let colDef = colDefs.filter((f: any) => f.field != "hidden_column");

    let isPresents = colDef.filter((flr: any) => findState.colState.find((fnd: any) => flr['colId'] == fnd['colId']))
    let isPresent = isPresents.filter((f: any) => f.field != "hidden_column");

    if (isPresent.length == colDef.length) {
      this.columnApi.applyColumnState({
        state: findState.colState,
        applyOrder: true,
      });
    }
  }

  userStateGenerated() {
    const getRenderedRow = this.gridApi.getRenderedNodes();
    
    let getRenderedData = [];
    if(getRenderedRow.length) {
      for (let index = 0; index < getRenderedRow.length; index++) {
        const element = getRenderedRow[index];        
        const obj = {
          expanded: element.expanded,
          field: element.field,
          group: element.group,
          key: element.key
        }
        getRenderedData.push(obj);
      }
    }

    let obj = {
      'dash_key': this.dashboardKey,
      'tab_id': this.tabId,
      'colState': this.columnApi.getColumnState(),
      'getRenderedRow': getRenderedRow
    }

    if(this.userActivityService.hierarchicalGridState?.length) {
      let findUserState = this.userActivityService.hierarchicalGridState.findIndex((o:any)=> o.dash_key == this.dashboardKey && o.tab_id == this.tabId);
      if(findUserState!=-1) {
        this.userActivityService.hierarchicalGridState[findUserState] = obj;
      } else {
        this.userActivityService.hierarchicalGridState.push(obj);
      }
    } else {
      this.userActivityService.hierarchicalGridState.push(obj);
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
