<div class="prescriber_office_list">
  
  <!-- Heading -->
  <div class="d-flex align-items-center justify-content-between" style="padding: 10px 15px !important;">
    <span class="prescriber_office_heading">{{ heading }}</span>

    <!-- Radio Selection If Location view -->
    <div *ngIf="showToggle">
      <mat-radio-group [(ngModel)]="toggleSelection" >
        <mat-radio-button class="toggleLabel pr-3" color="primary" value="location">Location View</mat-radio-button>
        <mat-radio-button class="toggleLabel" color="primary" value="state">State View</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <hr class="prescriber_office_hr" />
  
  <div class="heightOfWidget" *ngIf="allData?.length>0">
    <!-- class="ListlaptopTabView" -->
    <div > 
      <!-- State View -->
      <div *ngIf="toggleSelection=='state'">
        <div *ngFor="let item of groupData; let last = last;">
          <div class="d-flex align-items-center">
            <div><mat-icon svgIcon="location" class="pr-2" style="overflow: visible;"></mat-icon></div>
            <div class="prescriber_office_name pr-3" (click)="routerGroupHandler(item)">{{ item.name }}</div>
            <div><mat-icon svgIcon="right_arrow" class="prescriber_head_icon" style="overflow: visible;" (click)="routerGroupHandler(item)"></mat-icon></div>
          </div>
  
          <div class="row mt-2 mb-2">
            <div class="col-3" *ngFor="let data of item.state_values;">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div class="prescriber_office_head" style="cursor: pointer !important;"
                    (click)="routerHandler(item, data)">{{ data.name }}</div>
                  <div class="ml-2">
                    <mat-icon svgIcon="right_arrow" style="cursor: pointer !important;"
                      class="prescriber_head_value_icon pt-1" (click)="routerHandler(item, data)"></mat-icon>
                  </div>
                </div>
                <div class="prescriber_office_values mt-1">{{ data.value }}</div>
              </div>
            </div>
          </div>
          <hr *ngIf="!last" class="prescriber_office_hr" />
        </div>
      </div>

      <div *ngIf="toggleSelection=='location'">
        <div *ngFor="let item of groupData; let last = last;">
          <div class="d-flex align-items-center">
            <div><mat-icon svgIcon="location" class="pr-2" style="overflow: visible;"></mat-icon></div>
            <div class="prescriber_office_name pr-3" (click)="routerGroupHandler(item)">{{ item.name }}</div>
            <div><mat-icon svgIcon="right_arrow" class="prescriber_head_icon" style="overflow: visible;" (click)="routerGroupHandler(item)"></mat-icon></div>
          </div>
  
          <mat-accordion class="prevent-click">
            <mat-expansion-panel *ngFor="let data of item.location_values;let lastLoc = last" [hideToggle]="true" #expansionPanel style="box-shadow: none;padding: 10px 0; border-bottom: 1px solid #ddd;">
              
              <mat-expansion-panel-header class="disable_ripple">
                <div class="row d-flex align-items-center" style="width: 100%;">

                  <div class="col-3" *ngFor="let value of data.value">
                    <div class="d-flex flex-column" *ngIf="value?.obj?.navigationIsOn!='Bind Field'">
                      <div class="d-flex align-items-center">
                        <div class="prescriber_office_head authorize-click" (click)="routerHandler(item, value)">{{ value.name }}</div>
                        <div class="ml-2">
                          <mat-icon svgIcon="right_arrow" class="prescriber_head_value_icon authorize-click" (click)="routerHandler(item, value)" style="padding-top: 2px;"></mat-icon>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="prescriber_office_values mt-1">{{ value.value }}</div>
        
                        <div class="ml-2">
                        </div>
                      </div>
        
                    </div>

                    <div class="d-flex flex-column" *ngIf="value?.obj?.navigationIsOn=='Bind Field'">
      
                      <div class="d-flex align-items-center">
                        <div class="prescriber_office_head" style="padding: 10px 0 5px;">{{ value.name }}</div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="prescriber_office_values mt-1 prescriber_name authorize-click"
                          (click)="routerHandlerPrescriberCard(item, value)" style="cursor: pointer !important;">
                          {{ value.value.toString().replaceAll(",", " ") }}
                        </div>
                        <div class="ml-2">
                          <mat-icon svgIcon="right_arrow" style="cursor: pointer !important;padding-top: 5px;"
                            class="prescriber_head_value_icon authorize-click" 
                            (click)="routerHandlerPrescriberCard(item, value)"></mat-icon>
                        </div>
                      </div>
        
                    </div>

                  </div>
                  <div class="col-3">
                    <mat-icon svgIcon="accordian_icon_down" *ngIf="!expansionPanel.expanded" class="prescriber_head_value_arrow authorize-click"></mat-icon>
                    <mat-icon svgIcon="accordion_icon_up" *ngIf="expansionPanel.expanded" class="prescriber_head_value_arrow authorize-click"></mat-icon>
                  </div>
                </div>              
              </mat-expansion-panel-header>
  

              <hr class="prescriber_office_hr" />

              <div class="row mt-3 mb-1">
                <div class="col-2 text-start accordian_list pl-3">Patient ID</div>
                <div class="col-10 accordian_list text-start" style="padding-left: 0 !important;">Patient Progress</div>
              </div>
              
              <div class="row mt-4" *ngFor="let status of data.statusJourney; let lastStatus = last;">
                <div class="col-12 d-flex" style="padding: 0 0 10px;">
                  <div class="col-2 d-flex flex-column text-center">
                    <div class="d-flex align-items-center" style="cursor:pointer">
                      <div>
                        <span style="cursor:pointer" class="accordian_id mt-2 text-center authorize-click" style="width: max-content;"
                          (click)="routerHandlerPatientCard(item, status)">{{status.id}} </span>
                      </div>
                      <div>
                        <mat-icon svgIcon="right_arrow" (click)="routerHandlerPatientCard(item, status)"
                          class="prescriber_head_value_icon ml-2 authorize-click" style="overflow: visible;"></mat-icon>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 d-flex text-start pl-3">
                        <div [ngStyle]="{'border': '2px solid' + status.color}"></div>
                        <div class="accordian_name mt-1 pl-2">{{status.name}}</div>

                      </div>
                    </div>
                  </div>
                  <div class="col-10 text-start">
                    <div class="timeline-steps aos-init aos-animate">
                      <div class="timeline-step" *ngFor="let a of status?.status; ">
                        <div class="timeline-content" style="z-index: 999;font-family: 'Poppins'">
                          <span class="no_of_days">{{a.no_of_days == null ? '' : a.no_of_days + ' days' }}</span>
                          <mat-icon
                            [ngStyle]="{'color': a.stage_status == 'Success' ? '#3AC97C' : a.stage_status == 'NA' ? '#E8EAEE' : '#FFCD4A' } "
                            style="width: 30px;height: 30px;padding: 1px;"
                            [svgIcon]="a.stage_status == 'Success' ? status_tick : a.stage_status == 'NA' ? status_na: a.stage_status == 'In Progress' ? status_pending : status_ns ">
                          </mat-icon>

                          <p class="mt-2 mb-1" [ngStyle]="{'color' : a.stage_status=='NA' ? '#8A98AB' : '#101D42'}"
                            style="font-size: 12px;line-height: 12px;">{{a.du_status_group}}</p>
                          <p style="font-size: 12px;line-height: 10px;color: #E8EAEE" class="text-muted mb-0 mb-lg-0">
                            {{a.status_date? '(':''}}{{a.status_date ? ( a.status_date | date: 'MM/dd/YY' ) :
                            ''}}{{a.status_date? ')':''}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr *ngIf="!lastStatus" />
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <hr *ngIf="!last" class="prescriber_office_hr" />
        </div>
      </div>
    </div>
  </div>


</div>