<input type="hidden" [formControl]="dynamicSelect" [formlyAttributes]="field" />

<div *ngIf="this.showBy != 'no'">
    <label class="filterLabelStyle" style="margin: 0 !important;">{{label||"No Label"}}</label>

    <!-- Selcted Dropdown -->
    <ng-select #sc class="ngSelection" style="font-size:13px" [multiple]="false" [dropdownPosition]="'bottom'"
        [clearable]="false" [items]="opt.options" [placeholder]="this.placeholder" [(ngModel)]="this.selectedOption"
        [clearable]="false" [closeOnSelect]="false" [bindLabel]="this.labelProp" (close)="onClose()">
        <!-- Sesrch Header -->
        <ng-template ng-header-tmp>
            <input class="searchFiler" type="text" (input)="sc.filter(onFilterTextBoxChanged($event))"
                placeholder="Search" />
        </ng-template>

        <!-- Dropdown lable -->
        <ng-option *ngFor="let option of opt.options" [value]="option">
            {{ option[this.labelProp] }}
        </ng-option>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="form-check" style="margin: 0px 0 -5px;">
                <input class="form-check-input" id="item-{{ index }}" style="opacity: 1 !important;" type="checkbox"
                    [disabled]="selectedOption" [ngModel]="item$.selected" [value]="item">
                <label [matTooltip]="item[this.toolTipKey]" [matTooltipPosition]="'above'"
                    matTooltipClass="example-tooltip-single"
                    style="cursor: pointer;opacity: 1 !important;color: #000;font-family: 'Poppins' !important;"
                    class="form-check-label" for="flexCheckDefault">
                    {{ item[this.labelProp] }}
                </label>
            </div>
        </ng-template>

        <!-- Apply Button -->
        <ng-template ng-footer-tmp>
            <button (click)="applyFilter(sc)" class="btn btn-sm btn-secondary buttonApply">Apply</button>
        </ng-template>
    </ng-select>
    <hr>
</div>