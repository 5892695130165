<div class="widget-container" #pendingPipelineDiv>
  <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">

    <div class="outlay_pipeline card mt-1" id="outlay_pipeline_full">
      <div class="card-body mt-2">
        <div>
          <icon-chart [headerConfig]="item.config?.widget_header_configurations" [selectedFilter]="selectedFilter"
            [pageKey]="pageKey" [heading]="heading" [chartName]="'NetworkChart'" [config]="NetworkChartContainer"
            [expand]="fs" [fullscreen]="isFullscreen" [item]="item" [sliderValue]="this.filterService.sliderValue" [apiKey]="100126"></icon-chart>
        </div>
        <div id="completeNetworkChart" #fs #NetworkChartContainer class="mt-5 mb-3 overflow-auto">
          <app-network-chart [pieColorObject]="pieColorObject" [dropdownArray]="dropdownArray"
            [sliderValue]="this.filterService.sliderValue" [sortBySelectedData]="this.sortBySelectedData"
            [listStyle]="listStyle" [item]="item"></app-network-chart>
        </div>
      </div>
    </div>
  </article>
</div>